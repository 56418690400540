import { Outlet } from 'react-router-dom';
// @mui
import { Stack, Container } from '@mui/material';
//
import { StyledRoot } from './styles';

// ----------------------------------------------------------------------

export default function BackgroundLayout() {

  return (
    <StyledRoot>
      <Container>
        <Stack
          sx={{
            m: 'auto',
            pt: 35,
            maxWidth: 400,
            minHeight: '100vh',
            textAlign: 'center',
          }}
        >
          <Outlet />
        </Stack>
      </Container>
    </StyledRoot>
  );
}
