import BaseService from './baseService'

import axios from '../utils/axios'

export default class massiveUploadService extends BaseService {
  static resource() {
    return 'logoscorpecommerce__Logs_de_Importaciones'
  }

  static async countByFieldsMassive(fields = '', filters = []) {
    const finalFilters = filters.filter((e) => e.value !== undefined && !!e.value.length)
    const params = {
      fields,
      filters: finalFilters.length
        ? finalFilters.reduce((accumulator, currentValue, currentIndex, array) => {
            let newString = accumulator
            newString += `(${currentValue.field} ${currentValue.operation || '='} ${
              currentValue.value
            })`
            if (currentIndex === array.length - 1) {
              newString += ')'
            } else {
              newString += 'and'
            }
            return newString
          }, '(')
        : undefined,
    }
    // Eliminamos los parametros nullos o en blanco. Ejm: `filter[query]=`
    Object.keys(params).forEach((key) => {
      if (!params[key]) delete params[key]
    })

    const { data } = await axios.get(`${this.baseURL()}/Products/countMasiveLogs`, {
      params,
    })
    if (!data) {
      return { data: [] }
      // throw new Error('Not found')
    }
    return data
  }

  /**
   * Fetch all records from resources
   * @options {object} params - Query filters params
   * @returns {object}
   */
  static async fetch(
    fields = '',
    sort_by = 'id',
    sort_order = 'desc',
    page = 1,
    limit = 999,
    filters = [],
    headers = {},
    others = {},
    updateOtherResource = ''
  ) {
    const finalFilters = filters.filter((e) => e.value && e.value !== undefined)
    // ["A", "B", "C", "D"] => (((("A") AND "B") AND "C") AND "D")
    const filtersString = finalFilters.reduce((acc, curr, index) => {
      let result = acc
      const filter = `${curr.field} ${curr.operation || '='} ${curr.value}`
      result = `(${result}`
      result += `${index > 0 ? 'AND ' : ''}${filter}) `
      return result
    }, '')
    const params = {
      fields,
      sort_by,
      sort_order,
      page: page >= 1 ? page : 1,
      per_page: limit < 0 ? 0 : limit,
      filters: finalFilters.length ? filtersString : undefined,
      ...others,
    }
    // Eliminamos los parametros nullos o en blanco. Ejm: `filter[query]=`
    Object.keys(params).forEach((key) => {
      if (!params[key]) delete params[key]
    })
    const { data } = await axios.get(
      `${this.baseURL()}/${this.resource()}${updateOtherResource ? `/${updateOtherResource}` : ''}${
        finalFilters.length ? '/search' : ''
      }`,
      {
        params,
        headers,
      }
    )
    if (!data) {
      return { data: [] }
      // throw new Error('Not found')
    }
    return data
  }
}
