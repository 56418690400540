import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import invoiceReducer from './slices/invoice';
import contactReducer from './slices/contact';
import categoryReducer from './slices/category';
import vendorReducer from './slices/vendor';
import taxReducer from './slices/tax';
import storesReducer from './slices/stores';
import brandReducer from './slices/brand';
import massiveUploadReducer from './slices/massiveUpload';
import uploadDetailsReducer from './slices/uploadDetails';
import productQuestionsReducer from './slices/productQuestions';
import productAttributesReducer from './slices/productAttributes';
import ticketReducer from './slices/ticket';
import configurationsReducer from './slices/configurations'
// import brandCategoryReducer from './slices/brandCategory';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  invoice: invoiceReducer,
  contact: contactReducer,
  category: categoryReducer,
  vendor: vendorReducer,
  tax: taxReducer,
  stores: storesReducer,
  brand: brandReducer,
  massive: massiveUploadReducer,
  uploadDetails: uploadDetailsReducer,
  question: productQuestionsReducer,
  configurations:configurationsReducer,
  // brandCategory: brandCategoryReducer,
  productAttributes: productAttributesReducer,
  ticket: ticketReducer
});

export default rootReducer;
