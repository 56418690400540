import React from 'react'

import { Box, Button, Stack, Typography } from '@mui/material'
import { useLocales } from '../../locales'
import { useAuthContext } from '../../auth/useAuthContext'

import {
  StyledContentWelcome,
  StyledRoot,
  StyledSection,
  StyledSectionBg,
} from '../../layouts/login/styles'

import Image from '../../components/image'

function Welcome() {
  const { translate } = useLocales()
  const { welcomePage } = useAuthContext()

  const getUrlLocation = () => {
    if (window.location.hostname === 'one.zoho.com') {
      return `https://one.zoho.com/zohoone/${welcomePage?.CRMOrganizationName}/home/cxapp`
    }
    return 'https://crm.zoho.com'
  }

  return (
    <StyledRoot>
      <StyledContentWelcome>
        <Box
          component="div"
          sx={{
            width: 154,
            height: 80,
            display: 'inline-flex',
          }}
        >
          <Image
            disabledEffect
            alt="Sambil Online Logo"
            src={`${process.env.PUBLIC_URL}/assets/logos/svg/logo_sambil_modulo.svg`}
          />
        </Box>

        <Stack
          alignItems="flex-start"
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              paddingLeft: '10px',
            },
            mb: '10px',
            mt: '50px',
          })}
        >
          <Typography variant="h5"> Bienvenid@ a la administración del Sambil</Typography>

          {/* <Button
            variant="sambil"
            sx={{ mt: 2 }}
          >
            {translate('questions_products_administration')}
          </Button>
 */}
          <Button
            variant="sambil"
            sx={{ mt: 2 }}
            href={`${getUrlLocation()}/crm/org${welcomePage?.CRMOrganizationID}/tab/${
              welcomePage?.adminProductsWebtabName
            }`}
            target="_parent"
          >
            {translate('products_administration')}
          </Button>

          <Button
            variant="sambil"
            sx={{ mt: 2 }}
            href={`${getUrlLocation()}/crm/org${welcomePage?.CRMOrganizationID}/tab/${
              welcomePage?.adminOrdersWebtabName
            }`}
            target="_parent"
          >
            {translate('invoice_administration')}
          </Button>
        </Stack>
      </StyledContentWelcome>

      <StyledSection>
        <StyledSectionBg />
      </StyledSection>
    </StyledRoot>
  )
}

export default Welcome
