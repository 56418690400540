import axiosInstance from '../utils/axios'
import BaseService from './baseService'

export default class BrandService extends BaseService {
  static path = ''

  static resource() {
    return `Brands${this.path}`
  }

  static async s3ImageFromPath(path) {
    try {
      const response = await this.get(`s3ImageFromPath?path=${encodeURIComponent(path)}`)
      return response.data
    } catch (error) {
      return {}
    }
  }

  static async fetchBrands(fields, sort_by, sort_order, page, limit, filters, headers) {
    this.path = ''
    const response = await this.fetch(fields, sort_by, sort_order, page, limit, filters, headers)
    this.path = ''
    return response
  }

  static async fetchBrandsRequests(fields, sort_by, sort_order, page, limit, filters, headers) {
    this.path = '/requests'
    const response = await this.fetch(fields, sort_by, sort_order, page, limit, filters, headers)
    this.path = ''
    return response
  }

  static async getBrand(id, params = {}) {
    try {
      const { data } = await axiosInstance.get(
        `${this.baseURL()}/${this.resource()}/getOne/${id}`,
        {
          params,
        }
      )
      return data
    } catch (error) {
      return { error }
    }
  }

  static async delete(brandId) {
    try {
      const { data } = await axiosInstance.delete(`${this.baseURL()}/${this.resource()}/${brandId}`)
      return data
    } catch (error) {
      return error
    }
  }

  static getFileExtensionAndName(url) {
    if (url) {
      const urlWithoutParams = url.split('?')[0]
      const filename = urlWithoutParams.substring(urlWithoutParams.lastIndexOf('/') + 1)
      const ext = filename.substring(filename.lastIndexOf('.') + 1)
      const name = filename.substring(0, filename.lastIndexOf('.'))

      return {
        ext,
        name,
      }
    }
    return null
  }

  static async getBrandRequest(id, params = {}) {
    try {
      const { data } = await axiosInstance.get(
        `${this.baseURL()}/${this.resource()}/requests/getOne/${id}`,
        {
          params,
        }
      )
      return data
    } catch (error) {
      return { error }
    }
  }

  /**
   * Modify a existent resource
   * @param {Number} id - resource Id
   * @param {object} payload  - Data to update
   * @param {string} otherResouce param  - String
   * @param {boolean} isFormData  - Data is send should be converted in form-data
   * @returns {object} resource
   */
  static async massiveUpdateBrandsRequests(payload = {}, path) {
    try {
      const { data } = await this.updateMassive(path, payload)
      return data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  static async getBrandCatgories() {
    try {
      this.path = '/categories'
      const response = await this.get('')
      this.path = ''
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  static async saveBrandRequest(data) {
    try {
      this.path = '/requests'
      const response = await this.post(data, true)
      this.path = ''
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  static async editBrandRequest(payload) {
    try {
      const { data } = await axiosInstance.put(
        `${this.baseURL()}/${this.resource()}/requests/${payload.id}`,
        payload,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
      return data
    } catch (error) {
      console.log(error)
      return error
    }
  }

  static async cancelBrandRequest(id) {
    try {
      this.path = `/requests/`
      const response = await this.delete(id)
      this.path = ''
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  static async getBrandRequestsCount(vendorId) {
    try {
      this.path = '/requests'
      const response = await this.countByFields('logoscorpecommerce__Status', [
        {
          field: 'logoscorpecommerce__Vendor_Ecommerce_Id',
          operation: '=',
          value: vendorId ? `${vendorId}` : vendorId,
        },
        {
          field: 'logoscorpecommerce__Status',
          operation: '!=',
          value: 'null',
        },
      ])
      this.path = ''
      return response
    } catch (error) {
      console.log(error)
      return error
    } finally {
      this.path = ''
    }
  }

  static async saveBrand(data) {
    try {
      this.path = ''
      const response = await this.post(data, true)
      this.path = ''
      return response
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  static async editBrand(payload) {
    try {
      const { data } = await axiosInstance.put(
        `${this.baseURL()}/${this.resource()}/${payload.id}`,
        payload,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
      return data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async getBrandCount() {
    try {
      const response = await this.countByFields('logoscorpecommerce__enabled', [
        {
          field: 'id',
          operation: 'is not',
          value: 'null',
        },
      ])

      return response
    } catch (error) {
      console.error(error)
      return error
    }
  }
}
