import React, { useState, useEffect, useCallback } from 'react'
import {
  Grid,
  CircularProgress,
  Typography,
  Box,
  Alert,
  Container,
  Stack,
  List,
  ListItem,
  ListItemText,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useAuthContext } from '../../auth/useAuthContext'
import { ZOHO } from '../../lib/ZohoEmbededAppSDK'
import { updateVendorResources } from '../../redux/slices/vendor'
import { dispatch } from '../../redux/store'

const ApprovedReject = () => {
  // const { zohoId, entity, isZohoLoaded } = useContext(UserContext)
  const { zWidgetData } = useAuthContext()

  const [vendor, setVendor] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [alertMessage, setAlertMessage] = useState({
    severity: '',
    message: '',
  })

  const getRecord = useCallback(async () => {
    const currentRecord = await ZOHO.CRM.API.getRecord({
      Entity: zWidgetData.Entity,
      RecordID: zWidgetData.EntityId[0],
    })
    setVendor(currentRecord.data[0])
    setIsLoading(false)
  }, [zWidgetData])

  useEffect(() => {
    if (zWidgetData.EntityId.length === 1) {
      getRecord()
    } else {
      setAlertMessage({
        severity: 'error',
        message: 'No se puede aceptar/rechazar mas de un vendor a la vez',
      })
    }
  }, [zWidgetData, getRecord])

  const handleSubmit = async (option) => {
    setIsSaving(true)
    try {
      await dispatch(
        updateVendorResources({
          id: vendor.id,
          logoscorpecommerce__Status: option,
          otherResource: 'approveReject',
        })
      )
      setAlertMessage({
        severity: 'success',
        message: `El vendedor ha sido ${option === 'Activo' ? 'aprobado' : 'rechazado'} con éxito.`,
      })
    } catch (error) {
      console.log('error', error)
      if (error.message) {
        // Revisar
        const errorMessage = error.parameters?.length
          ? error.message.replace('%1', error.parameters[0])
          : error.message
        setAlertMessage({ severity: 'error', message: errorMessage })
      } else {
        setAlertMessage({ severity: 'error', message: 'Ha ocurrido un error inesperado.' })
      }
    }
    setIsSaving(false)
  }

  if (isLoading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }
  if (alertMessage.message) {
    return (
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        </Grid>
      </Grid>
    )
  }
  return (
    <Container
      fluid="true"
      maxWidth="100%"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '40px',
      }}
    >
      <Typography variant="h6" sx={{ width: '100%' }}>
        Indica si deseas aprobar o rechazar al vendedor {vendor.Vendor_Name}
      </Typography>

      <Stack direction="column" spacing={3} sx={{ mt: 5, width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <LoadingButton
            variant="contained"
            size="small"
            sx={{ pl: 5, pr: 5 }}
            onClick={() => handleSubmit('Activo')}
            loading={isSaving}
          >
            Aprobar
          </LoadingButton>

          <LoadingButton
            variant="contained"
            size="small"
            sx={{ pl: 5, pr: 5 }}
            onClick={() => handleSubmit('Inactivo')}
            loading={isSaving}
          >
            Rechazar
          </LoadingButton>
        </Box>

        <Box>
          <Typography variant="subtitle2" sx={{ lineHeight: 1 }}>
            Requisitos para la solicitud:
          </Typography>

          <List>
            <ListItem sx={{ pb: 0, pt: 0 }}>
              <ListItemText secondary="1. El vendedor debe contener su RIF para poder ser aprobado." />
            </ListItem>

            <ListItem sx={{ pb: 0, pt: 0 }}>
              <ListItemText secondary="2. El nombre del vendedor no puede contener caracteres especiales." />
            </ListItem>
          </List>
        </Box>
      </Stack>
    </Container>
  )
}

export default ApprovedReject
