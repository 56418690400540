import { ZOHO } from '../lib/ZohoEmbededAppSDK';

export async function saveRecord(apiData, entity, isUpdate){
  return isUpdate ? updateRecord(apiData, entity) : createRecord(apiData, entity)
}

export async function deleteRecords(entity, recordId){
  return ZOHO.CRM.API.deleteRecord({
    Entity:entity,
    RecordID: recordId 
  })
}

async function createRecord(apiData, entity){
  return ZOHO.CRM.API.insertRecord({
    Entity: entity, 
    APIData: apiData,
  });
};

async function updateRecord(apiData, entity){
  await ZOHO.CRM.API.updateRecord({
    Entity: entity,
    APIData: apiData,
  });
};

export function getUniqueArrays(arr1, arr2, prop){
  const uniqueArr1 = arr1.filter((obj1) => !arr2.some((obj2) => obj1[prop] === obj2[prop]));
  const uniqueArr2 = arr2.filter((obj2) => !arr1.some((obj1) => obj2[prop] === obj1[prop]));
  return [uniqueArr1, uniqueArr2];
}
