import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Grid, CircularProgress, Typography, Box, Alert, Container, Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useAuthContext } from '../../auth/useAuthContext'
import { ZOHO } from '../../lib/ZohoEmbededAppSDK'
import { ApproveRejectCancelAccountResources } from '../../redux/slices/cancelAccountRequests'
import { dispatch } from '../../redux/store'
import LinearProgressWithLabel from '../../components/linear-progress/LinearProgressWithLabel'
import { getProductsCountByStatus, deleteMagentoProducts } from '../../redux/slices/product'
import { useSnackbar } from '../../components/snackbar'

const ApproveRejectCancelAccountRequest = () => {
  // const { zohoId, entity, isZohoLoaded } = useContext(UserContext)
  const { zWidgetData } = useAuthContext()

  const [cancelAccountRequest, setCancelAccountRequest] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [alertMessage, setAlertMessage] = useState({
    severity: '',
    message: '',
  })
  const [percentUpload, setPercentUpload] = useState(0)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const getRecord = useCallback(async () => {
    const currentRecord = await ZOHO.CRM.API.getRecord({
      Entity: zWidgetData.Entity,
      RecordID: zWidgetData.EntityId[0],
    })
    setCancelAccountRequest(currentRecord.data[0])
    setIsLoading(false)
  }, [zWidgetData])

  useEffect(() => {
    if (zWidgetData.EntityId.length === 1) {
      getRecord()
    } else {
      setAlertMessage({
        severity: 'error',
        message: 'No se puede aceptar/rechazar mas de una solicitud a la vez',
      })
    }
  }, [zWidgetData, getRecord])

  const handleSubmit = async (option) => {
    setIsSaving(true)
    const deleteErrorsProducts = []
    const otherErrorProducts = []
    try {
      let deleteProducts = 0
      let hasMoreToDelete = true
      setLoadingDelete(true)
      setPercentUpload(5)
      while (hasMoreToDelete) {
        const { hasMore, countDeleted, countAll, deleteErrors } = await dispatch(
          deleteMagentoProducts(cancelAccountRequest?.logoscorpecommerce__vendor_ecommerce_id, 10)
        )
        deleteErrors.forEach((error) => {
          if (
            error.error.message.includes(
              'El producto solicitado no existe. Verifique el producto y vuelva a intentarlo.'
            )
          ) {
            deleteErrorsProducts.push(error.sku)
          } else otherErrorProducts.push(error.sku)
        })

        hasMoreToDelete = hasMore
        deleteProducts = countDeleted
        const percent = countAll === 0 ? 100 : (deleteProducts / countAll) * 100
        setPercentUpload(percent)
      }
      await dispatch(
        ApproveRejectCancelAccountResources({
          id: cancelAccountRequest.id,
          // id: '4934577000010316015',
          status: option,
          otherResource: 'approveReject',
        })
      )
      setAlertMessage({ severity: 'success', message: `La solicitud ha sido ${option} con éxito.` })
      if (deleteErrorsProducts.length) {
        enqueueSnackbar(
          `Producto solicitado no existen. Verifique : ${deleteErrorsProducts.join(',')}`,
          { variant: 'error' }
        )
      }
      if (otherErrorProducts.length) {
        enqueueSnackbar(
          `Error al borrar los siguientes productos. Verifique : ${otherErrorProducts.join(',')}`,
          { variant: 'error' }
        )
      }
    } catch (error) {
      console.log('error', error)
      if (error.message) {
        // Revisar
        const errorMessage = error.parameters?.length
          ? error.message.replace('%1', error.parameters[0])
          : error.message || error
        setAlertMessage({ severity: 'error', message: errorMessage })
      } else {
        setAlertMessage({ severity: 'error', message: 'Ha ocurrido un error inesperado.' })
      }
    } finally {
      setLoadingDelete(false)
    }
    setIsSaving(false)
  }

  if (isLoading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }
  if (alertMessage.message) {
    return (
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        </Grid>
      </Grid>
    )
  }
  return (
    <>
      {loadingDelete && (
        <Box marginBottom="10px" marginTop="10px" marginX="10px">
          <LinearProgressWithLabel variant="determinate" value={percentUpload} />
        </Box>
      )}
      <Container
        fluid="true"
        maxWidth="100%"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '40px',
        }}
      >
        <Typography variant="h6">
          Indica si deseas aprobar o rechazar la solicitud de &ldquo;cancelación de cuenta&rdquo; de{' '}
          {cancelAccountRequest.Name}
        </Typography>

        <Stack direction="row" spacing={3} sx={{ mt: 5 }}>
          <LoadingButton
            variant="contained"
            size="small"
            sx={{ pl: 5, pr: 5 }}
            onClick={() => handleSubmit('Aprobado')}
            loading={isSaving}
          >
            Aprobar
          </LoadingButton>

          <LoadingButton
            variant="contained"
            size="small"
            sx={{ pl: 5, pr: 5 }}
            onClick={() => handleSubmit('Rechazado')}
            loading={isSaving}
          >
            Rechazar
          </LoadingButton>
        </Stack>
      </Container>
    </>
  )
}

export default ApproveRejectCancelAccountRequest
