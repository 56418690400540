import { createSlice } from '@reduxjs/toolkit'
import UploadDetailsService from '../../services/uploadDetailsService'

// ----------------------------------------------------------------------

export const fieldsUploadDetails = [
    'logoscorpecommerce__Log_de_importaci_n_padre',
    'logoscorpecommerce__Product',    
    'logoscorpecommerce__Product.logoscorpecommerce__Name1',
    'logoscorpecommerce__SKU',
    'logoscorpecommerce__Status',
    'logoscorpecommerce__Description',
    'logoscorpecommerce__Type',
    'logoscorpecommerce__Precio_anterios',
    'logoscorpecommerce__Precio_nuevo',
    'logoscorpecommerce__Stock',
  ]

  const initialState = {
    isLoading: false,
    error: null,
    uploadDetails: [],
    uploadDetailsInfo: {
      countAll: 0,
  },
  }

  const slice = createSlice({
    name: 'uploadDetails',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false
        state.error = action.payload
      },
  
      // GET UPLOADS DETAILS
      getLogDetailsSuccess(state, action) {
        state.isLoading = false
        console.log( action.payload.data)
        state.uploadDetails = action.payload.data
        /* state.uploadDetails = action.payload.data.map(details => {
          const newDetails = details
          newDetails.Product_Name = newDetails['logoscorpecommerce__Product.logoscorpecommerce__Name1']
          delete newDetails['logoscorpecommerce__Product.logoscorpecommerce__Name1']
          return newDetails
        }) */
        state.uploadDetailsInfo = action.payload.info
      },
  
    },
  })

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------


export function getLogsDetails(page = 1, limit = 20, sort_order = 'desc', sort_by = 'id', filters = []) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading())
      try {        
        const response = await UploadDetailsService.fetch(
          [...fieldsUploadDetails].join(','),
          sort_by,
          sort_order,
          page,
          limit,
          filters,
        )        
        dispatch(slice.actions.getLogDetailsSuccess(response))
      } catch (error) {
        dispatch(slice.actions.hasError(error))
      }
    }
  }