// ----------------------------------------------------------------------
/**
 * La funcion se encarga de formatear el nro de telefono 
 * 
 * @param {string}      value          Nro de Tlfn  (04144548049)
 * @param {number=}     index          Posicion del ultimo digito del prefijo (4)
 * 
 * @returns {array}  (0414, 456-3245)
 */
export function split_at_index(value, index) {
    if(value) {
      return [value.substring(0, index), `${value.substring(index).substring(0, 3)}-${value.substring(index).substring(3)}`]
    }
    return ['','']
    
  }
/**
 * La funcion se encarga de hacer split o devolver vacio si no existe el nombre 
 * 
 * @param {string}      value          Carlos Blanco
 * 
 * @returns {array}  [Carlos, Blanco] || ['','']
 */  
  export function split_name(value) {
    if(value) {
      return value.split(' ')
    }
    return ['','']
    
  }
/**
 * La funcion se encarga de devolver la inicial correspondiente al tipo de documento
 * 
 * @param {string}      value          Jurídica || Natural
 * 
 * @returns {string}  J || V
 */    
  export function getPrefix(value) {
    return value === 'Jurídica' ? 'J' : 'V'
  }  

  export function formatInstagram(value) {
    if(value){
      return `@${(value?.replace('@','') || '')}`
    }
    return null
  }