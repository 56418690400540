import { createSlice } from '@reduxjs/toolkit'
import AttributeService from '../../services/attributesService'

const initialState = {
  isLoading: false,
  isSaving: false,
  error: null,
  options: [],
  attributeCode: null,
}

const slice = createSlice({
  name: 'attribute',
  initialState,
  reducers: {
    // ONSAVEOPTIONS
    onSaveOptions(state, action) {
      state.isSaving = false
      state.options = { ...state.options, ...action.payload }
    },
  },
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------
export function getAttributeOptions(attributeCode) {
  return async (dispatch) => {
    try {
      const response = await AttributeService.getOptions(attributeCode)
      dispatch(slice.actions.onSaveOptions(response))
      return response
    } catch (error) {
      console.log(error)
      throw new Error(error?.message || 'Error al obtener las opciones')
    }
  }
}
