import { createSlice } from '@reduxjs/toolkit'
import CategoryService from '../../services/categoryService'
import AttributesService from '../../services/attributesService'

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  categories: [],
  isLoadingCategoriesAttributes: false,
  categoriesAttributes: [],
  categoriesInfo: {
    countAll: 0,
  },
}

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET CATEGORIES
    getCategoriesSuccess(state, action) {
      // Add Path to category tree
      const addPathToTree = (tree, path = []) => tree.map((node) => {
        const nodePath = [...path, node.name]
        node.path = nodePath
        if (node.children) {
          node.children = addPathToTree(node.children, nodePath)
        }
        return node
      });
        
      state.categories = addPathToTree(action.payload.data) || []
      state.categoriesInfo = action.payload.info
      state.isLoading = false
    },

    // START LOADING CATEGORIES ATTRIBUTES
    startLoadingCategoriesAttributes(state) {
      state.isLoadingCategoriesAttributes = true
    },

    // GET CATEGORIES ATTRIBUTES
    getCategoriesAttributesSuccess(state, action) {
      state.categoriesAttributes =
      action.payload.data.map((data) => ({
        ...data,
        attributes: data.attributes.filter(Boolean),
      })) || [];
      state.isLoadingCategoriesAttributes = false
    },

    cleanCategoryAttributes(state, action) {
      state.isLoadingCategoriesAttributes = false
      state.categoriesAttributes = []
    },
  },
})

// Reducer
export default slice.reducer

// Actions
export const { cleanCategoryAttributes } = slice.actions

// ----------------------------------------------------------------------

export function getCategories() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await CategoryService.fetch()
      dispatch(slice.actions.getCategoriesSuccess(response))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function getCategoriesAttributes(ecommerceAttributeSetsIds) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingCategoriesAttributes())
    try {
      const response = await AttributesService.fetch(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          ecommerce_attribute_set_ids: `${ecommerceAttributeSetsIds.filter(id=>(!!id)).join(',')}`,
        }
      )
      dispatch(slice.actions.getCategoriesAttributesSuccess(response))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
    return []
  }
}
