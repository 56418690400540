import { useSelector } from 'react-redux'
import { createSlice } from '@reduxjs/toolkit'
import VendorService from '../../services/vendorService'
import CategoryService from '../../services/categoryService'
import MagentoProductService from '../../services/magentoProductService'
import { parseDataAmount } from '../../utils/graphUtils'
import ProductService from '../../services/productService'

// ----------------------------------------------------------------------

/** Manager */
export const managerField = [
  'logoscorpecommerce__firstname',
  'logoscorpecommerce__lastName',
  'logoscorpecommerce__manager_city',
  'logoscorpecommerce__manager_country',
  'logoscorpecommerce__manager_doc_number',
  'logoscorpecommerce__manager_doc_type',
  'logoscorpecommerce__manager_email',
  'logoscorpecommerce__manager_house_number',
  'logoscorpecommerce__manager_phone_number',
  'logoscorpecommerce__manager_postal_code',
  'logoscorpecommerce__manager_residence',
  'logoscorpecommerce__manager_secondary_phone_number',
  'logoscorpecommerce__manager_state',
  'logoscorpecommerce__manager_street',
]
/** Legal Representative */
export const legalField = [
  'logoscorpecommerce__legal_representative_doc_number',
  'logoscorpecommerce__legal_representative_doc_type',
  'logoscorpecommerce__legal_representative_email',
  'logoscorpecommerce__legal_representative_firstName',
  'logoscorpecommerce__legal_representative_lastName',
  'logoscorpecommerce__legal_representative_phone_number',
  'logoscorpecommerce__legal_representative_secondary_phone_number',
]

/** Warehouse */
export const wareHouseField = [
  'logoscorpecommerce__warehouse_manager_city',
  'logoscorpecommerce__warehouse_manager_country',
  'logoscorpecommerce__warehouse_manager_doc_number',
  'logoscorpecommerce__warehouse_manager_doc_type',
  'logoscorpecommerce__warehouse_manager_email',
  'logoscorpecommerce__warehouse_manager_firstName',
  'logoscorpecommerce__warehouse_manager_house_number',
  'logoscorpecommerce__warehouse_manager_lastName',
  'logoscorpecommerce__warehouse_manager_phone_number',
  'logoscorpecommerce__warehouse_manager_postal_code',
  'logoscorpecommerce__warehouse_manager_residence',
  'logoscorpecommerce__warehouse_manager_secondary_phone_number',
  'logoscorpecommerce__warehouse_manager_state',
  'logoscorpecommerce__warehouse_manager_street',
]

/** Fields in Auth Context */
export const zohoAdminField = [
  'logoscorpecommerce__Commission_Percentage',
  'logoscorpecommerce__store_has_additional_comission',
  'logoscorpecommerce__store_additional_comission_ecommerce_id',
  'logoscorpecommerce__catalyst_user_id',
]

/** Fields in Auth Context */
export const socialField = [
  'logoscorpecommerce__store_website',
  'logoscorpecommerce__store_RRSS_twitter',
  'logoscorpecommerce__store_RRSS_facebook',
  'logoscorpecommerce__store_RRSS_instagram',
  'logoscorpecommerce__store_RRSS_youtube',
  'logoscorpecommerce__store_RRSS_vimeo',
  'logoscorpecommerce__store_RRSS_moleskine',
]

/** Fields in Auth Context */
export const policyField = [
  'logoscorpecommerce__store_return_policy',
  'logoscorpecommerce__store_shipping_policy',
  'logoscorpecommerce__store_privacy_policy',
]

export const fieldsVendor = [
  'Email',
  'Vendor_Name',
  'Phone',
  'Category',
  // 'sambil__Categor_a_de_la_Tienda',
  'GL_Account',
  'Description',
  // 'sambil__Destacar_tienda',
  'logoscorpecommerce__Featured_Store',
  // 'sambil__Documento_de_identidad_del_Representante_Legal',
  'Tag',
  // 'sambil__IGTF',
  'logoscorpecommerce__IGTF',
  'Record_Image',
  // 'sambil__Porcentaje_de_Comisi_n',
  // 'sambil__Posici_n_de_la_tienda',
  'Owner',
  // 'sambil__Raz_n_Social',
  // 'sambil__Representante_Legal',
  // 'sambil__Servicio_que_Prestan',
  'logoscorpecommerce__Service_Provide',
  'Website',
  'Exchange_Rate',
  // 'sambil__Tipo',
  'logoscorpecommerce__Type',
  // 'sambil__Visible_en_Tienda',
  'logoscorpecommerce__Visible_In_Store',
  'logoscorpecommerce__store_logo_pic',
  'logoscorpecommerce__store_rif',
  'logoscorpecommerce__Status',
  'Currency',
  'Country',
  'State',
  'City',
  'Street',
  'Zip_Code',
  'Created_By',
  'Modified_By',
  'logoscorpecommerce__store_is_contributor',
  'logoscorpecommerce__company_locality',
  'logoscorpecommerce__bussines_name',
  'logoscorpecommerce__bussines_sector',
  'logoscorpecommerce__store_banner_pic',
  'logoscorpecommerce__ecommerce_id',
  'Created_Time',
  'logoscorpecommerce__ecommerce_category_id',
]

const initialState = {
  isLoading: false,
  isSaving: false,
  error: null,
  vendor: null,
  vendors: [],
  contactsVendor: [],
  contactsError: null,
  isConctactLoading: false,
  categoryVendor: [],
  salesData: null,
  loadingSales: false,
}

const slice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // START SAVING
    startSaving(state) {
      state.isSaving = true
    },

    // ENDSAVING SAVING
    endSaving(state) {
      state.isSaving = false
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.isSaving = false
      state.error = action.payload
    },

    // GET VENDOR
    getVendorSuccess(state, action) {
      state.isLoading = false
      state.vendor = state.vendor ? { ...state.vendor, ...action.payload } : action.payload
    },

    // GET VENDORS
    getVendorsSuccess(state, action) {
      state.isLoading = false
      state.vendors = action.payload.data
    },

    // ONSAVEVENDOR
    onSaveVendor(state, action) {
      state.isSaving = false
      state.vendor = { ...state.vendor, ...action.payload }
    },

    // GET VENDORS SALES CHART
    getSalesDataSuccess(state, action) {
      state.loadingSales = false
      state.salesData = action.payload
    },
    startSavingChart(state, action) {
      state.loadingSales = true
    },
    endSavingChart(state, action) {
      state.loadingSales = false
    },
  },
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------
export function saveVendor(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startSaving())
    try {
      delete data.Record_Image
      if (data.logoscorpecommerce__Status) delete data.logoscorpecommerce__Status
      const response = await VendorService.update(data.id, data, data.isFormData || false)
      dispatch(slice.actions.onSaveVendor(data))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
      throw new Error('Ha ocurrido un error, por favor intenta mas tarde.')
    }
  }
}

export function getVendor(filters = [], fieldsVendor = fieldsVendor) {
  console.log(fieldsVendor)
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await VendorService.fetch(
        [...fieldsVendor].join(','),
        'id',
        'desc',
        undefined,
        undefined,
        filters
      )
      dispatch(slice.actions.getVendorSuccess(response.data[0]))
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function getVendors(
  page = 1,
  limit = 2000,
  sort_order = 'desc',
  sortBy = 'id',
  filters = []
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await VendorService.fetch(
        fieldsVendor.join(','),
        sortBy,
        sort_order,
        page,
        limit,
        [
          {
            field: 'logoscorpecommerce__Status',
            operation: '=',
            value: 'Activo',
          },
          ...filters,
        ]
      )
      dispatch(slice.actions.getVendorsSuccess(response))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function updatePassword(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startSaving())
    try {
      const response = await VendorService.updateOtherResource(data.id, data, data.otherResource)
      dispatch(slice.actions.startSaving())
      return response.data
    } catch (error) {
      console.log(error)
      dispatch(slice.actions.hasError(error))
      throw new Error('Ha ocurrido un error, por favor intenta mas tarde.')
    } finally {
      dispatch(slice.actions.endSaving())
    }
  }
}

export function getCategoryVendor(category = 2) {
  return async (dispatch) => {
    try {
      const response = await CategoryService.fetch(
        [],
        'id',
        'desc',
        undefined,
        undefined,
        [],
        {},
        {},
        `getLevel/${category}`
      )
      dispatch(slice.actions.getVendorSuccess({ categoryVendor: response }))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}
export function updateVendorResources(data, updateVendor = true) {
  return async (dispatch) => {
    dispatch(slice.actions.startSaving())
    try {
      await VendorService.updateOtherResource(data.id, data, data.otherResource)
      dispatch(slice.actions.endSaving())
    } catch (error) {
      console.log(error)
      dispatch(slice.actions.hasError(error))
      throw new Error(error.error?.message || 'Ha ocurrido un error, por favor intenta mas tarde.')
    }
    //  finally {
    //   dispatch(slice.actions.endSaving())
    // }
  }
}

export function visibleStore(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startSaving())
    try {
      const {
        ecommerceId,
        logoscorpecommerce__Visible_In_Store,
        vendorZohoId,
        ecommerceCategoryId,
      } = data
      let enableProducts = -1
      let responseDisable = true
      let resultsZoho

      if (!logoscorpecommerce__Visible_In_Store && responseDisable) {
        while (enableProducts !== 0) {
          const { data } = await MagentoProductService.disableProductsBySku(`S1-V${ecommerceId}`)
          if (data && data?.success) {
            enableProducts = data.enabled_products
            responseDisable = data.success
          } else {
            throw new Error(data || 'Error al desactivar los productos')
          }
        }
      }

      const result = await VendorService.visibleStoreToggleMagento(ecommerceId, {
        logoscorpecommerce__Visible_In_Store: !!logoscorpecommerce__Visible_In_Store,
        vendorZohoId,
        ecommerceCategoryId,
      })

      if (result.status === 'SUCCESS') {
        resultsZoho = await VendorService.visibleStoreToggle(ecommerceId, {
          logoscorpecommerce__Visible_In_Store: !!logoscorpecommerce__Visible_In_Store,
          vendorZohoId,
          ecommerceCategoryId,
        })
      } else {
        throw new Error(result?.error || 'Error al desactivar el vendor en el ecommerce ')
      }
      dispatch(slice.actions.endSaving())
      return resultsZoho
    } catch (error) {
      console.log(error)
      throw new Error(error?.error || 'Ha ocurrido un error, por favor intenta mas tarde.')
    } finally {
      dispatch(slice.actions.endSaving())
    }
  }
}

export function getSales({ payload, interval, id }) {
  return async (dispatch) => {
    dispatch(slice.actions.startSavingChart())
    try {
      const result = await MagentoProductService.getSalesData(payload, id)
      const dataGraph = parseDataAmount(result, payload[0], payload[1])[interval]

      dispatch(slice.actions.getSalesDataSuccess(dataGraph))
      dispatch(slice.actions.endSavingChart())
      return dataGraph
    } catch (error) {
      console.log(error)
      throw new Error(error?.error || 'Ha ocurrido un error, por favor intenta mas tarde.')
    } finally {
      dispatch(slice.actions.endSavingChart())
    }
  }
}

export function deleteVendor(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startSaving())
    try {
      const {
        logoscorpecommerce__vendor_ecommerce_id,
        logoscorpecommerce__vendor_name,
        vendorZohoId,
        ecommerceCategoryId,
      } = data
      let enableProducts = -1
      let responseDisable = true
      let resultsZoho

      if (responseDisable) {
        while (enableProducts !== 0) {
          const { data } = await MagentoProductService.disableProductsBySku(
            `S1-V${logoscorpecommerce__vendor_ecommerce_id}`
          )
          if (data && data?.success) {
            enableProducts = data.enabled_products
            responseDisable = data.success
          } else {
            throw new Error(data || 'Error al desactivar los productos asociados')
          }
          console.log('enableProducts', enableProducts)
        }
      }

      const resultMagento = await VendorService.visibleStoreToggleMagento(
        logoscorpecommerce__vendor_ecommerce_id,
        {
          logoscorpecommerce__Visible_In_Store: false,
          vendorZohoId,
          ecommerceCategoryId,
        }
      )

      if (resultMagento.status === 'SUCCESS') {
        resultsZoho = await VendorService.visibleStoreToggle(
          logoscorpecommerce__vendor_ecommerce_id,
          {
            logoscorpecommerce__Visible_In_Store: false,
            vendorZohoId,
            ecommerceCategoryId,
          }
        )
      } else {
        throw new Error(result?.error || 'Error al desactivar el vendor en el ecommerce ')
      }

      const result = await VendorService.cancelAccountRequest({
        logoscorpecommerce__vendor_name,
        logoscorpecommerce__vendor_ecommerce_id: logoscorpecommerce__vendor_ecommerce_id.toString(),
      })

      console.log('result de cancel ccount', result, resultsZoho)
      if (result[0].code !== 'SUCCESS') {
        throw new Error(result?.error || 'Error al generar solicitud de cancelación de vendor')
      }
      dispatch(slice.actions.endSaving())
      return result[0]
    } catch (error) {
      console.log(error)
      throw new Error(error?.error || 'Ha ocurrido un error, por favor intenta mas tarde.')
    } finally {
      dispatch(slice.actions.endSaving())
    }
  }
}
