import React from 'react'
import PropTypes from 'prop-types';
import { Box, Alert } from '@mui/material'
import Iconify from '../iconify'
// import WarningAmberIcon from '@mui/icons-material/WarningAmber';

UserMessage.propTypes = {
  type: PropTypes.any,
  msg: PropTypes.any,
};

function UserMessage(props) {
  const { type, msg } = props
  return (
    <Box
      sx={{
        padding: '10px',
        background: '#FFFFFF',
        width: '100%',
        marginTop: '-30px',
        marginBottom: '20px',
      }}
    >
      {/* <Iconify icon="eva:more-vertical-fill" /> */}
      <Alert severity={type}>{msg}</Alert>
    </Box>
  )
}

export default UserMessage
