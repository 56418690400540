import { createSlice } from '@reduxjs/toolkit'
import ContactService from '../../services/contactService'
import VendorService from '../../services/vendorService'

// ----------------------------------------------------------------------

export const fieldsContact = [
  'Description',
  'Created_Time',
  'Phone',
  'Home_Phone',
  'sambil__N_mero_de_Documento',
  'First_Name',
  'Last_Name',
  'Email',
  'Date_of_Birth'
]

export const fieldsContactAccount = [
  'First_Name',
  'Last_Name',
  'Email',
  'Phone',
  'Mobile',  
  'logoscorpecommerce__Document_Type',
  'logoscorpecommerce__Document_Number',
  'Vendor_Name',
  'logoscorpecommerce__Company_position',
  'Mailing_Country',
  'Mailing_State',
  'Mailing_City',
  'Mailing_Street',
  'logoscorpecommerce__Residencia_o_Edificio',
  'logoscorpecommerce__Floor_or_House_Number',
  'Mailing_Zip',
]

const initialState = {
  isLoading: false,
  error: null,
  contacts: [],
  contactsInfo: {
    countAll: 0
  },
  contact: null,
}

const slice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET CONTACT
    getContactSuccess(state, action) {
      state.isLoading = false
      state.contact = action.payload
    },

    // STARTGETTING
    startGetting(state) {
      state.isConctactLoading = true
    },

    // HASCONTACTSSERROR
    hasContactsError(state, action) {
      state.isConctactLoading = false
      state.contactsError = action.payload
    },

    // GETCONTACTSVENDORSUCCESS 
    getContactsVendorSuccess(state, action) {
      state.isConctactLoading = false      
      state.contactsVendor = action.payload
    },
  },
})

// Reducer
export default slice.reducer

// Actions
// export const { getCart } = slice.actions

// ----------------------------------------------------------------------

export function getContact(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await ContactService.get(id, {
        fields: [...fieldsContact].join(','),
      })
      if (response.data && response.data.length) {
        dispatch(slice.actions.getContactSuccess(response.data[0]))
      } else {
        throw new Error('Resource not found.')
      }
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function saveContact(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await ContactService.update(data.id, data)      
      dispatch(slice.actions.getContactSuccess(data))      
      dispatch(getContactRelated(data.Vendor_Id))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function getContactRelated(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetting())
    try {
      const response = await VendorService.getContacts(id)            
      dispatch(slice.actions.getContactsVendorSuccess(response))
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasContactsError(error))
    }
  }
}

