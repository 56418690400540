import BaseService from "./baseService"
import axiosInstance from '../utils/axios'

export default class BrandService extends BaseService {
    
    static path = ''

    static resource() {
      return `productsQuestions${this.path}`
    }

    static async fetchQuestions(fields, sort_by, sort_order, page, limit, filters, headers) {
      this.path = ``
      const response = await this.fetch(fields, sort_by, sort_order, page, limit, filters, headers)
      this.path = ''
      return response
    }

    static async fetchQuestionsByVendor(id,fields, sort_by, sort_order, page, limit, filters, headers) {
      this.path = `/${id}`
      const response = await this.fetch(fields, sort_by, sort_order, page, limit, filters, headers)
      this.path = ''
      return response
    }

    static async getQuestionByQuestionId(id, params = {}) {

        try {
          const { data } = await axiosInstance.get(
            `${this.baseURL()}/${this.resource()}/question/${id}`,
            params,
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            }
          )
          return data
        } catch (error) {
          console.log(error)
          return error
        }
      
    }

    static async getAnswerByQuestionId(id, params = {}) {

      try {
        const { data } = await axiosInstance.get(
          `${this.baseURL()}/${this.resource()}/answer/${id}`,
          params,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        )
        return data
      } catch (error) {
        console.log(error)
        return error
      }
    
  }

    static async postAnswerToQuestionById(id, params = {}) {

      try {
        const { data } = await axiosInstance.post(
          `${this.baseURL()}/${this.resource()}/answer/${id}`,
          params,
        )
        return data
      } catch (error) {
        console.log(error)
        return error
      }
      
  }

}