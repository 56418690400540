import React, { useContext, useState, useEffect, useCallback } from 'react'
import { Grid, CircularProgress, Typography, Button, Box, Alert, Container } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useAuthContext } from '../../auth/useAuthContext'
import { ZOHO } from '../../lib/ZohoEmbededAppSDK'
import AttributeSetService from '../../services/attributeSetService'

const DeleteAttributeSet = () => {
  // const { zohoId, entity, isZohoLoaded } = useContext(UserContext)
  const { zWidgetData } = useAuthContext()

  const [attributeSet, setAttributeSet] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [alertMessage, setAlertMessage] = useState({
    severity: '',
    message: '',
  })

  const getRecord = useCallback(async () => {
    const currentRecord = await ZOHO.CRM.API.getRecord({
      Entity: zWidgetData.Entity,
      RecordID: zWidgetData.EntityId[0],
    })
    setAttributeSet(currentRecord.data[0])
    setIsLoading(false)
  }, [zWidgetData])

  useEffect(() => {
    if (zWidgetData.EntityId.length === 1) {
      getRecord()
    } else {
      setAlertMessage({
        severity: 'error',
        message: 'No se puede eliminar mas de un conjunto de atributo a la vez',
      })
      setIsLoading(false)
    }
  }, [zWidgetData, getRecord])

  const handleSubmit = async () => {
    setIsSaving(true)
    try {
      const result = await AttributeSetService.delete(attributeSet.id)
      if (result.status === 'success') {
        setAlertMessage({
          severity: 'success',
          message: 'Conjunto de atributos eliminado correctamente',
        })
      }
    } catch (error) {
      if (error.message) {
        const errorMessage = error.message.replace('%1', error.parameters[0])
        setAlertMessage({ severity: 'error', message: errorMessage })
      } else {
        setAlertMessage({ severity: 'error', message: 'Ha ocurrido un error inesperado.' })
      }
    }
    setIsSaving(false)
  }

  if (isLoading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }
  if (alertMessage.message) {
    return (
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        </Grid>
      </Grid>
    )
  }
  return (
    <Container
      fluid="true"
      maxWidth="100%"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '40px',
      }}
    >
      <Typography variant="h6">
        Seguro que desea eliminar el conjunto de atributos: {attributeSet.Name}?
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', margin: '10px 0', width: '100%' }}>
        {/* <LoadingButton
          variant="contained"
          size="small"
          sx={{ pl: 5, pr: 5 }}
          onClick={handleSubmit}
          loading={isSaving}
        >
          Aceptar
        </LoadingButton> */}
      </Box>
    </Container>
  )
}

export default DeleteAttributeSet
