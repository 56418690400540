import { formatISO } from 'date-fns'
import axios from '../utils/axios'
import BaseService from './baseService'

export default class InvoiceService extends BaseService {
  static path = ''

  static resource() {
    return `Orders${this.path}`
  }

  /**
   * Fetch all records from resources
   * @options {object} params - Query filters params
   * @returns {object}
   */
  static async fetchByIdMagento({ id, vendorId, headers = {} }) {
    const { data } = await axios.get(`${this.baseURL()}/${this.resource()}/${id}`, {
      headers,
    })
    // GET VENDOR ORDER DETAILS FROM ZOHO
    // const { data: vendorsOrderStatus } = await axios.get(
    //   `${this.baseURL()}/${this.resource()}/vendorsOrder/${id}`,
    //   {
    //     headers,
    //   }
    // )
    if (!data) {
      return { data: [] }
      // throw new Error('Not found')
    }
    let itemsNotParent = data?.data?.items?.filter((product) => !product.parent_item)
    if (vendorId) {
      itemsNotParent = itemsNotParent.filter(
        (product) =>
          product?.extension_attributes.marketplace?.seller_id?.toString() === vendorId?.toString()
      )
    }

    const sellerIdArray = []
    const itemsFilter = itemsNotParent?.reduce((acum, product) => {
      const sellerId = product.extension_attributes.marketplace?.seller_id
      product.sellerId = sellerId
      product.sellerName = product.extension_attributes.marketplace?.name
      if (acum[sellerId]?.items) {
        acum[sellerId].items.push(product)
      } else {
        sellerIdArray.push(sellerId)
        acum[sellerId] = {
          items: [product],
        }
      }
      return acum
    }, {})
    const resumeBySeller = sellerIdArray.reduce((acum, sellerId) => {
      acum[sellerId] = itemsFilter[sellerId].items.reduce(
        (acc, product) => ({
          name: product?.extension_attributes.marketplace?.name,
          qty_ordered: acc.qty_ordered + product.qty_ordered || 0,
          row_total: acc.row_total + product.row_total || 0,
          base_row_total: acc.base_row_total + product.base_row_total || 0,
          base_tax_amount: acc.base_tax_amount + product.base_tax_amount || 0,
          tax_amount: acc.tax_amount + product.tax_amount || 0,
          base_discount_amount: acc.base_discount_amount + product.base_discount_amount || 0,
          discount_amount: acc.discount_amount + product.discount_amount || 0,
          base_row_total_incl_tax:
            acc.base_row_total_incl_tax + product.base_row_total_incl_tax || 0,
          row_total_incl_tax: acc.row_total_incl_tax + product.row_total_incl_tax || 0,
        }),
        {
          qty_ordered: 0,
          row_total: 0,
          base_row_total: 0,
          base_tax_amount: 0,
          tax_amount: 0,
          discount_amount: 0,
          base_discount_amount: 0,
          base_row_total_incl_tax: 0,
          row_total_incl_tax: 0,
        }
      )

      return acum
    }, {})

    // ADD IGTF TO RESUME BY VENDOR
    if (data?.data?.extension_attributes?.custom_igtf?.length) {
      data?.data?.extension_attributes?.custom_igtf?.forEach((element) => {
        if (resumeBySeller[element?.seller_id]) {
          resumeBySeller[element?.seller_id].igtf = {
            igtf_base_price: element.totals.base_price,
            igtf_price: element.totals.price,
          }
        }
      })
    }

    data.data.itemsFilter = itemsFilter
    data.data.resumeBySeller = resumeBySeller
    data.data.sellerIdArray = sellerIdArray
    // data.data.vendorsOrderStatus = vendorsOrderStatus

    return data
  }

  /**
   * Modify a existent resource
   * @param {Number} id - resource Id
   * @param {object} payload  - Data to update
   * @param {string} otherResouce param  - String
   * @param {boolean} isFormData  - Data is send should be converted in form-data
   * @returns {object} resource
   */
  static async updateOtherResource(id, payload = {}, otherResouce, isFormData = false) {
    const { data } = await axios.put(
      `${this.baseURL()}/${this.resource()}/${otherResouce}/${id}`,
      payload,
      {
        headers: isFormData && { 'Content-Type': 'multipart/form-data' },
      }
    )
    return data
  }

  /**
   * Fetch all records from resources
   * @options {object} params - Query filters params
   * @returns {object}
   */
  static async fetchMagento({
    page,
    limit,
    sort_order = 'desc',
    sort_by = 'increment_id',
    filters = {},
    headers = {},
    type = 'list',
  }) {
    const filterStartDate = (filters?.filterStartDate && formatISO(filters?.filterStartDate)) || ''
    const filterEndDate = (filters?.filterEndDate && formatISO(filters?.filterEndDate)) || ''

    const filterParam = { ...filters, filterStartDate, filterEndDate }

    const finalFilters = Object.fromEntries(
      Object.entries(filterParam).filter(([_, value]) => value !== '' && value !== null)
    )
    const params = {
      sort_order,
      sort_by,
      pageSize: limit,
      currentPage: page,
      type,
      ...finalFilters,
    }
    const { data } = await axios.get(`${this.baseURL()}/${this.resource()}`, {
      params,
      headers,
    })
    if (!data) {
      return { data: [] }
      // throw new Error('Not found')
    }
    return data
  }

  /**
   * Fetch all records from resources
   * @options {object} params - Query filters params
   * @returns {object}
   */
  static async fetchMagentoByVendor({
    vendorId,
    page = 1,
    limit = 20,
    sort_order = 'desc',
    sort_by = 'increment_id',
    filters = {},
    headers = {},
  }) {
    const filterStartDate = (filters?.filterStartDate && formatISO(filters?.filterStartDate)) || ''
    const filterEndDate = (filters?.filterEndDate && formatISO(filters?.filterEndDate)) || ''
    this.path = '/orders-by-seller'
    const filterParam = { ...filters, filterStartDate, filterEndDate }

    const finalFilters = Object.fromEntries(
      Object.entries(filterParam).filter(([_, value]) => value !== '' && value !== null)
    )
    const params = {
      sort_order,
      sort_by,
      pageSize: limit,
      currentPage: page,
      vendorId,
      ...finalFilters,
    }
    const { data } = await axios.get(`${this.baseURL()}/${this.resource()}/`, {
      params,
      headers,
    })
    if (!data) {
      return { data: [] }
      // throw new Error('Not found')
    }
    this.path = ''
    return data
  }

  static async getOrderStatus() {
    const { data } = await axios.get(`${this.baseURL()}/${this.resource()}/status`)
    return data
  }
}
