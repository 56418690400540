import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Grid, CircularProgress, Typography, Button, Box, Alert, Container } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { ZOHO } from '../../lib/ZohoEmbededAppSDK'
import { useLocales } from '../../locales'
import CategoryService from '../../services/categoryService'
// import CategoryEntry from './components/CategoryEntry'
import { useAuthContext } from '../../auth/useAuthContext'

const DeleteCategories = () => {
  const { translate } = useLocales()
  const trans = useCallback((key) => translate(`zWidgetCategories.${key}`), [translate])

  const { zWidgetData } = useAuthContext()
  // const { zohoId, entity, isZohoLoaded } = useContext(UserContext)
  const [alertMessage, setAlertMessage] = useState({ severity: 'error', message: '' })
  const [record, setRecord] = useState({})
  const [categoryChildren, setCategoryChildren] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)

  const getRecord = useCallback(async () => {
    const currentRecord = await ZOHO.CRM.API.getRecord({
      Entity: zWidgetData.Entity,
      RecordID: zWidgetData.EntityId[0],
    })
    setRecord(currentRecord.data[0])
    setIsLoading(false)
  }, [zWidgetData])

  useEffect(() => {
    if (zWidgetData.EntityId.length === 1) {
      getRecord()
    } else {
      setAlertMessage({
        severity: 'error',
        message: 'No es posible eliminar varias categorías al mismo tiempo',
      })
    }
  }, [zWidgetData, getRecord])

  useEffect(() => {
    if (Object.keys(record).length > 0) {
      getCategoryChildren(record)
    }
  }, [record])

  const getCategoryChildren = async (currentRecord) => {
    const categoryChildrenResponse = await CategoryService.getCategoryChildren(
      currentRecord.logoscorpecommerce__magento_id
    )
    console.log('categoryChildrenResponse', categoryChildrenResponse)
    if (categoryChildrenResponse !== undefined) {
      console.log('entre')
      setCategoryChildren(categoryChildrenResponse)
    }
  }

  const handleSubmit = async () => {
    setIsSaving(true)
    try {
      const categoryDeleteResponse = await CategoryService.delete(
        record.logoscorpecommerce__magento_id
      )
      if (categoryDeleteResponse.data[0].status === 'success') {
        setAlertMessage({ severity: 'success', message: trans('categoryDeleteSuccess') })
      }
    } catch (error) {
      if (error.message) {
        const errorMessage = error.message.replace('%1', error.parameters[0])
        setAlertMessage({ severity: 'error', message: errorMessage })
      } else {
        setAlertMessage({ severity: 'error', message: 'Ha ocurrido un error inesperado.' })
      }
    }
    setIsSaving(false)
  }

  if (isLoading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }
  if (alertMessage.message) {
    return (
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        </Grid>
      </Grid>
    )
  }
  return (
    <Container
      fluid="true"
      maxWidth="100%"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '40px',
      }}
    >
      <Grid container xs={12}>
        <Typography mb={1} variant="h4">
          Seguro que quieres eliminar {record.Name}?
        </Typography>
        {categoryChildren.length > 0 ? (
          <Typography mb={1} fontWeight={600}>
            Como resultado estás categorías tambien se borraran:
          </Typography>
        ) : (
          ''
        )}
        {/* {categoryChildren.map((category) => (
          <CategoryEntry
            key={category.magento_id}
            name={category.name}
            depth={0}
            children={category.children}
          />
        ))} */}
      </Grid>
      <Grid container item xs={12} justifyContent="center" mt={3}>
        <LoadingButton
          variant="contained"
          size="small"
          sx={{ pl: 5, pr: 5 }}
          onClick={handleSubmit}
          loading={isSaving}
        >
          Aceptar
        </LoadingButton>
      </Grid>
    </Container>
  )
}

export default DeleteCategories
