// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const es = {
  true: 'Si',
  false: 'No',
  demo: {
    title: `Español`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hola`,
    description: `¿Necesitas ayuda? \n Por favor revisa nuestra documentación.`,
    documentation: `documentation`,
  },
  dashboardPage: {
    welcome: 'bienvenido al módulo \n de gestión de vendedores!',
    description:
      'Te recomendamos revisar las notificaciones, donde están las últimas actividades del administrador.',
    goto: 'Ir Ahora',
    products: 'Productos',
    products_label1: 'Aprobados',
    products_label2: 'No aprobados',
    products_label3: 'Pendientes',
    question_receiver: 'Preguntas',
    question_receiver_label1: 'Respondidas',
    question_receiver_label2: 'No respondidas',
    note_receiver: 'Reseñas',
    this_month: 'este mes',
    compare_sell: 'Comparativo de ventas',
    than_last_year: 'que el año pasado',
    compare_period: 'el periodo comparado',
    current_year: 'Año actual',
    last_year: 'Año anterior',
    last_order: 'Últimas órdenes',
    number_order: 'Número de orden',
    product: 'Producto',
    cat_products: 'Categorías de productos',
    buyer: 'Comprador',
    without_products: 'Sin productos',
    without_questions: 'Sin preguntas recibidas',
  },
  dashboard: 'Dashboard',
  are_you_sure_want_to_delete: '¿Estás seguro que quieres eliminar',
  are_you_sure_want_to_cancel: '¿Estás seguro que quieres cancelar',
  are_you_sure_want_to: '¿Estás seguro que quieres',
  make_sure_to_approve_simple_products:
    'Debes asegurarte de aprobar primero los productos tipo simple',
  approve_reject: 'Aprobar| Rechazar',
  active_product: 'Activar',
  inactive_product: 'Desactivar',
  approved_product: 'Aprobó',
  reject_product: 'Rechazó',
  actived_product: 'Activó',
  inactived_product: 'Desactivó',
  cancel: 'Cancelar',
  apply: 'Aplicar',
  enable: 'Habilitar',
  disabled: 'Inhabilitar',
  Jan: 'Ene',
  Apr: 'Abr',
  Aug: 'Ago',
  create_product: 'Crear producto',
  massive_load: 'Carga masiva',
  reviews: 'Reviews',
  sells: 'Ventas',
  orders: 'Órdenes',
  products_questions: 'Pregunta del producto',
  clients: 'Clientes',
  campaing: 'Campaña',
  campaings: 'Campañas',
  no_data: 'Sin datos',
  // Product Details
  products_details: 'Detalle de producto',
  return_to_list: 'Regresar a la lista',
  edit_product: 'Editar producto',
  featured_info: 'Informacion destacada',
  related_products: 'Productos Relacionados',
  view_more: 'Ver más',
  visit_our_stores: 'Visita nuestras marcas',
  questions: 'Preguntas',
  comments: 'Comentarios',
  color: 'Color',
  size: 'Talla',
  sizes: 'Tallas',
  add_to_cart: 'Agregar al carrito',
  add_to_favs: 'Agregar',
  brand: 'Marca',
  sku: 'SKU',
  availability: 'Disponibilidad',
  share: 'Compartir',
  pick_up: 'Pick up',
  days: 'Dias',
  product_info: 'Informacion del producto',
  product_specifications: 'Especificaciones del producto',
  store_info: 'Informacion de la tienda',
  show_more_products: 'Ver más productos',
  // Lista de productos
  new_product: 'Nuevo producto',
  creation: 'Creación',
  stock_load: 'Carga de stock',
  precio_load: 'Carga de precio',
  search: 'Buscar...',
  search2: 'buscar',
  selected: 'seleccionado(s)',
  clear: 'Limpiar',
  rows_per_page: 'Filas por páginas',
  products: 'Productos',
  create_at: 'Creado el',
  products_list: 'Lista de productos',
  in_ecommerce: ' En Ecommerce',
  enabled: 'Habilitado',
  active: 'Activo',
  inactive: 'Inactivo',
  edit: 'Editar',
  Edit: 'Editar',
  save: 'Guardar',
  back: 'Regresar',
  delete: 'Eliminar',
  aprobation_status: 'Estatus de aprobación',
  approved: 'Aprobado',
  pending: 'Pendiente',
  not_approved: 'No aprobado',
  visibility: 'Visibilidad',
  price: 'Precio',
  // Add Related Product
  add_related_product: 'Agregar productos relacionados',
  id: 'ID',
  type: 'Tipo',
  params: 'Atributos',
  confirm: 'Confirmar',
  add_products: 'Agregar productos',
  configurable_product: 'Producto Configurable',
  products_in_list: 'Productos en la lista',
  filter: 'Filtrar',
  apply_filter: 'Aplicar filtro',
  utlize_filter_search_related_productos:
    'Utiliza el filtro para conseguir los productos relacionados',
  // create product
  remove_all: 'Eliminar Todos',
  upload_files: 'Cargar Imagen',
  select_images: 'Selecciona las imágenes',
  imagens: 'Imágenes',
  quantity: 'Cantidad',
  select: 'Seleccionar',
  browser: 'Navegador',
  write_any: 'Escribe algo aquí...',
  drop_files_here_or_click: 'Puedes arrastrar las imágenes aquí o',
  drop_document_here_or_click: 'Puedes arrastrar el documento aquí o',
  attach_files: 'Archivos adjuntos',
  thorough_machine: 'en tu computadora',
  without_variations: 'Producto sin variaciones',
  with_variations: 'Producto con variaciones',
  next: 'Continuar',
  new_variation: 'Crear Variación',
  alert: 'Mensaje de Alerta',
  product_price: 'Precio por unidad',
  available_stock: 'Stock disponible',
  wizardVariation: {
    error_message_imcomplete_step: 'Por favor realice las selecciones correspondientes',
    step1: {
      title_step: 'Selecciona los Atributos',
      filter: {
        title: 'Paso 1: Seleccionar atributos',
        subtitle: 'Atributos Seleccionados',
        result: 'registros encontrados',
        parrafe: 'Utilizar el filtro para conseguir los Atributos relacionados',
      },
      table: {
        write_here: 'Escribe aquí...',
        code_attr: 'Código del Atributo',
        name_attr: 'Etiqueta del Atributo',
        required: 'Requerido',
        system: 'Sistema',
        visible: 'Visible',
        scope: 'Alcance',
        is_search: 'Buscable',
        is_compare: 'Comparable',
        use_browser_layer: 'Uso en navegación por capas',
      },
    },
    step2: {
      title_step: 'Valores de los Atributos',
      title: 'Paso 2: Valores de atributo',
      parrafe:
        'Seleccione valores de cada atributo para incluir en este producto. Cada combinación única de valores crea un SKU de producto único',
      card: {
        selected: 'seleccionados',
        select_all: 'Seleccionar Todos',
        not_select_all: 'Deseleccionar Todos',
      },
    },
    step3: {
      title_step: 'Asignar Imagen y Precio',
      subtitle: 'Paso 3: Asignar imágenes, precios y cantidad',
      description:
        'Según sus selecciones, se crearan 1 nuevos productos. Utilice este paso para personalizar las imágenes y el precio de sus nuevos productos.',
      image: {
        option1: 'Aplicar un solo conjunto de imágenes a todos los SKU',
        option2: 'Aplicar imágenes únicas por atributo a cada SKU',
        option3: 'Omitir la cargar de imágenes en este momento',
      },
      quantity: {
        option1: 'Aplicar una sola cantidad a cada SKU',
        option2: 'Aplicar cantidad única por atributo a cada SKU',
        option3: 'Omitir cantidad en este momento',
      },
      price: {
        option1: 'Aplicar precio único a todos los SKU',
        option2: 'Aplicar precios únicos por atributo a cada SKU',
        option3: 'Omitir precio en este momento',
      },
    },
    step4: {
      title_step: 'Resumen',
      title: 'Paso 4: Resumen',
      parrafe1: 'Revisión de nuevos productos',
      parrafe2: 'Aquí puedes ver los nuevos productos',
      table: {
        products: 'Producto',
        quantity: 'Cantidad',
        price: 'Precio',
      },
    },
  },
  wizarProduct: {
    step1: {
      select_product_type: 'Selecciona el tipo de producto',
      product_type_note1:
        'Producto sin variaciones es cuando tienes un producto que es único en su tipo. Ejemplo: Termo rojo (no tiene variaciones de otros colores).',
      product_type_note2:
        'Producto con variaciones es cuando el mismo producto tiene variaciones en su tipo.Ejemplo: varias tallas y varios colores... Termo rojo, Termo azul, Termo amarillo',
    },
    step2: {
      list: {
        title: 'Elige la categoría correcta',
      },
      search: {
        title: 'Coloca la categoría o producto',
        parrafe: 'Para definir la categoría de tu producto, indica qué quieres vender.',
      },
      select: {
        select_category_product: 'Selecciona la categoría que corresponda a tu producto',
        select_other_category: 'Selecciona otra categoría',
        category_not_match: '¿No es la categoría que estás buscando? ',
      },
      summary: {
        title: 'Confirmar la categoría',
        parrafe:
          'La categoría debe estar relacionada con el título y la foto del producto, de esta manera facilitarás la búsqueda de tus compradores. Si la categoría no es correcta, deberás modificarla y solicitar nuevamente su aprobación.',
        change_category: 'Cambiar Categoría',
      },
    },
    step3: {
      success: 'Producto creado con exito',
      product_update: 'Producto actualizado',
      product_related: {
        title: 'Productos Relacionados',
        parrafe:
          'Los productos relacionados se muestran a los clientes además del artículo que el cliente está mirando.',
        label: 'Agregar producto relacionado',
      },
      seo: {
        title: 'Optimización para buscadores (SEO)',
      },
      info: {
        tax_class: 'Clase de impuesto',
        select_tax_class: 'Seleccionar impuesto',
        label_taxes_enable: 'Precio incluye impuestos',
        title: 'Información del producto',
        upload_images: 'Subir imágenes',
        product_name: {
          label: 'Nombre del producto',
          placeholder: 'Escribe el nombre del producto aquí',
        },
        vendor: {
          label: 'Proveedor',
          placeholder: 'Seleccionar proveedor',
        },
        sku: {
          label: 'SKU',
          placeholder: 'Escribe el SKU',
        },
        price: {
          label: 'Precio',
          placeholder: 'Escribe el Precio',
        },
        inventorySource: {
          inventory: 'Inventario',
          label: 'Inventario',
          placeholder: 'Selecciona el inventario',
        },
        mark: {
          showProductOn: 'Mostrar producto en',
          label: 'Marca',
          placeholder: 'Selecciona la marca del producto',
        },
        visibility: {
          label: 'Visibilidad',
          placeholder: 'Selecciona',
          option1: 'Activo',
          option2: 'Inactivo',
          option3: 'Cátalogo, Búsqueda',
          option4: 'No visible individualmente',
        },
        enabled: {
          label: 'Habilitado',
        },
        payment_method: 'Métodos de Pago',
        delivery_method: 'Métodos de Envío',
      },
      description: {
        title: 'Inserta la información sobre el producto',
        description: {
          title: 'Descripción del producto',
          helper: 'Sobre la descripción del producto',
        },
        details: {
          title: 'Especificaciones del producto',
          helper: 'Descripción de las especificaciones del producto',
        },
        specialInfo: {
          title: 'Información destacada',
          helper: 'Descripción de la información destacada',
        },
        featuredImage: {
          title: 'Imagen destacada',
          helper: 'Sube una imagen destacada',
        },
      },
      feature: {
        title: 'Características del producto',
        description: 'Selecciona un atributo de cada característica',
        color: { placeholder: 'Color', label: 'Color' },
        size: { placeholder: 'Talla', label: 'Talla' },
        material: { placeholder: 'Material', label: 'Material' },
        country: { placeholder: 'País de manufactura', label: 'País de manufactura' },
      },
      price: {
        special_price: 'Precio especial',
        special_price_from: 'Precio especial desde',
        special_price_to: 'Precio especial hasta',
        add_date_start: 'Agrega la fecha de inicio',
        add_date_end: 'Agrega la fecha de finalización',
        label_taxes_enable: 'Precio incluye impuestos',
        tax_class: 'Clase de impuesto',
        select_tax_class: 'Seleccionar impuesto',
      },
      weight: {
        yes: 'Si',
        not: 'No',
        weight: 'Peso',
        has_weight: '¿El producto tiene peso?',
        volume: 'Volumen',
        height: 'Alto',
        width: 'Ancho',
        large: 'Largo',
      },
      stock: {
        select: 'Seleccionar',
        in_stock: 'En Stock',
        out_stock: 'Fuera de Stock',
        quantity: 'Cantidad de productos',
        quantity_placeholder: 'Agrega el número de productos',
        avalaibility: 'Disponibilidad de Stock',
        client_max: 'Cantidad de productos por clientes',
        client_max_placeholder: 'Agrega el número de productos',
        helper:
          'Por defecto, se permitirán 5 productos máximo por cliente que puede agregar al carrito',
      },
      variation: {
        table: {
          action: 'Acción',
          image: 'Imagen',
          sku: 'SKU',
          products: 'Nombre',
          color: 'Color',
          price: 'Precio',
          quantity: 'Cantidad',
          weight: 'Peso (Kg)',
          status: 'Estatus de aprobación',
        },
      },
    },
  },
  products_administration: 'Administración de productos',
  invoice_administration: 'Administración de órdenes',
  questions_products_administration: 'Administración de preguntas de productos',
  create_a_new_product: 'Crear producto',
  create_a_new_template: 'Crear nueva plantilla',
  step: 'Paso',
  type_product: 'Tipo de producto',
  category: 'Categoría',
  product_data: 'Datos del producto',
  app: `app`,
  user: `usuario`,
  list: `lista`,
  shop: `Tienda`,
  blog: `blog`,
  post: `post`,
  mail: `correo`,
  chat: `chat`,
  cards: `tarjetas`,
  posts: `posts`,
  create: `crear`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `perfil`,
  account: `cuenta`,
  product: `producto`,
  // invoice: `invoice`,
  details: `detalles`,
  checkout: `checkout`,
  calendar: `calendario`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `descripción`,
  other_cases: `otros casos`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  // massive load
  history: `Historial`,
  import_template: `Plantillas de importación`,
  import: `Importación`,
  type_template: 'Tipo de plantilla',
  type_template_to: 'Tipo de plantilla a descargar',
  select_category: 'Seleccionar categoría',
  download_template: 'Descargar plantilla',
  massive_load_product: 'Carga masiva de productos',
  import_and_validate: 'Importar y validar',
  status_can_not_changed: 'Estatus no puede ser cambiado',
  // massive load: import
  validate_result: 'Resultado de validación',
  see_result: 'Ver resultados',
  result: 'Resultado',
  status: 'Estatus',
  message: 'Mensaje',
  product_2: `Producto`,
  success: 'Exito',
  // massive load: import result filters
  search_by_name: 'Buscar por nombre',
  search_by_SKU: 'Buscar por SKU',
  search_by_date: 'Buscar por fecha',
  search_by_status: 'Buscar por status',
  // massive load: history
  listing_details: 'Detalles del listado',
  type_of_charge: 'Tipo de carga',
  date_range: 'Rango de fecha',
  date: 'Fecha',
  completed_with_errors: 'Completado con errores',
  with_errors: 'Completado con errores',
  completed: 'Completado',
  to_complete: 'Por completar',
  load_cancelled: 'Cancelada',
  update_product: 'Actualización de producto',
  view_details: 'Ver detalles',
  action: 'Acción',
  // user: edit
  my_acount: 'Mi cuenta',
  images_format: 'Formato de imagen:',
  images_formats: 'Formatos de imágenes',
  image_format: 'Formato de imagen:',
  size_resolution: 'Tamaño y resolución:',
  max_size: 'Peso máximo',
  active_store: 'Tienda Activa',
  name: 'Nombre',
  lastname: 'Apellido',
  phone: 'Teléfono',
  secondary_phone: 'Teléfono secundario',
  id_number: 'Documento de identidad',
  country: 'País',
  state: 'Estado',
  city: 'Ciudad',
  address: 'Calle o avenida',
  address2: 'Residencia o edificio',
  homeNumber: 'Piso o número de casa',
  zip: 'Código postal',
  store_name: 'Nombre de la tienda',
  store_email: 'Email',
  store_phone: 'Teléfono de la tienda',
  store_categories: 'Categorías de la tienda',
  store_desc: 'Descripción de la tienda',
  select_file: 'Selecciona el archivo',
  add_eCommerce_banner: 'Agregar banner del ecommerce',
  save_changes: 'Guardar cambios',
  change_password: 'Cambiar contraseña',
  mail_send: 'Hemos enviado al correo',
  mail_send2: ' las indicaciones para cambiar la clave de tu cuenta.',
  client_contributor: 'Cliente contribuyente',
  cancel_account: 'Cancelar cuenta',
  active_account: 'Acivar cuenta',
  are_you_sure_want_to_cancel_account: '¿Estás seguro de que desea desactivar la tienda?',
  are_you_sure_want_to_activate_account: '¿Estás seguro de que desea activar la tienda?',
  store_status: 'Estatus de tienda',
  pause_store: 'Pausar tienda',
  pause: 'Pausar',
  activate: 'Activar',
  responsible: 'Responsable',
  store_responsible: 'Responsable de la tienda',
  legal_representative: 'Representante legal',
  warehouse: 'Almacén',
  cancel_account_message_a: 'Asegúrese de que todas tus órdenes hayan sido',
  cancel_account_message_b: 'cerradas antes de desactivar la tienda.',
  cancel_account_message_c: 'En cualquier momento puede activar la tienda.',
  cancel_account_message_d: 'Te hemos enviado un SMS con un código al número registrado.',
  cancel_account_message_e:
    'Para completar la cancelación de la cuenta, ingrese el código de 6 digitos.',
  cancel_account_message_f:
    'Para completar la cancelación de la cuenta, ingrese el código de 6 digitos.',
  cancel_account_message_g: 'para enviar mensaje de nuevo hacer',
  cancel_account_message_h:
    'El equipo del Sambil se comunicará con usted para proceder con la cancelación de su cuenta.',
  inactive_account:
    'Al pausar su tienda, sus productos no se mostrarán en el portal. Los productos que hayan sido editados o creados durante la pausa, deberán ser aprobados por Sambil Online después que se active la tienda.',
  close_popup: 'Cerrar',
  click_here: 'click aquí',
  comunicate_with_admin_to_load_data:
    'Por favor, comuníquese con su Ejecutivo de Soporte para agregar o actualizar esta información',
  contributor: 'Contribuyente',
  date_created: 'Cuenta creada desde',
  ecommerce_visible: 'Visible en ecommerce',
  commision_percentage: 'Porcentaje de comisión',
  additional_comission: 'Comisión adicional',
  additional_id: 'ID de comisión adicional',
  ecommerce_id: 'ID de ecommerce',
  catalyst_user_id: 'User ID Catalyst',
  policy: 'Políticas',
  return_policy: 'Políticas de retorno',
  send_policy: 'Políticas de envío',
  privacy_policy: 'Políticas de privacidad',
  bussines_name: 'Razón social',
  bussines_sector: 'Sector o rubro',
  company_locality: 'Localidad de la compañía',
  rsss_web: 'Redes sociales y web',
  house: 'Residencia o edificio',
  add_description: 'Agrega aquí la descripción...',
  store: 'Tienda',
  // Invoice: List
  invoice: {
    invoices: 'Órdenes',
    list: 'Listado de órdenes',
    all: 'Todas',
    pending: 'Pendientes',
    completed: 'Completadas',
    canceled: 'Canceladas',
    status: 'Estatus',
    from: 'Fecha desde',
    to: 'Fecha hasta',
    ordered: 'Ordenado',
    invoiced: 'Facturado',
    shipped: 'Enviado',
    refunded: 'Refundado',
    Canceled: 'Cancelado',
    search: 'Escribe la orden del cliente que estás buscando...',
    table_head: {
      invoice_number: 'N°. de Orden',
      buyer: 'Comprador',
      created: 'Creado',
      currency: 'Moneda',
      amount: 'Monto',
      invoice_loaded: 'Fact. Cargada',
      status: 'Estatus',
      received: 'Recibido',
      product: 'Producto',
      send_method: 'Método de Envío',
      pay_method: 'Método de Pago',
      buyer_dni: 'Documento de Identidad',
      coupon: 'Cupón',
    },
    yes: 'Si',
    no: 'No',
    status_pending: 'Pendiente',
    status_processing: 'Pendiente',
    status_complete: 'Completada',
    status_canceled: 'Cancelada',
    status_closed: 'Cerrada',
    status_fraud: 'Sospecha de Fraude',
    status_holded: 'Hold',
    status_payment_review: 'Revision de Pago',
    status_paypal_canceled: 'Paypal Cancelado',
    status_paypal_reversed: 'Paypal Reversado',
    status_pending_in_edition: 'En proceso de edición',
    status_pending_payment: 'Pago Pendiente',
    status_pending_paypal: 'Pendiente Paypal',
    status_canceled_cancelado: 'Cancelado',
    status_complete_despachado: 'Despachado',
    status_complete_entregado: 'Entregado',
    status_new_pendienteporcompr: 'Pendiente por Comprobación de Pago',
    status_processing_pagoconfirmado: 'Procesando: Pago Confirmado',
    status_processing_preparandopedido: 'Procesando: Preparando Pedido',
    status_processing_porentregar: 'Procesando: Por Entregar',
    row_menu_export: 'Exportar',
    row_menu_buyer: 'Comprador',
    row_menu_details: 'Ver detalle',
    upload_invoice: 'Adjuntar factura',
    generate_ticket: 'Generar ticket',
    details: {
      dni: 'Número de documento',
      phone: 'Teléfono',
      hold_account: 'Titular de la Cuenta',
      reference_number: 'Número de Referencia',
      emit_bank: 'Banco Emisor',
      reference: 'Referencia',
      bank: 'Banco',
      title: 'Detalle de orden N°.',
      invoice_and_data: 'Orden y datos del cliente',
      invoice_no: 'Orden N°.',
      invoice_date: 'Fecha de la orden',
      invoice_status: 'Estatus de la orden',
      buy_maded: 'Compra hecha en',
      buy_ip: 'IP de la compra',
      client_info: 'Información del cliente',
      client_name: 'Nombre del cliente',
      email: 'email',
      client_type: 'Tipo de cliente',
      date_of_birth: 'Fecha de nacimiento',
      address_info: 'Información de direcciones',
      billing_address: 'Dirección de facturación',
      shipping_address: 'Dirección de envío',
      payment_shipping_ways: 'Formas de pago y métodos de envíos',
      payment_way: 'Forma de pago',
      bank_transfer: 'Transferencia Bancaria',
      exchange_rate: 'Tasa de cambio',
      shipping_way: 'Método de envío',
      invoice_items: 'Artículos de la orden',
      addtional_information: 'Información adicional',
      table_head: {
        '#': '#',
        item_status: 'Item Status',
        description: 'Descripción',
        original_price: 'Precio Original',
        price: 'Precio',
        quantity: 'Cantidad',
        taxes: 'Impuesto',
        sub_total: 'Sub total',
        total: 'Total',
        taxes_percent: '% Impuesto',
        discount: 'Descuento',
        product: 'Producto',
        subtotal: 'Sub Total',
      },
      presentation: 'Presentacion',
      sku: 'SKU',
      store: 'Tienda',
      subtotal_store: 'Sub Total',
      total_store: 'Total',
      total_invoice: 'Total de la orden',
      invoice_notes: 'Notas de la orden',
      status: 'Estatus',
      sub_total: 'Sub-Total',
      comment: 'Comentario',
      taxes: 'Impuesto',
      shipping_costs: 'Costos de envío',
      shipping: 'Envio',
      gateway_recharge: 'Recargo por pasarela',
      IGTF: 'IGTF',
      tax: 'Tax',
      grand_total: 'Total',
      total_due: 'Total No Pagado',
      total_paid: 'Total Pagado',
      total_refunded: 'Total Reembolso',
      total_discounts: 'Total descuentos',
      total: 'Total',
      questions: '¿Preguntas?',
      changeStatus: 'Cambiar status',
      changeStatusConfirm: 'Estás segur@ que deseas cambiar el status de la orden',
    },
  },
  // product questions
  product_question_list: 'Lista de preguntas sobre productos',
  question_list: 'Lista de preguntas',
  question_detail: 'Detalle de la pregunta',
  client_name: 'Nombre del cliente',
  question: 'Pregunta',
  all_questions: 'Todas las preguntas',
  last_15_days: 'Útimos 15 días',
  last_30_days: 'Últimos 30 días',
  // questions: List
  product_questions: {
    all: 'Todas',
    answered: 'Respondida',
    answer: 'Responder',
    pending: 'Por contestar',
    enabled: 'Habilitada',
    disabled: 'Deshabilitada',
    list: 'Listado',
    search: 'Periodo de tiempo',
    last_24_h: 'Últimas 24 horas',
    last_week: 'Última semana',
    last_month: 'Último mes',
    last_year: 'Último año',
    enable: 'Habilitar',
    disable: 'Deshabilitar',
    update: 'Actualizar',
    details: 'Ver detalles',
    not_found: 'Producto no encontrado',
    are_you_sure_want_to_update: '¿Estás seguro que quieres actualizar la visibilidad de estás',
    table_head: {
      id: 'ID',
      product: 'Producto',
      client_name: 'Cliente',
      question: 'Pregunta',
      date: 'Fecha',
      status: 'Estatus',
    },
  },
  // questions: Detail
  answer_and_hide: 'Responder y ocultar',
  asnwer_and_post: 'Responder y publicar',
  asnwer_and_post_desc:
    'La respuesta debe ser enviada al comprador y debe poder ser visible en la sección "preguntas" de la vista detalle de producto',
  asnwer_and_hide_desc:
    'La respuesta debe ser enviada al comprador y no debe mostrarse en la sección "preguntas" de la vista detalle de producto',
  // brands
  brands: 'Marcas',
  request_brand: 'Solicitar marca',
  request_brand_title: 'Solicitud de marca',
  brand_description:
    'Bienvenido al modulo de marcas, puedes incluir las marcas de las cuales tienes representación de la casa matriz, recuerda tener el inventario y la carta de autorización para completar el formulario.',
  brand_list: 'Listado de marcas',
  brand_detail: 'Detalle de marcas',
  brand_request: 'Solicitud de marca',
  brand_name: 'Nombre de la marca',
  brand_category: 'Categoría',
  brand_category_placeholder: 'Selecciona la categoría',
  add_brand: 'Agrega la marca',
  other_details: 'Otros detalles',
  other_details_placeholder: 'Colocar los detalles',
  brand_desc: 'Descripción de la marca',
  brand_desc_placeholder: 'Coloca aquí la descripción de la marca',
  save_new_request: 'Enviar solicitud',
  save_request: 'Guardar solicitud',
  list2: 'Listado de Marcas',
  listRequest: 'Listado de Solicitudes',
  search_brand: 'Escribe la marca que estás buscando...',
  search_vendor: 'Escribe el nombre del Vendedor que estás buscando...',
  new_brand: 'Agregar nueva marca',
  new_brand_request: 'Agregar nueva solicitud',
  are_you_sure_want_to_update_brand_status:
    'Estás seguro de que deseas actualizar el estatus de estás',
  is_a_new_brand: 'Marca nueva',
  brand_status: {
    aproved: 'Aprobada',
    rejected: 'Rechazada',
    pending: 'Pendiente',
    aproveds: 'Aprobadas',
    rejecteds: 'Rechazadas',
    pendings: 'Pendientes',
    aprove: 'Aprobar',
    reject: 'Rechazar',
    all: 'Todas',
    yes: 'Sí',
    no: 'No',
    details: 'Ver detalles',
    cancel: 'Cancelada',
    enabled: 'Habilitadas',
    disabled: 'Deshabilitadas',
    activated: 'Habilitada',
    deactivated: 'Deshabilitada',
  },
  approved_request_brand: 'Aprobó',
  reject_request_brand: 'Rechazó',
  brand_table_head: {
    id: 'ID',
    image: 'Imagen',
    brand: 'Marca',
    exclusive: 'Exclusive',
    status: 'Estatus',
    actions: 'Acciones',
    vendor: 'Vendor',
    visibility: 'Visibilidad',
  },
  make_request_brand: {
    vendor: 'Proveedor',
    upload_banner: 'Subir banner',
    upload_banner_title: 'Selecciona el banner',
    banner: 'Banner',
    upload_prices: 'Subir lista de precios',
    upload_prices_title: 'Selecciona la lista de precios',
    upload_maker: 'Carta del fabricante  ',
    upload_maker_title: 'Selecciona la carta del fabricante',
    upload_logo: 'Subir Logo',
    upload_logo_title: 'Selecciona el logo',
    logo: 'Logo',
  },
  // support
  support: 'Soporte',
  support_ticket: 'Ticket de soporte',
  all_tickets: 'Todos los tickets',
  add_ticket: 'Agregar ticket de soporte',
  create_ticket: 'Crear ticket',
  create_support_ticket: 'Crear Ticket de Soporte',
  creating_support_ticket: 'Creación de ticket de soporte',
  create_ticket_description:
    'Escríbenos para apoyarte a resolver tus dudas o darte soporte en algún módulo',
  support_table_status: {
    open: 'Abiertos',
    closed: 'Cerrados',
    pending: 'En espera',
    scaled: 'Escalados',
    all: 'Todos',
  },
  support_table_status_singular: {
    open: 'Abierto',
    closed: 'Cerrado',
    pending: 'En espera',
    scaled: 'Escalado',
  },
  support_table_head: {
    id: 'ID',
    subject: 'Asunto',
    category: 'Categoría',
    subCategory: 'Sub categoría',
    date: 'Fecha',
    status: 'Estatus',
  },
  info_support: {
    vendor_name: 'Nombre del vendor',
    vendor_id: 'ID del vendor',
    vendor_rif: 'Rif del vendor',
    vendor_email: 'Email',
    vendor_tlfn: 'Teléfono de contacto',
    priority: 'Prioridad',
    category: 'Categoría',
    subcategory: 'Sub categoría',
    subject: 'Asunto',
    description: 'Descripción',
    answer: 'Respuesta',
  },
  placeholder_support: {
    subject: 'Coloca el asunto de tu ticket',
    description: 'Agrega tu ticket de soporte',
    category: 'Selecciona la categoría',
    subcategory: 'Selecciona la sub categoría',
  },
  general_error: 'Ha ocurrido un error, por favor intenta más tarde.',
  saved_success: '¡Cambios guardados satisfactoriamente!',
  zWidgetAttributes: {
    attributeNameLabel: 'Nombre',
    inputTypeLabel: 'Tipo de atributo',
    select: 'Lista de selección',
    text: 'Texto',
    textarea: 'Área de texto',
    typePlaceholder: 'Seleccionar...',
    attributeOptionLabel: 'Opción',
    attributeOptionSubmit: 'Agregar opción ',
    attributeRequiredLabel: 'Es requerido',
    attributeSaveBtn: 'Guardar',
    duplicateAttributeError: 'Las opciones deben ser únicas',
    boolean: 'Verdadero o Falso',
    multiselect: 'Selección múltiple',
  },
  zWidgetAttributeSet: {
    attributeSetNameLabel: 'Nombre de conjunto de atributos',
    addAttributeLabel: 'Agregar atributo',
    addAttributePlaceholder: 'Agregar atributo',
    attributeSetSaveBtn: 'Guardar',
  },
  zWidgetCategories: {
    categoryNameLabel: 'Nombre de categoría',
    parentCategoryNameLabel: 'Nombre de categoría padre',
    parentCategoryPlaceholder: 'Nombre de categoría padre',
    categoryTagLabel: 'Sinónimos de la categoría',
    categoryTagPlaceholder: 'Sinónimos de la categoría',
    categoryIsActive: 'Activa',
    categorySaveBtn: 'Guardar',
    attributeSetLabel: 'Conjunto de atributos',
    attributeSetPlaceholder: 'Conjunto de atributos',
    categoryUpdateSuccess: 'Categoría editada exitosamente',
    categoryCreationSuccess: 'Categoría creada exitosamente',
    categoryMultipleCategoriesError: 'No es posible eliminar vaeias categorías al mismo tiempo',
    categoryDeleteSuccess: 'Categoría eliminada exitosamente',
  },
  accept: 'Aceptar',
  reject: 'Rechazar',
  approve: 'Aprobar',
  are_you_sure_want_to_reject: '¿Estás seguro que deseas Rechazar el producto',
  are_you_sure_want_to_accept: '¿Estás seguro que deseas Aprobar el producto',
  are_you_sure_want_to_accept_brand: '¿Estás seguro que deseas Aprobar la marca',
  are_you_sure_want_to_reject_brand: '¿Estás seguro que deseas Rechazar la marca',
  are_you_sure_want_to_enable_brand: '¿Estás seguro que deseas Habilitar la marca',
  are_you_sure_want_to_disabled_brand: '¿Estás seguro que deseas Deshabilitar la marca',
  vendor_no_approved: 'El Vendedor del producto no esta aprobado',
  product_vendor_not_approved:
    'Algunos productos tienen Vendedores que no están aprobados y no serán actualizados',
  errors: {
    name_required: 'Nombre es requerido',
    name_min: 'Nombre debe tener al menos 2 caracteres',
    name_max: 'Nombre debe tener máximo 75 caracteres',
    lastname_required: 'Apellido es requerido',
    lastname_min: 'Apellido debe tener al menos 2 caracteres',
    lastname_max: 'Apellido debe tener máximo 75 caracteres',
    phone_required: 'Teléfono es requerido',
    phone_min_required: 'Número de Teléfono debe tener al menos 7 caracteres',
    second_phone_required: 'Número de Teléfono Secundario debe tener al menos 7 caracteres',
    second_phone_min_required: 'Teléfono Secundario debe tener al menos 7 caracteres',
    document_required: 'Documento de identidad es requerido',
    document_min_required: 'Documento de identidad debe tener al menos 5 caracteres',
    email_required: 'Email es requerido',
    email_valid: 'debe ser una dirección de email valida',
    email_max: 'Email puede tener máximo 75 caracteres',
    country_required: 'País es requerido',
    country_max: 'País puede tener máximo 55 caracteres',
    state_required: 'Estado es requerido',
    state_max: 'Estado puede tener máximo 55 caracteres',
    city_required: 'Ciudad es requerido',
    city_max: 'Ciudad puede tener máximo 55 caracteres',
    street_required: 'Calle o avenida es requerido',
    street_max: 'Calle o avenida puede tener máximo 255 caracteres',
    residence_required: 'Residencia o edificio es requerido',
    residence_max: 'Residencia o edificio puede tener máximo 255 caracteres',
    house_number_max: 'Piso o Número de casa puede tener máximo 10 caracteres',
    postal_code_max: 'Código postal puede tener máximo 10 caracteres',
    field_required: 'es requerido',
    field_min: 'debe tener al menos 2 caracteres',
    field_min_5: 'debe tener al menos 5 caracteres',
    field_max: 'debe tener máximo 75 caracteres',
    min_val: 'Valor minimo 0',
    max_val: 'Valor maximo 100',
    field_big: 'debe tener máximo 255 caracteres',
  },
}

export default es
