import axios from '../utils/axios'
import BaseService from './baseService'

export default class AttributesService extends BaseService {
  static resource() {
    // return 'api/v1/users'
    return 'attributes'
  }

  /**
   * Get a resource by id
   * @param {Number} id - resource Id
   * @param {Array} params - query params
   * @returns {object} resource
   */
  static async getOptions(id) {
    const { data } = await axios.get(`${this.baseURL()}/${this.resource()}/options/${id}`)
    return data
  }
}
