import sum from 'lodash/sum'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import { createSlice } from '@reduxjs/toolkit'
// utils
import axios from '../../utils/axios'
import ProductService from '../../services/productService'
import CategoryService from '../../services/categoryService'
import { fieldsProductAttributes } from './productAttributes'
import ProductAttributesService from '../../services/productAttributesService'
import RelatedProductsService from '../../services/relatedProductsService'
import ProductMassUploadService from '../../services/productMassUploadService'
import MagentoProductService from '../../services/magentoProductService'
import { _ecommerceNewProducts } from '../../_mock/arrays'
import BrandService from '../../services/brandService'

// ----------------------------------------------------------------------

const fieldsProduct = [
  // Información del Producto 1
  'Product_Name',
  'logoscorpecommerce__Name1',
  'Product_Code',
  'logoscorpecommerce__SKU',
  'Manufacturer',
  'Product_Active',
  'Record_Image',
  'Product_Category',
  'logoscorpecommerce__Type',
  'logoscorpecommerce__Visibility',
  // Información del Producto 2
  'Description',
  'logoscorpecommerce__Details',
  'logoscorpecommerce__SpecialInfo',
  // SEO
  'logoscorpecommerce__MetaDescription',
  'logoscorpecommerce__MetaKeyWord',
  'logoscorpecommerce__MetaTitle',
  // Price
  'Unit_Price',
  'logoscorpecommerce__SpecialPrice',
  'logoscorpecommerce__PriceSpecialFrom',
  'logoscorpecommerce__PriceSpecialTo',
  // Stock
  'logoscorpecommerce__hasStock',
  'logoscorpecommerce__MaxQuantity',
  'Qty_in_Stock',
  // Weight
  'logoscorpecommerce__Weight',
  // OTHER
  'Created_By',
  'Created_Time',
  'Sales_Start_Date',
  'logoscorpecommerce__Approval_Status',
  'logoscorpecommerce__Category_id',
  // 'logoscorpecommerce__HasTax',
  'logoscorpecommerce__Tax_Class_id',
  'logoscorpecommerce__HasWeight',
  'logoscorpecommerce__Configurations',
  'logoscorpecommerce__Featured_Image_Id',
  'logoscorpecommerce__Show_Product_On',
  'logoscorpecommerce__Principal_Image_Path',
  /* 'logoscorpecommerce__Payment_methods', 
     'logoscorpecommerce__Delivery_methods', */
  'logoscorpecommerce__Create_In_Ecommerce',
  'logoscorpecommerce__Vendor_Ecommerce_Id',
  'logoscorpecommerce__Ecommerce_Brand_Id',
  'logoscorpecommerce__Inventory_Source'
]

const initialState = {
  isLoading: false,
  error: null,
  errorMessege: null,
  categories: [],
  productsCountByStatus: [],
  products: [],
  productVariations: [],
  productRelatedProducts: [],
  isLoadingProductRelatedProducts: false,
  productsInfo: {
    countAll: 0,
  },
  productsStocks: [],
  product: null,
  createProduct: null,
  massUploadProductsResults: null,
  massupdateStockResult: {},
  massupdatePriceResult: {},
  percentDownload: 0,
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
    totalItems: 0,
  },
}

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false
    },

    // START LOADING
    startLoadingRelatedProducts(state) {
      state.isLoadingProductRelatedProducts = true
    },

    // END LOADING
    endLoadingRelatedProducts(state) {
      state.isLoadingProductRelatedProducts = false
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    // HAS ERROR WHIT MESSAGE
    hasErrorMessege(state, action) {
      state.isLoading = false
      state.errorMessege = action.payload
    },

    // deleteProductSuccess(state, action) {
    //   state.isLoading = false
    //   state.products = state.products.filter((product) => product.id !== action.payload)
    // },

    // SET MASS UPLOAD PRODUCTS
    massUploadProductsSuccess(state, action) {
      state.massUploadProductsResults = action.payload
      state.isLoading = false
    },

    // SET MASS UPLOAD PRODUCTS
    massUpdateStokProductsSuccess(state, action) {
      state.massupdateStockResult = action.payload
      state.isLoading = false
    },

    // CLEAR MASS UPDATE STOCK
    massUpdateStokProductsClear(state, action) {
      state.massupdateStockResult = {}
      state.isLoading = false
      state.error = null
      state.errorMessege = null
    },
    // SET MASS UPLOAD PRODUCTS
    massUpdatePriceProductsSuccess(state, action) {
      state.massupdatePriceResult = action.payload
      state.isLoading = false
    },

    // UPDATE PRODUCT
    updateProductSuccess(state, action) {
      state.isLoading = false
      state.products = state.products.map((product) => {
        if (product.id === action.payload.id) {
          return { ...product, ...action.payload }
        }
        return product
      })
    },

    // UPDATE MASS PRODUCTS
    updateMassProduct(state, action) {
      state.isLoading = false
      state.products = state.products.map((product) => {
        if (action.payload.productsIds.includes(product.id)) {
          return { ...product, ...action.payload.update }
        }
        return product
      })
    },
    // SAVE PRODUCT
    createProductSuccess(state, action) {
      state.isLoading = false
      state.products = [...state.products, action.payload]
    },

    // SET CREATE PRODUCT
    setCreateProduct(state, action) {
      state.createProduct = action.payload
    },
    // GET PRODUCTS STOCK LOADING
    getProductsStockSuccess(state, action) {
      state.productsStocks = action.payload

      state.isLoading = false
    },
    // CLEAN PRODUCT STOCK
    cleanProductsStockSuccess(state) {
      state.productsStocks = []
    },
    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.products = action.payload.data
      state.productsInfo = action.payload.info
      state.isLoading = false
      state.product = null
    },

    // GET PRODUCTS COUNT BY STATUS
    getProductsCountByStatusSuccess(state, action) {
      state.productsCountByStatus = action.payload.data
      state.isLoading = false
    },

    // GET PRODUCTS VARIATIONS
    getProductVariationsSuccess(state, action) {
      state.productVariations = action.payload
    },

    // GET PRODUCTS Related Products
    getProductRelatedProductsSuccess(state, action) {
      state.productRelatedProducts = action.payload
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false
      state.product = action.payload
      state.products = []
    },

    // GET CATEGORIES
    getCategoriesSuccess(state, action) {
      state.isLoading = false
      state.categories = action.payload
    },

    // CLEAN PRODUCT
    cleanProduct(state, action) {
      state.isLoading = false
      state.product = null
      // state.createProduct = null
      state.productVariations = []
      state.productRelatedProducts = []
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload

      const totalItems = sum(cart.map((product) => product.quantity))
      const subtotal = sum(cart.map((product) => product.price * product.quantity))
      state.checkout.cart = cart
      state.checkout.discount = state.checkout.discount || 0
      state.checkout.shipping = state.checkout.shipping || 0
      state.checkout.billing = state.checkout.billing || null
      state.checkout.subtotal = subtotal
      state.checkout.total = subtotal - state.checkout.discount
      state.checkout.totalItems = totalItems
    },

    addToCart(state, action) {
      const newProduct = action.payload
      const isEmptyCart = !state.checkout.cart.length

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, newProduct]
      } else {
        state.checkout.cart = state.checkout.cart.map((product) => {
          const isExisted = product.id === newProduct.id

          if (isExisted) {
            return {
              ...product,
              colors: uniq([...product.colors, ...newProduct.colors]),
              quantity: product.quantity + 1,
            }
          }

          return product
        })
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, newProduct], 'id')
      state.checkout.totalItems = sum(state.checkout.cart.map((product) => product.quantity))
    },

    deleteCart(state, action) {
      const updateCart = state.checkout.cart.filter((product) => product.id !== action.payload)

      state.checkout.cart = updateCart
    },

    resetCart(state) {
      state.checkout.cart = []
      state.checkout.billing = null
      state.checkout.activeStep = 0
      state.checkout.total = 0
      state.checkout.subtotal = 0
      state.checkout.discount = 0
      state.checkout.shipping = 0
      state.checkout.totalItems = 0
    },

    backStep(state) {
      state.checkout.activeStep -= 1
    },

    nextStep(state) {
      state.checkout.activeStep += 1
    },

    gotoStep(state, action) {
      const step = action.payload
      state.checkout.activeStep = step
    },

    increaseQuantity(state, action) {
      const productId = action.payload

      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          }
        }
        return product
      })

      state.checkout.cart = updateCart
    },

    decreaseQuantity(state, action) {
      const productId = action.payload
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1,
          }
        }
        return product
      })

      state.checkout.cart = updateCart
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload
    },

    applyDiscount(state, action) {
      const discount = action.payload
      state.checkout.discount = discount
      state.checkout.total = state.checkout.subtotal - discount
    },

    applyShipping(state, action) {
      const shipping = action.payload
      state.checkout.shipping = shipping
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping
    },

    setPercentDownload(state, action) {
      state.percentDownload = action.payload
    },
  },
})

// Reducer
export default slice.reducer

// Actions
export const {
  cleanProduct,
  getCart,
  addToCart,
  resetCart,
  gotoStep,
  backStep,
  nextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  setCreateProduct,
} = slice.actions

// ----------------------------------------------------------------------

export function saveProduct(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      let result
      if (data.id) {
        result = await ProductService.update(data.id, data, true)
      } else {
        result = await ProductService.post(data, true)
      }
      if (
        result?.data?.code === 'SUCCESS' &&
        !Object.keys(result?.attributesError).length &&
        !result?.imagesErrors.length &&
        !result?.productVariantsErrors.length &&
        !Object.keys(result.relatedProductsError).length
      ) {
        if (result?.data?.message === 'record updated') {
          dispatch(slice.actions.updateProductSuccess(data))
        } else {
          dispatch(
            slice.actions.createProductSuccess({
              id: result.details?.id,
              ...data,
            })
          )
        }
      } else {
        throw Error('Error al guardar Productos')
      }
      return result.data
    } catch (error) {
      console.log('error', error)
      throw error
    }
  }
}

/**
 * Query for delete products
 * @param {String[]} productsIds - productsIds
 * @returns {Promise<any>}
 */
export function deleteProducts(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await ProductService.delete(data.productsIds)
      dispatch(slice.actions.endLoading())
      if (response?.error) {
        throw Error(response.error || 'Error al eliminar productos')
      }
      return response.data
    } catch (error) {
      throw Error(error.message || 'Error al eliminar productos')
    } finally {
      dispatch(slice.actions.endLoading())
    }
  }
}

export function getProductsCountByStatus(vendorId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await ProductService.countByFields('logoscorpecommerce__Approval_Status', [
        {
          field: 'logoscorpecommerce__Vendor_Ecommerce_Id',
          operation: '=',
          value: vendorId ? `${vendorId}` : vendorId,
        },
        {
          field: 'id',
          operation: 'is not',
          value: 'null',
        },
      ])
      dispatch(slice.actions.getProductsCountByStatusSuccess(response))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function getProductsStockByVendor(vendorId, pageSize = 400) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    dispatch(slice.actions.setPercentDownload(0))
    try {
      let itemsFetch = true
      let page = 1
      let items = []
      while (itemsFetch) {
        const { data, currentPage, totalCount } = await ProductService.getProductsStock(
          vendorId,
          page,
          pageSize
        )
        items = items.concat(data)
        itemsFetch = !(currentPage * pageSize >= totalCount)
        page += 1
        const percent = totalCount > 0 ? Number((items.length / totalCount) * 100) || 1 : 100
        dispatch(slice.actions.setPercentDownload(percent))
      }
      dispatch(slice.actions.getProductsStockSuccess(items))
    } catch (error) {
      console.log(error)
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function getProductsWithoutImages(
  page = 1,
  limit = 20,
  sort_order = 'desc',
  sort_by = 'id',
  filters = []
) {
  return async () => {
    const data = await ProductService.fetch(
      fieldsProduct.join(','),
      sort_by,
      sort_order,
      page,
      limit,
      filters
    )
    return data
  }
}

export function getProducts(
  page = 1,
  limit = 20,
  sort_order = 'desc',
  sort_by = 'id',
  filters = []
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const { data, info } = await ProductService.fetch(
        fieldsProduct.join(','),
        sort_by,
        sort_order,
        page,
        limit,
        filters
      )
      const productsWithImages = await Promise.all(
        data.map(async (product) => {
          let principalImage = ''
          if (product.logoscorpecommerce__Principal_Image_Path) {
            principalImage = await ProductService.s3ImageFromPath(
              product.logoscorpecommerce__Principal_Image_Path
            )
          }
          return { ...product, principalImage }
        })
      )
      dispatch(slice.actions.getProductsSuccess({ data: productsWithImages, info }))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function getProductRelatedProducts(
  page = 1,
  limit = 20,
  sort_order = 'desc',
  sort_by = 'id',
  filters = []
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingRelatedProducts())
    try {
      const relatedProducts2NF = await RelatedProductsService.fetch(
        ['Name', 'logoscorpecommerce__Product_Id0', 'logoscorpecommerce__Related_Product_id0'].join(
          ','
        ),
        sort_by,
        sort_order,
        page,
        limit,
        filters
      )
      // Get Related Products
      let relatedProducts = []
      if (relatedProducts2NF.data.length) {
        const products = await ProductService.fetch(fieldsProduct.join(','), 'id', 'desc', 1, 50, [
          {
            field: 'Product_Code',
            operation: 'in',
            value: relatedProducts2NF.data.length
              ? `"${relatedProducts2NF.data
                  .map((p) => p.logoscorpecommerce__Related_Product_id0)
                  .join('","')}"`
              : undefined,
          },
        ])
        relatedProducts = await relatedProducts2NF.data.map(async (related) => {
          const product = products.data.find(
            (p) => p.Product_Code === related.logoscorpecommerce__Related_Product_id0
          )
          if (product) {
            // const photo = await ProductService.getPhoto(product.id)
            // const attachments = await ProductService.getAttachments(product.id)
            // product.images = JSON.stringify(photo) !== '{}' ? [photo, ...attachments] : attachments
            let principalImage = ''
            if (product.logoscorpecommerce__Principal_Image_Path) {
              principalImage = await ProductService.s3ImageFromPath(
                product.logoscorpecommerce__Principal_Image_Path
              )
            }
            product.principalImage = principalImage
          }
          return {
            ...related,
            product: product || {},
          }
        })
      }
      relatedProducts = await Promise.all(relatedProducts)
      dispatch(slice.actions.getProductRelatedProductsSuccess(relatedProducts))
    } catch (error) {
      console.log('error', error)
      dispatch(slice.actions.hasError(error))
    } finally {
      dispatch(slice.actions.endLoadingRelatedProducts())
    }
  }
}

export function getProductVariations(
  page = 1,
  limit = 20,
  sort_order = 'desc',
  sort_by = 'id',
  filters = []
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const productsVariations = await ProductService.fetch(
        fieldsProduct.join(','),
        sort_by,
        sort_order,
        page,
        limit,
        filters
      )
      // Get Product Variations Attributes
      let productsAttributes = []
      if (productsVariations.data) {
        productsAttributes = await ProductAttributesService.fetch(
          fieldsProductAttributes.join(','),
          'id',
          'desc',
          1,
          2000,
          [
            {
              field: 'logoscorpecommerce__Product_code',
              operation: 'in',
              value: productsVariations.data.length
                ? `(${productsVariations.data.map((p) => `"${p.Product_Code}"`).join(',')})`
                : undefined,
            },
          ]
        )
      }
      const variationsWithAttrs = productsVariations.data.map((variation) => {
        const filterAttrs = productsAttributes.data.filter(
          (attr) => attr.logoscorpecommerce__Product_code === variation.Product_Code
        )
        return {
          ...variation,
          ...filterAttrs?.reduce(
            (a, v) => ({
              ...a,
              [v.logoscorpecommerce__Attribute_code]: {
                active: true,
                label: v.logoscorpecommerce__Attribute_label,
                option: v.logoscorpecommerce__Value,
              },
            }),
            {}
          ),
        }
      })
      const productsWithImages = await Promise.all(
        variationsWithAttrs.map(async (product) => {
          let images = []
          if (product.logoscorpecommerce__Principal_Image_Path) {
            const preview = await ProductService.s3ImageFromPath(
              product.logoscorpecommerce__Principal_Image_Path
            )
            images = [
              {
                isOld: true,
                isNew: false,
                preview,
                principal: true,
                isAttachment: false,
                isFeaturedImage: false,
              },
            ]
          }
          return { ...product, images }
        })
      )
      dispatch(slice.actions.getProductVariationsSuccess(productsWithImages))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

// ----------------------------------------------------------------------

export function massUpdateStockProducts(file, ecommerce_vendor_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('ecommerce_vendor_id', ecommerce_vendor_id)
      const response = await ProductService.updateStock(formData)
      dispatch(slice.actions.massUpdateStokProductsSuccess(response))
    } catch (error) {
      console.log(error)
      throw error
    } finally {
      dispatch(slice.actions.endLoading())
    }
  }
}

export function massUpdatePriceProducts(file, ecommerce_vendor_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('ecommerce_vendor_id', ecommerce_vendor_id)
      const response = await ProductService.updatePrice(formData)
      dispatch(slice.actions.massUpdatePriceProductsSuccess(response))
    } catch (error) {
      console.log(error)
      throw error
    } finally {
      dispatch(slice.actions.endLoading())
    }
  }
}

export function clearPriceAndStockErrors() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.hasErrorMessege(null))
      dispatch(slice.actions.hasError(null))
    } catch (error) {
      dispatch(
        slice.actions.hasErrorMessege(
          error?.error?.message
            ? error.error.message
            : 'Ha ocurrido un error, por favor intenta mas tarde.'
        )
      )
    }
  }
}

// ----------------------------------------------------------------------
export function massUploadProducts(values, file, Vendor_Email) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const newObj = {
        images: values.images ? values.images.map((item) => item.file) : [],
        file,
        Vendor_Email,
        typeLoad: values.typeLoad,
        ...(values.brand_Ecommerce_Id || values.typeLoad === 'brand'
          ? { brand_Ecommerce_Id: values.brand_Ecommerce_Id }
          : {}),
      }
      const response = await ProductMassUploadService.post(newObj, true)
      dispatch(slice.actions.massUploadProductsSuccess(response))
    } catch (error) {
      const FinalError = error?.message
        ? error.message
        : 'Ha ocurrido un error en el servidor, por favor intenta mas tarde.'

      dispatch(slice.actions.hasErrorMessege(FinalError))
    }
  }
}

// ----------------------------------------------------------------------
export function getProduct(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    const filters = [
      {
        field: 'id',
        operation: '=',
        value: id,
      },
    ]
    try {
      const response = await ProductService.fetch(
        fieldsProduct.join(','),
        undefined,
        undefined,
        undefined,
        undefined,
        filters
      )
      if (!response.data.length) {
        throw response.info.error
      }

      const images = await ProductService.getImages(response.data[0].Product_Code.toUpperCase())
      // const photo = await ProductService.getPhoto(id)
      // const attachments = await ProductService.getAttachments(id)
      let ResponseStock = null
      if (
        response.data[0].logoscorpecommerce__Approval_Status === 'Aprobado' &&
        response.data[0].Product_Code
      ) {
        ResponseStock = await MagentoProductService.getProductbySkuMagento(
          response.data[0].Product_Code
        )
      }
      if (
        ResponseStock?.quantity !== null &&
        ResponseStock?.quantity !== undefined &&
        ResponseStock?.quantity !== false &&
        ResponseStock?.quantity !== ''
      ) {
        response.data[0].Qty_in_Stock = ResponseStock?.quantity
      }
      dispatch(
        slice.actions.getProductSuccess({
          ...response.data[0],
          images:
            images && images.length
              ? images
                  .map((image) => ({
                    ...image,
                    isOld: true,
                    principal: image.logoscorpecommerce__Principal,
                    isAttachment: !image.logoscorpecommerce__Principal,
                    isFeaturedImage: image.logoscorpecommerce__Featured,
                  }))
                  .sort((x, y) => Number(y.principal) - Number(x.principal))
              : [],
          // images: [
          //   photo && photo.id
          //     ? {
          //         isOld: true,
          //         isAttachment: false,
          //         image: photo.image,
          //         id: photo.id,
          //       }
          //     : undefined,
          //   ...(attachments && attachments.length ? attachments : []).map((at) => ({
          //     isOld: true,
          //     isAttachment: true,
          //     isFeaturedImage: response.data[0].logoscorpecommerce__Featured_Image_Id === at.id,
          //     image: at.image,
          //     id: at.id,
          //   })),
          // ].filter(Boolean),
        })
      )
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error))
      throw error
    }
  }
}

// ----------------------------------------------------------------------
export function getProductStockMagento(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    const filters = [
      {
        field: 'id',
        operation: '=',
        value: id,
      },
    ]
    try {
      const response = await ProductService.fetch(
        fieldsProduct.join(','),
        undefined,
        undefined,
        undefined,
        undefined,
        filters
      )
      if (!response.data.length) {
        throw response.info.error
      }
      const images = await ProductService.getImages(response.data[0].Product_Code.toUpperCase())
      // const photo = await ProductService.getPhoto(id)
      // const attachments = await ProductService.getAttachments(id)
      dispatch(
        slice.actions.getProductSuccess({
          ...response.data[0],
          images:
            images && images.length
              ? images
                  .map((image) => ({
                    ...image,
                    isOld: true,
                    principal: image.logoscorpecommerce__Principal,
                    isAttachment: !image.logoscorpecommerce__Principal,
                    isFeaturedImage: image.logoscorpecommerce__Featured,
                  }))
                  .sort((x, y) => Number(y.principal) - Number(x.principal))
              : [],
          // images: [
          //   photo && photo.id
          //     ? {
          //         isOld: true,
          //         isAttachment: false,
          //         image: photo.image,
          //         id: photo.id,
          //       }
          //     : undefined,
          //   ...(attachments && attachments.length ? attachments : []).map((at) => ({
          //     isOld: true,
          //     isAttachment: true,
          //     isFeaturedImage: response.data[0].logoscorpecommerce__Featured_Image_Id === at.id,
          //     image: at.image,
          //     id: at.id,
          //   })),
          // ].filter(Boolean),
        })
      )
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error))
      throw error
    }
  }
}

export function getCategories() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await CategoryService.fetch()
      dispatch(slice.actions.getCategoriesSuccess(response.data))
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function approveRejectAIndividual(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const path = '/mass-approve-reject'
      const response = await ProductService.massiveUpdate(data, path)
      if (response?.error) {
        throw Error(response.error || `Error al actualizar los productos`)
      }
      if (!response.errors.length && response.responses[0].response.code === 'SUCCESS') {
        const id = data.productsIds[0]
        const { logoscorpecommerce__Approval_Status } = data
        dispatch(slice.actions.updateProductSuccess({ id, logoscorpecommerce__Approval_Status }))
      } else {
        dispatch(slice.actions.endLoading())
        throw Error(response?.errors[0]?.message || 'Error al cambiar el estatus')
      }
      dispatch(slice.actions.endLoading())
    } catch (error) {
      console.log('error', error)
      dispatch(slice.actions.hasError(error))
      throw Array.isArray(error) ? error[0].msg : error || 'Ha ocurrido un error'
    }
  }
}

/**
 * Function to Approve or Reject Products
 * @param {{productApprove: { 'Pendiente' | 'Aprobado' | 'No Aprobado' }, productsIds : []}} data
 * @returns
 */
export function actionApproveRejectMassive(data) {
  return async (dispatch) => {
    try {
      const path = '/mass-approve-reject'
      const response = await ProductService.massiveUpdate(data, path)
      if (response?.error) {
        throw Error(response.error || `Error al actualizar los productos`)
      }
      return response
    } catch (error) {
      // dispatch(slice.actions.hasError(error))
      throw Error(error.message || `Error al actualizar los productos`)
    }
  }
}

/**
 * Function to Active or Inactive Product Individual
 * @param {{productApprove: { 'Pendiente' | 'Aprobado' | 'No Aprobado' }, productsIds : []}} data
 * @returns
 */
export function activeInactiveProduct(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const path = '/mass-status-active-inactive'
      const response = await ProductService.massiveUpdate(data, path)
      if (response?.error) {
        throw Error(response.error || 'Error al cambiar el estatus')
      }
      if (!response.errors.length && response.responses[0].code === 'SUCCESS') {
        const id = data.productsIds[0]
        const { Product_Active } = data
        dispatch(slice.actions.updateProductSuccess({ id, Product_Active }))
      } else {
        dispatch(slice.actions.endLoading())
        throw Error(response?.errors[0]?.message || 'Error al cambiar el estatus')
      }
      return response
    } catch (error) {
      // dispatch(slice.actions.hasError(error))
      throw Error(error.message || `Error al actualizar los productos`)
    } finally {
      dispatch(slice.actions.endLoading())
    }
  }
}

/**
 * Function to Active or Inactive Product
 * @param {{productApprove: { 'Pendiente' | 'Aprobado' | 'No Aprobado' }, productsIds : []}} data
 * @returns
 */
export function actionMassive(data) {
  return async (dispatch) => {
    try {
      const path = '/mass-status-active-inactive'
      const response = await ProductService.massiveUpdate(data, path)
      if (response?.error) {
        throw Error(response.error || `Error al actualizar los productos`)
      }
      return response
    } catch (error) {
      // dispatch(slice.actions.hasError(error))
      throw Error(error.message || `Error al actualizar los productos`)
    }
  }
}

/**
 * Update Stock and Proce Product
 * @param {{Qty_in_Stock : Number, Unit_Price : Number ,id: String }} data
 * @returns response
 */
export function updateStockPrice(data) {
  return async (dispatch) => {
    try {
      const response = await ProductService.updateIndividualStockPrice(data)

      if (response?.error) {
        throw Error(response.error || `Error al actualizar el producto`)
      }
      if (response?.code === 'SUCCESS')
        await dispatch(slice.actions.updateProductSuccess({ ...data }))
      return response
    } catch (error) {
      // dispatch(slice.actions.hasError(error))
      throw Error(error.message || `Error al actualizar los productos`)
    }
  }
}

/**
 * Function get Magento Stock and Price Product
 * @param {sku} Product Sku
 * @returns
 */
export function getMagentoStock(sku, sourceCode = 'sambilchacao') {
  return async (dispatch) => {
    try {
      const response = await MagentoProductService.getMagentoProductStockPrice(sku, sourceCode)
      // const store = await MagentoProductService.getStores()
      return { ...response, store: { id: 0 } }
    } catch (error) {
      throw Error(error.message || `Error al actualizar los productos`)
    }
  }
}

export function disableProductsByCategory({ brand: oldData, payload }) {
  return async (dispatch) => {
    try {
      let enableProducts = -1
      let responseDisable = null
      let disabledProducts = 0

      while (enableProducts !== 0) {
        const { data } = await MagentoProductService.disableProductsBySku(
          `S1-V${oldData.logoscorpecommerce__Vendor_Ecommerce_Id}-${payload.logoscorpecommerce__Incremental}`
        )

        if (data && data?.success) {
          enableProducts = data.enabled_products
          disabledProducts = data.disabled_products
          responseDisable = data.success
        } else {
          throw new Error(data || 'Error al desactivar los productos')
        }
      }

      if (enableProducts === 0 && responseDisable === true) {
        const response = await BrandService.editBrand(payload, true)
      }

      return { responseDisable, enableProducts, disabledProducts }
    } catch (error) {
      console.error(error)
      throw Error(error.message || `Error al desactivar los productos`)
    }
  }
}

/**
 * Function delete Products in Magento
 * @param {vendorEcommerceId} vendorEcommerceId
 * @param quantity Quantity
 * @returns
 */
export function deleteMagentoProducts(vendorEcommerceId, quantity = 10) {
  return async (dispatch) => {
    try {
      const response = await ProductService.deleteMagentoProducts(vendorEcommerceId, quantity)
      return response
    } catch (error) {
      throw Error(error.message || `Error al actualizar los productos`)
    }
  }
}
