import { createSlice } from '@reduxjs/toolkit'
import InvoiceService from '../../services/invoiceService'

// ----------------------------------------------------------------------

export const fieldsInvoice = [
  'Discount',
  'Tax',
  'Due_Date',
  'Subject',
  'Currency',
  'Customer_No',
  'Contact_Name',
  'Account_Name',
  'Deal_Name',
  'Shipping_Country',
  'Shipping_State',
  'Shipping_City',
  'Shipping_Street',
  'Billing_Country',
  'Billing_State',
  'Billing_City',
  'Billing_Street',
  'Description',
  'Status',
  'Sub_Total',
  'Exchange_Rate',
  'Grand_Total',
  'Created_Time',
]

const initialState = {
  isLoading: false,
  isLoadinReport: false,
  error: null,
  invoices: [],
  invoicesFilter: [],
  countAllFilter: null,
  invoicesInfo: {
    countAll: 0,
  },
  paymentMethod: [],
  sendMethod: [],
  status: [],
  invoice: null,
  isLoadingChangeOrderStatus: false,
  statusOrders: {},
  statusOrdersArray: [],
}

const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },
    // START LOADING REPORT
    startLoadingReport(state) {
      state.isLoadinReport = true
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false
    },
    // END LOADING REPORT
    endLoadingreport(state) {
      state.isLoadinReport = false
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false
      state.invoices = action.payload.data || []
      state.invoicesInfo = action.payload.info
    },

    // FILTERINVOICES
    updateInvoicePayment(state, action) {
      const items = action.payload.items.length ? action.payload.items : state.invoices
      state.isLoading = false
      state.invoicesFilter = items.filter(
        (invoice) => invoice.payment.additional_information[0] === action.payload.value
      )
      state.countAllFilter = items.length
    },

    // GET INVOICE
    getInvoiceSuccess(state, action) {
      state.isLoading = false
      state.invoice = action.payload
    },

    // CLEAN INVOICE
    cleanInvoice(state, action) {
      state.isLoading = false
      state.invoice = null
    },

    // GET INVOICE
    invoiceStatus(state, action) {
      state.isLoading = false
      state.statusOrders = action.payload.orderStatus
      state.statusOrdersArray = action.payload.statusOrdersArray
    },

    // SET LOADING CHANGING STATUS VENDOR ORDER
    setLoadingChangingVendorOrderStatus(state, action) {
      state.isLoadingChangeOrderStatus = action.payload
    },
    // CHANGE VENDOR ORDER STATUS SUCCESS
    changeVendorOrderStatusSuccess(state, action) {
      if (state.invoice) {
        const { vendorsOrderStatus } = state.invoice
        const { orderId, vendorId, status } = action.payload
        const found = vendorsOrderStatus.findIndex(
          (e) =>
            e.logoscorpecommerce__Ecommerce_Order_id === orderId &&
            e.logoscorpecommerce__Ecommerce_vendor_id === vendorId
        )
        if (found !== -1) {
          vendorsOrderStatus[found].logoscorpecommerce__Order_status = status
          state.invoice = { ...state.invoice, vendorsOrderStatus }
        } else {
          vendorsOrderStatus.push({
            logoscorpecommerce__Ecommerce_Order_id: orderId,
            logoscorpecommerce__Ecommerce_vendor_id: vendorId,
            logoscorpecommerce__Order_status: status,
          })
        }
      }
    },
  },
})

// Reducer
export default slice.reducer

// Actions
// export const { getCart } = slice.actions

// ----------------------------------------------------------------------

export function getInvoicesTotalsByDate(startDate, endDate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await InvoiceService.countByFields('Grand_Total,Created_Time', [
        {
          field: 'Status',
          operation: '=',
          value: 'Delivered',
        },
        {
          field: 'Created_Time',
          operation: 'between',
          value:
            startDate && endDate
              ? `'${new Date(startDate).toISOString().split('.')[0]}-00:00' and '${
                  new Date(endDate).toISOString().split('.')[0]
                }-00:00'`
              : undefined,
        },
      ])
      // dispatch(slice.actions.getProductsCountByStatusSuccess(response))
      return response.data
    } catch (error) {
      dispatch(slice.actions.hasError(error))
      return []
    }
  }
}

export function getInvoices({
  page = 1,
  limit = 5,
  sort_order = 'desc',
  sort_by = 'id',
  filters,
} = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const result = await InvoiceService.fetchMagento({
        page,
        limit,
        sort_order,
        sort_by,
        filters,
      })
      if (!result.items) {
        throw Error(`No hay órdenes para mostrar`)
      }

      await dispatch(
        slice.actions.getInvoicesSuccess({
          data: result.items,
          more_records: result.total_count,
          info: { more_records: result?.total_count },
        })
      )
    } catch (error) {
      dispatch(slice.actions.hasError(error))
      throw Error(`Error al obtener las órdenes`)
    }
  }
}

export function getInvoicesFilter({
  sort_order = 'desc',
  sort_by = 'id',
  filters,
  extraField = null,
}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      let response = {}
      if (filters.length) {
        response = await InvoiceService.fetchMagentoNopaginate({
          sort_order,
          sort_by,
          filters,
        })
        if (!response.items) {
          throw Error(`Error al obtener las órdenes`)
        }
        await dispatch(
          slice.actions.getInvoicesSuccessFilter({
            data: response.items,
            paymentMethod: [],
            sendMethod: [],
            status: [],
            more_records: response.total_count,
            info: { more_records: response.total_count },
            ...extraField,
          })
        )
      } else if (extraField) {
        // Filter Field Not allow by Magento Payment
        dispatch(slice.actions.updateInvoicePayment({ ...extraField, items: response.items ?? [] }))
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error))
      throw Error(`Error al obtener las órdenes`)
    }
  }
}
export function getInvoicesReport({
  page = 1,
  limit = 5,
  sort_order = 'desc',
  sort_by = 'id',
  filters,
} = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingReport())
    const type = 'report'
    try {
      const result = await InvoiceService.fetchMagento({
        page,
        limit,
        sort_order,
        sort_by,
        filters,
        type,
      })
      if (!result.items) {
        throw Error(`No hay órdenes para mostrar`)
      }
      return {
        data: result.items,
        total: result.total_count,
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error))
      throw Error(`Error al obtener las órdenes`)
    } finally {
      dispatch(slice.actions.endLoadingreport())
    }
  }
}

export function getInvoicesByVendor({
  page = 1,
  limit = 20,
  sort_order = 'desc',
  sort_by = 'id',
  fields,
  filters,
  vendorId,
}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await InvoiceService.fetchMagentoByVendor({
        page,
        limit,
        sort_order,
        sort_by,
        fields,
        filters,
        vendorId,
      })
      if (!response.items) {
        throw Error(`Error al obtener las órdenes`)
      }
      dispatch(
        slice.actions.getInvoicesSuccess({
          data: response.items,
          more_records: response.total_count,
          info: { more_records: response.total_count },
        })
      )
    } catch (error) {
      dispatch(slice.actions.hasError(error))
      throw Error(`Error al obtener las órdenes`)
    }
  }
}

// ----------------------------------------------------------------------

export function getInvoice(id, vendorId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await InvoiceService.fetchByIdMagento({ id, vendorId })
      if (response.data) {
        dispatch(slice.actions.getInvoiceSuccess(response.data))
      } else {
        throw new Error('Resource not found.')
      }
    } catch (error) {
      console.error(error)
      throw Error(error?.message || 'Error al obtener el detalle la orden')
    } finally {
      dispatch(slice.actions.endLoading())
    }
  }
}

export function cleanInvoice() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.cleanInvoice())
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function changeVendorOrderStatus(orderId, vendorId, status) {
  return async (dispatch) => {
    dispatch(slice.actions.setLoadingChangingVendorOrderStatus(true))
    try {
      const response = await InvoiceService.updateOtherResource(
        '',
        {
          ecommerceOrderId: orderId,
          ecommerceVendorId: vendorId,
          orderStatus: status,
        },
        'changeVendorOrderStatus'
      )
      dispatch(slice.actions.setLoadingChangingVendorOrderStatus(false))
      dispatch(slice.actions.changeVendorOrderStatusSuccess({ orderId, vendorId, status }))
      return response
    } catch (error) {
      dispatch(slice.actions.setLoadingChangingVendorOrderStatus(false))
      throw error
    }
  }
}

export function getOrderStatus() {
  return async (dispatch) => {
    // dispatch(slice.actions.setLoadingChangingVendorOrderStatus(true))
    try {
      const response = await InvoiceService.getOrderStatus()
      const outputArray = []

      for (const key in response.data) {
        // eslint-disable-next-line no-prototype-builtins
        if (response.data.hasOwnProperty(key)) {
          outputArray.push({ label: response.data[key], value: key })
        }
      }

      dispatch(
        slice.actions.invoiceStatus({
          orderStatus: response.data,
          statusOrdersArray: outputArray,
        })
      )
      return response
    } catch (error) {
      dispatch(slice.actions.setLoadingChangingVendorOrderStatus(false))
      throw error
    }
  }
}
