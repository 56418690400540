import { createSlice } from '@reduxjs/toolkit'
import ConfigurationsService from '../../services/configurationsService'

// ----------------------------------------------------------------------


const initialState = {
  LoadingBannerPrimario: false,
  LoadingBannerSecundario: false,
  LoadingTerms: false,
  error: null,
  BannersSecundario: [],
  BannersPrimario: [],
  terms: " ",

}

const slice = createSlice({
  name: 'configurations',
  initialState,
  reducers: {
    // START LOADING
    startLoadingBannerPrimario(state) {
      state.LoadingBannerPrimario = true
    },

    startLoadingBannerSecundario(state) {
      state.LoadingBannerSecundario = true
    },

    startLoadingTerms(state) {
      state.LoadingTerms = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET CONTACT
    getBannersSecundarioSuccess(state, action) {
      state.LoadingBannerSecundario = false
      state.BannersSecundario = action.payload
    },

    // GET CONTACT
    getBannersPrimarioSuccess(state, action) {
      state.LoadingBannerPrimario = false
      state.BannersPrimario = action.payload
    },

    getTermsSuccess(state, action) {
      state.LoadingTerms = false
      state.terms = action.payload
    },

  },
})

// Reducer
export default slice.reducer

// Actions
// export const { getCart } = slice.actions

// ----------------------------------------------------------------------

export function getBannersPrimario() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingBannerPrimario())
    const FinalArray=[]
    try {
      const response1 = await ConfigurationsService.get("dashboard_principal_banner")
      if(Array.isArray(response1)){
        FinalArray.push(...response1.map(object => object?.logoscorpecommerce__Value ? object?.logoscorpecommerce__Value : ""))
      } else {
        FinalArray.push(response1?.logoscorpecommerce__Value ? response1?.logoscorpecommerce__Value : "")
      }
      console.log("response1")
      
      console.log(response1)
    dispatch(slice.actions.getBannersPrimarioSuccess(FinalArray))

    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function getBannersSecundario() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingBannerSecundario())
    const FinalArray=[]
    try {
      const response2 = await ConfigurationsService.get("dashboard_secondary_banner")
      if(Array.isArray(response2)){
        FinalArray.push(...response2.map(object => object?.logoscorpecommerce__Value ? object?.logoscorpecommerce__Value : ""
        ))
      } else {
        FinalArray.push(response2?.logoscorpecommerce__Value ? response2?.logoscorpecommerce__Value : "")
      }
      console.log("response2")
      
      console.log(response2)
    dispatch(slice.actions.getBannersSecundarioSuccess(FinalArray))

    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function getCancelAccountTerms() {

  return async (dispatch) => {
    dispatch(slice.actions.startLoadingTerms())
    try {
      const response = await ConfigurationsService.getTermAndConditions()
      console.log('poruebaasdadas')
      console.log("response hkdjahsjk",response)
      dispatch(slice.actions.getTermsSuccess(response.logoscorpecommerce__Value))
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error))
    }
  }

}
