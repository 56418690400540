import { createSlice } from '@reduxjs/toolkit'
import BrandService from '../../services/brandService'

// ----------------------------------------------------------------------

export const fieldsRequestBrand = [
  'Name',
  'logoscorpecommerce__Website',
  'logoscorpecommerce__Other_Details',
  'logoscorpecommerce__Meta_Title',
  'logoscorpecommerce__Meta_Keyword',
  'logoscorpecommerce__Meta_Description',
  'logoscorpecommerce__Description',
  'logoscorpecommerce__Status',
  'logoscorpecommerce__Letter_Representation_Path_S3',
  'logoscorpecommerce__Price_List_Path_S3',
  'logoscorpecommerce__Logo_Image_Path_S3',
  'logoscorpecommerce__Banner_Image_Path_S3',
  'logoscorpecommerce__Brand_Ecommerce_Id',
  'logoscorpecommerce__Ecommerce_Brand_Category_Id',
  'logoscorpecommerce__Is_New_Brand',
  'logoscorpecommerce__Vendor_Ecommerce_Id',
  'logoscorpecommerce__Vendor_Name',
  'logoscorpecommerce__Brand_Old_Name',
]
export const fieldsBrand = [
  'Name',
  'logoscorpecommerce__Vendor_Name',
  'logoscorpecommerce__Incremental',
  'logoscorpecommerce__Logo_Image_Path_S3',
  'logoscorpecommerce__Banner_Image_Path_S3',
  'logoscorpecommerce__Ecommerce_Id',
  'logoscorpecommerce__Vendor_Ecommerce_Id',
  'logoscorpecommerce__enabled',
  'logoscorpecommerce__Ecommerce_Brand_Category_Id',
  'logoscorpecommerce__Meta_Title0',
  'logoscorpecommerce__Meta_Keyword0',
  'logoscorpecommerce__Meta_Description0',
]

const initialState = {
  isLoading: false,
  isSaving: false,
  error: null,
  brand: null,
  brands: [],
  brandsInfo: {
    countAll: 0,
  },
  brandError: null,
  isBrandLoading: false,
  brandsCategories: [],
  // REQUEST
  requestBrand: null,
  requestsBrands: [],
  isRequestLoading: false,
  requestInfo: {
    countAll: 0,
  },
  brandRequestsCountByStatus: [],
  brandCountByVisibility: [],
}

const slice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET BRANDS
    getBrandsSuccess(state, action) {
      state.brands = action.payload.data
      state.brandsInfo = action.payload.info
      state.isLoading = false
      state.brand = null
    },

    // GET BRAND
    getBrandSuccess(state, action) {
      state.isLoading = false
      state.brand = action.payload
    },

    // ONSAVEBRAND
    onSaveBrand(state, action) {
      state.isSaving = false
      state.brand = { ...state.brand, ...action.payload }
    },

    // ONSAVEBRAND
    onSaveEditBrand(state, action) {
      state.isSaving = false
      state.brands = state.brands.map((brand) => {
        if (brand.id === action.payload.id) {
          return {
            ...brand,
            ...action.payload,
          }
        }
        return brand
      })
    },

    // START SAVING
    startSaving(state) {
      state.isSaving = true
    },

    // ENDSAVING SAVING
    endSaving(state) {
      state.isSaving = false
    },

    // START REQUEST BRAND LOADING
    startRequestLoading(state) {
      state.isRequestLoading = true
    },

    // END REQUEST BRAND LOADING
    endRequestLoading(state) {
      state.isRequestLoading = false
    },

    // GET CATEGORIES
    getCategoriesSuccess(state, action) {
      state.isLoading = false
      state.brandsCategories = action.payload.options
    },

    // UPDATE BRANDS REQUEST
    updateBrandRequest(state, action) {
      state.isLoading = false
      state.requestsBrands = state.requestsBrands.map((request) => {
        if (request.id === action.payload.id) {
          return { ...request, ...action.payload }
        }
        return request
      })
    },

    // UPDATE BRAND
    updateBrandSuccess(state, action) {
      state.isLoading = false
      state.brands = state.brands.map((brand) => {
        if (
          brand.logoscorpecommerce__Ecommerce_Id === action.payload.logoscorpecommerce__Ecommerce_Id
        ) {
          return {
            ...brand,
            logoscorpecommerce__enabled: action.payload.logoscorpecommerce__enabled,
          }
        }
        return brand
      })
    },

    // GET REQUESTS BRANDS
    getBrandsRequestsSuccess(state, action) {
      state.requestsBrands = action.payload.data
      state.requestInfo = action.payload.info
      state.isLoading = false
      state.requestBrand = null
    },

    // GET PRODUCTS COUNT BY STATUS
    getBrandRequestCountByStatusSuccess(state, action) {
      state.brandRequestsCountByStatus = action.payload
      state.isLoading = false
    },
    // GET BRANDS COUNT
    getBrandCountSuccess(state, action) {
      state.brandCountByVisibility = action.payload
      state.isLoading = false
    },

    // GET REQUEST BRAND
    getBrandRequestSuccess(state, action) {
      state.isLoading = false
      state.requestBrand = action.payload
    },
    // GET REQUEST BRAND
    cleanBrandRequest(state, action) {
      state.isLoading = false
      state.requestBrand = null
    },
  },

  // UPDATE MASS REQUESTS BRANDS
  updateMassProduct(state, action) {
    state.isLoading = false
    state.products = state.products.map((product) => {
      if (action.payload.productsIds.includes(product.id)) {
        return { ...product, ...action.payload.update }
      }
      return product
    })
  },
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------
export function saveBrand(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startSaving())
    try {
      const response = await BrandService.post(data, true)
      dispatch(slice.actions.onSaveBrand(response))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
      throw new Error(error.message)
    }
  }
}

export function getBrands(
  page = 1,
  limit = 20,
  sort_order = 'desc',
  sort_by = 'id',
  filters = [],
  headers = {}
) {
  // eslint-disable-next-line consistent-return
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const { data, info } = await BrandService.fetchBrands(
        [...fieldsBrand].join(','),
        sort_by,
        sort_order,
        page,
        limit,
        filters,
        headers
      )
      const brandsWithImages = await Promise.all(
        data.map(async (brand) => {
          let principalImage = ''
          if (brand.logoscorpecommerce__Logo_Image_Path_S3) {
            principalImage = await BrandService.s3ImageFromPath(
              brand.logoscorpecommerce__Logo_Image_Path_S3
            )
          }
          return { ...brand, principalImage }
        })
      )
      dispatch(slice.actions.getBrandsSuccess({ data: brandsWithImages, info }))
      return data // TO CHECK BRAND IN SELECTOR
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function getBrand(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await BrandService.getBrand(id, {
        fields: [...fieldsBrand].join(','),
      })

      if (response.data && response.data.length) {
        const { data } = response

        const attachmentPaths = [
          data[0].logoscorpecommerce__Logo_Image_Path_S3,
          data[0].logoscorpecommerce__Banner_Image_Path_S3,
          data[0].logoscorpecommerce__Letter_Representation_Image_Path_S3,
          data[0].logoscorpecommerce__Price_List_Image_Path_S3,
        ]

        const attachment = []

        for (const path of attachmentPaths) {
          if (path) {
            const image = await BrandService.s3ImageFromPath(path)
            attachment.push(image)
          }
        }

        const files = [attachment[2], attachment[3]]
        const filesAttachment = []

        for (const file of files) {
          if (file) {
            const fileInfo = BrandService.getFileExtensionAndName(file)
            filesAttachment.push({ url: file, ext: fileInfo.ext, Name: fileInfo.name })
          }
        }

        dispatch(
          slice.actions.getBrandSuccess({
            ...response.data[0],

            logoscorpecommerce__Logo: attachment[0],
            logoscorpecommerce__Banner: attachment[1],

            documents: filesAttachment.length ? filesAttachment : [],

            logoscorpecommerce__Ecommerce_Brand_Category_Id: response.data[0]
              .logoscorpecommerce__Ecommerce_Brand_Category_Id
              ? response.data[0].logoscorpecommerce__Ecommerce_Brand_Category_Id.split(',')
              : [],
          })
        )
      } else {
        throw response?.info?.error
      }
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error))
      throw error
    } finally {
      dispatch(slice.actions.endLoading())
    }
  }
}

export function approveRejectBrand(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startSaving())
    try {
      console.log('updating vendor', data)
      await BrandService.updateOtherResource(data.id, data, 'approveReject')
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error))
      throw new Error(error.error?.message || 'Ha ocurrido un error, por favor intenta mas tarde.')
    } finally {
      dispatch(slice.actions.endSaving())
    }
  }
}

export function getBrandCategories() {
  return async (dispatch) => {
    // dispatch(slice.actions.startRequestLoading())
    try {
      const { options } = await BrandService.getBrandCatgories()
      if (options) dispatch(slice.actions.getCategoriesSuccess({ options }))
    } catch (error) {
      console.error(error)
    }
  }
}

export function getBrandRequestCount(vendorId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const { data } = await BrandService.getBrandRequestsCount(vendorId)
      dispatch(slice.actions.getBrandRequestCountByStatusSuccess(data))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function cancelBrandRequest(brandsRequestsId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await BrandService.cancelBrandRequest(brandsRequestsId)
      if (!response?.error && response[0].code === 'SUCCESS') {
        dispatch(
          slice.actions.updateBrandRequest({
            id: brandsRequestsId,
            logoscorpecommerce__Status: 'Cancelada',
          })
        )
      } else {
        dispatch(slice.actions.endLoading())
        throw Error(response?.errors[0]?.message || `Error al actualizar las solicitudes de marcas`)
      }

      dispatch(slice.actions.endLoading())
    } catch (error) {
      console.error('error', error)
      dispatch(slice.actions.hasError(error))
      throw Array.isArray(error) ? error[0].msg : error || 'Ha ocurrido un error'
    }
  }
}

export function saveBrandRequest(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startSaving())
    try {
      const response = await BrandService.saveBrandRequest(data, true)
      if (
        !response?.error &&
        !response.errors &&
        response &&
        response[0] &&
        response[0]?.code === 'SUCCESS'
      ) {
        dispatch(slice.actions.onSaveBrand(response))
      } else {
        throw Error(
          (response?.errors && response?.errors[0]?.msg) ||
            `Error al guardar las solicitud de la marca`
        )
      }
    } catch (error) {
      console.log(error)
      throw new Error(
        error?.message || error?.errors[0] || 'Error al guardar la solicitud de la marca'
      )
    } finally {
      dispatch(slice.actions.endLoading())
    }
  }
}

export function editBrandRequest(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startSaving())
    try {
      const response = await BrandService.editBrandRequest(data, true)
      if (!response?.error && response[0].code === 'SUCCESS' && !response.errors) {
        await dispatch(slice.actions.onSaveEditBrand(data))
      } else {
        throw Error(
          (response?.errors && response?.errors[0]?.msg) ||
            `Error al guardar las solicitud de la marca`
        )
      }
    } catch (error) {
      throw new Error(
        error?.message || error?.errors[0] || 'Error al guardar la solicitud de la marca'
      )
    } finally {
      dispatch(slice.actions.endLoading())
    }
  }
}

export function getBrandsRequests(
  page = 1,
  limit = 20,
  sort_order = 'desc',
  sort_by = 'id',
  filters = [],
  headers = {}
) {
  // eslint-disable-next-line consistent-return
  return async (dispatch) => {
    dispatch(slice.actions.startRequestLoading())
    try {
      const { data, info } = await BrandService.fetchBrandsRequests(
        [...fieldsRequestBrand].join(','),
        sort_by,
        sort_order,
        page,
        limit,
        [
          {
            field: 'id',
            operation: 'is not',
            value: 'null',
          },
          ...filters,
        ],
        headers,
        {}
      )
      const brandsWithImages = await Promise.all(
        data.map(async (brand) => {
          let principalImage = ''
          if (brand.logoscorpecommerce__Logo_Image_Path_S3) {
            principalImage = await BrandService.s3ImageFromPath(
              brand.logoscorpecommerce__Logo_Image_Path_S3
            )
          }
          return { ...brand, principalImage }
        })
      )
      dispatch(slice.actions.getBrandsRequestsSuccess({ data: brandsWithImages, info }))
      return data // TO CHECK BRAND IN SELECTOR
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error))
    } finally {
      dispatch(slice.actions.endRequestLoading())
    }
  }
}

export function getBrandRequest(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startRequestLoading())
    try {
      const response = await BrandService.getBrandRequest(id, {
        fields: [...fieldsRequestBrand].join(','),
      })

      if (response.data && response.data.length) {
        const { data } = response

        const attachmentPaths = [
          data[0].logoscorpecommerce__Logo_Image_Path_S3,
          data[0].logoscorpecommerce__Banner_Image_Path_S3,
          data[0].logoscorpecommerce__Letter_Representation_Path_S3,
          data[0].logoscorpecommerce__Price_List_Path_S3,
        ]

        const attachments = []

        for (const path of attachmentPaths) {
          if (path) {
            const item = await BrandService.s3ImageFromPath(path)
            attachments.push(item)
          }
        }

        const filesAttachment = []

        for (const file of attachments) {
          if (file) {
            const fileInfo = BrandService.getFileExtensionAndName(file)

            filesAttachment.push({ url: file, ext: fileInfo.ext, Name: fileInfo.name })
          }
        }

        const payload = { ...response.data[0] }

        dispatch(
          slice.actions.getBrandRequestSuccess({
            ...payload,
            logoscorpecommerce__Logo: attachments[0],
            logoscorpecommerce__Banner: attachments[1],

            logoscorpecommerce__Letter_Representation: filesAttachment?.find((file) =>
              file?.url?.includes(payload?.logoscorpecommerce__Letter_Representation_Path_S3)
            ),

            logoscorpecommerce__Price_List: filesAttachment?.find((file) =>
              file?.url?.includes(payload?.logoscorpecommerce__Price_List_Path_S3)
            ),

            documents: filesAttachment.length ? filesAttachment : [],

            logoscorpecommerce__Ecommerce_Brand_Category_Id: response.data[0]
              .logoscorpecommerce__Ecommerce_Brand_Category_Id
              ? response.data[0].logoscorpecommerce__Ecommerce_Brand_Category_Id.split(',')
              : [],
          })
        )
      } else {
        throw response?.info?.error
      }
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error))
      throw error
    } finally {
      dispatch(slice.actions.endRequestLoading())
    }
  }
}

export function approveRejectAIndividualBrandRequest(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const { brandsRequestsIds, logoscorpecommerce__Status } = data
      const path = '/requests/approveReject'
      const response = await BrandService.massiveUpdateBrandsRequests(data, path)
      if (
        !response.error &&
        !response.errors.length &&
        response?.responses[0]?.response?.code === 'SUCCESS'
      ) {
        dispatch(
          slice.actions.updateBrandRequest({
            id: brandsRequestsIds[0],
            logoscorpecommerce__Status,
          })
        )
      } else {
        dispatch(slice.actions.endLoading())
        throw Error(response?.errors[0]?.message || `Error al actualizar las solicitudes de marcas`)
      }

      dispatch(slice.actions.endLoading())
    } catch (error) {
      console.error('error', error)
      dispatch(slice.actions.hasError(error))
      throw Array.isArray(error) ? error[0].msg : error || 'Ha ocurrido un error'
    }
  }
}

/**
 * Function to Approve or Reject Products
 * @param {{productApprove: { 'Pendiente' | 'Aprobado' | 'No Aprobado' }, productsIds : []}} data
 * @returns
 */
export function actionApproveRejectMassiveRequestBrands(data) {
  return async (dispatch) => {
    try {
      const path = '/requests/approveReject'
      const response = await BrandService.massiveUpdateBrandsRequests(data, path)
      if (response?.error) {
        throw Error(response.error || `Error al actualizar las solicitudes de marcas`)
      }
      return response
    } catch (error) {
      throw Error(error.message || `Error al actualizar las solicitudes de marcas`)
    }
  }
}
export function enableDisabledMassiveBrands(data) {
  return async (dispatch) => {
    try {
      const path = '/enableDisable'
      const response = await BrandService.massiveUpdateBrandsRequests(data, path)
      if (response?.error) {
        throw Error(response.error || `Error al actualizar las solicitudes de marcas`)
      }
      return response
    } catch (error) {
      throw Error(error.message || `Error al actualizar las solicitudes de marcas`)
    }
  }
}

export function enableDisabledBrands(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const { brandsIds, enabled } = data
      const path = '/enableDisable'
      const response = await BrandService.massiveUpdateBrandsRequests(data, path)
      if (response?.error) {
        throw Error(response?.error?.message || `Error al habilitar las marcas`)
      }
      await dispatch(
        slice.actions.updateBrandSuccess({
          logoscorpecommerce__Ecommerce_Id: brandsIds[0],
          logoscorpecommerce__enabled: enabled,
        })
      )
      dispatch(slice.actions.endLoading())
    } catch ({ error }) {
      dispatch(slice.actions.hasError(error))
      throw Error(error || 'Error al cambiar visivilidad')
    }
  }
}

export function deleteBrand(brandId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await BrandService.delete(brandId)
      dispatch(slice.actions.endLoading())
      if (response?.error) {
        throw Error(response.error.error || 'Error al eliminar marca')
      }
      return response.data
    } catch (error) {
      throw Error(error || 'Error al eliminar marca')
    } finally {
      dispatch(slice.actions.endLoading())
    }
  }
}

export function editBrand(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startSaving())
    try {
      let response
      if (data.id) {
        response = await BrandService.editBrand(data, true)
      } else {
        response = await BrandService.saveBrand(data, true)
      }
      if (!response?.error && response[0].code === 'SUCCESS')
        await dispatch(slice.actions.onSaveEditBrand(data))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
      throw Error('Ha ocurrido un error, por favor intenta mas tarde.')
    }
  }
}

export function getBrandCount() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const { data } = await BrandService.getBrandCount()
      dispatch(slice.actions.getBrandCountSuccess(data))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function cleanBrandRequest() {
  return async (dispatch) => {
    dispatch(slice.actions.cleanBrandRequest())
  }
}
