import { Navigate, useRoutes, Outlet } from 'react-router-dom'
// auth
import AuthGuard from '../auth/AuthGuard'
import AuthGuardAdmin from '../auth/AuthGuardAdmin'
import GuestGuard from '../auth/GuestGuard'
import DisabledModulesBasedGuard from '../auth/DisabledModulesBasedGuard'
import DisabledModulesAprovedVendorGuard from '../auth/DisabledModulesAprovedVendorGuard'
// layouts
import MainLayout from '../layouts/main'
import SimpleLayout from '../layouts/simple'
import CompactLayout from '../layouts/compact'
import BackgroundLayout from '../layouts/background'
import DashboardLayout from '../layouts/dashboard'
import DashboardLayoutAdmin from '../layouts/dashboard-admin'

// config
import { PATH_AFTER_LOGIN, PATH_AFTER_LOGIN_ADMIN } from '../config-global'
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  GeneralAppPage,
  // GeneralFilePage,
  // GeneralBankingPage,
  // GeneralBookingPage,
  // GeneralEcommercePage,
  // GeneralAnalyticsPage,
  // Dashboard: User
  UserListPage,
  UserEditPage,
  // UserCardsPage,
  UserCreatePage,
  UserProfilePage,
  UserAccountPage,
  // Dashboard: Ecommerce
  // EcommerceShopPage,
  // EcommerceCheckoutPage,
  // ************* PRODUCTS *************
  AddRelatedProductsPage,
  ProductListPage,
  ProductDetailsPage,
  ProductCreatePage,
  VariationCreatePage,
  // EcommerceProductEditPage,
  // EcommerceProductDetailsPage,
  // ************* MASSIVE LOAD *************
  GeneralMassiveLoadPage,
  MassiveLoadCreatePage,
  MassiveLoadImportPage,
  MassiveLoadListPage,
  MassiveLoadDataPage,
  MassiveLoadHistoryPage,
  MassiveLoadHistoryDetailsPage,
  // EcommerceProductDetailsPage,
  // ************* INVOICE *************
  InvoiceListPage,
  InvoiceDetailsPage,
  // InvoiceCreatePage,
  // InvoiceEditPage,
  // ************* PRODUCT QUESTIONS *************
  ProductQuestionsListPage,
  ProductQuestionsDetailPage,
  // ************* BRANDS *************
  BrandsListPage,
  BrandsDetailPage,
  BrandsRequestPage,
  // ************* SUPPORT *************
  SupportListPage,
  SupportDetailPage,
  SupportCreatePage,
  // Dashboard: Blog
  // BlogPostsPage,
  // BlogPostPage,
  // BlogNewPostPage,
  // Dashboard: FileManager
  // FileManagerPage,
  // Dashboard: App
  // ChatPage,
  // MailPage,
  // CalendarPage,
  // KanbanPage,
  //
  BlankPage,
  // PermissionDeniedPage,
  //
  Page500,
  Page403,
  Page404,
  // HomePage,
  // FaqsPage,
  // AboutPage,
  // Contact,
  // PricingPage,
  // PaymentPage,
  // ComingSoonPage,
  // MaintenancePage,
  //
  // ComponentsOverviewPage,
  // FoundationColorsPage,
  // FoundationTypographyPage,
  // FoundationShadowsPage,
  // FoundationGridPage,
  // FoundationIconsPage,
  //
  // MUIAccordionPage,
  // MUIAlertPage,
  // MUIAutocompletePage,
  // MUIAvatarPage,
  // MUIBadgePage,
  // MUIBreadcrumbsPage,
  // MUIButtonsPage,
  // MUICheckboxPage,
  // MUIChipPage,
  // MUIDataGridPage,
  // MUIDialogPage,
  // MUIListPage,
  // MUIMenuPage,
  // MUIPaginationPage,
  // MUIPickersPage,
  // MUIPopoverPage,
  // MUIProgressPage,
  // MUIRadioButtonsPage,
  // MUIRatingPage,
  // MUISliderPage,
  // MUIStepperPage,
  // MUISwitchPage,
  // MUITablePage,
  // MUITabsPage,
  // MUITextFieldPage,
  // MUITimelinePage,
  // MUITooltipPage,
  // MUITransferListPage,
  // MUITreesViewPage,
  //
  // DemoAnimatePage,
  // DemoCarouselsPage,
  // DemoChartsPage,
  // DemoCopyToClipboardPage,
  // DemoEditorPage,
  // DemoFormValidationPage,
  // DemoImagePage,
  // DemoLabelPage,
  // DemoLightboxPage,
  // DemoMapPage,
  // DemoMegaMenuPage,
  // DemoMultiLanguagePage,
  // DemoNavigationBarPage,
  // DemoOrganizationalChartPage,
  // DemoScrollbarPage,
  // DemoSnackbarPage,
  // DemoTextMaxLinePage,
  // DemoUploadPage,
  // DemoMarkdownPage,
  /** ADMIN COMPONENTS  */
  ProductAdminListPage,
  InvoiceAdminListPage,
  BrandsAdminListPage,
  BrandsAdminRequestListPage,
  RequestBrandsPage,
  RequestBrandListPage,
  BrandAdminPage,
} from './elements'

// ZOHO WIDGETS
import ZWidgetAddAttribute from '../zohoWidgets/attributes/AddAttribute'
import ZWidgetDeleteAttribute from '../zohoWidgets/attributes/DeleteAttribute'
import ZWidgetAddAttributeSet from '../zohoWidgets/attributesSets/AddAttributeSet'
import ZWidgetDeleteAttributeSet from '../zohoWidgets/attributesSets/DeleteAttributeSet'
import ZWidgetAddCategory from '../zohoWidgets/categories/AddCategories'
import ZWidgetDeleteCategory from '../zohoWidgets/categories/DeleteCategories'
import ZWidgetApproveReject from '../zohoWidgets/vendor/ApproveReject'
import ZWidgetApproveRejectBrand from '../zohoWidgets/brand/ApproveReject'
import ZWidgetApproveRejectCancelAccountRequest from '../zohoWidgets/cancelAccountRequest/ApproveReject'
import ZWidgetWelcome from '../zohoWidgets/welcome/Welcome'
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <BackgroundLayout />,
          children: [{ path: 'reset-password', element: <ResetPasswordPage /> }],
        },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },
    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DisabledModulesBasedGuard>
            <DisabledModulesAprovedVendorGuard>
              <DashboardLayout />
            </DisabledModulesAprovedVendorGuard>
          </DisabledModulesBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralAppPage /> },
        { path: 'massive-load', element: <GeneralMassiveLoadPage /> },
        // { path: 'products', element: <GeneralEcommercePage /> },
        { path: 'analytics', element: <BlankPage /> },
        { path: 'banking', element: <BlankPage /> },
        { path: 'booking', element: <BlankPage /> },
        // { path: 'file', element: <GeneralFilePage /> },
        {
          path: 'products',
          children: [
            //     { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            //     { path: 'shop', element: <EcommerceShopPage /> },
            //     { path: 'product/:name', element: <EcommerceProductDetailsPage /> },
            { path: '', element: <Navigate to="/dashboard/products/list" /> },
            { path: 'list', element: <ProductListPage /> },
            { path: 'new/:step', element: <ProductCreatePage /> },
            { path: 'variation', element: <VariationCreatePage /> },
            // { path: ':id', element: <ProductDetailsPage /> },
            { path: 'addRelated', element: <AddRelatedProductsPage /> },
            { path: ':id/edit', element: <ProductCreatePage edit /> },
            //     { path: 'checkout', element: <EcommerceCheckoutPage /> },
          ],
        },
        {
          path: 'massive-load',
          children: [
            { path: 'import', element: <MassiveLoadImportPage /> },
            { path: 'new-template', element: <MassiveLoadCreatePage /> },
            { path: 'list', element: <MassiveLoadListPage /> },
            { path: 'load', element: <MassiveLoadDataPage /> },
            { path: 'history', element: <MassiveLoadHistoryPage /> },
            { path: 'history/:id', element: <MassiveLoadHistoryDetailsPage /> },
          ],
        },
        {
          path: 'product-questions',
          children: [
            { path: '', element: <Navigate to="/dashboard/product-questions/list" /> },
            { path: 'list', element: <ProductQuestionsListPage /> },
            { path: ':id', element: <ProductQuestionsDetailPage /> },
          ],
        },
        {
          path: 'brands',
          children: [
            { path: '', element: <Navigate to="/dashboard/brands/list" /> },
            { path: 'list', element: <RequestBrandListPage /> },
            { path: ':id', element: <BrandsDetailPage /> },
            { path: 'new', element: <BrandsDetailPage /> },
            { path: 'request', element: <RequestBrandListPage /> },
            { path: 'resquest/new', element: <RequestBrandsPage newRequest /> },
            { path: ':id/request', element: <RequestBrandsPage /> },
          ],
        },
        {
          path: 'support',
          children: [
            { path: '', element: <Navigate to="/dashboard/support/list" /> },
            { path: 'list', element: <SupportListPage /> },
            { path: ':id', element: <SupportDetailPage /> },
            { path: 'new', element: <SupportCreatePage /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfilePage /> },
            // { path: 'cards', element: <UserCardsPage /> },
            { path: 'list', element: <UserListPage /> },
            { path: 'new', element: <UserCreatePage /> },
            { path: ':name/edit', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceListPage /> },
            { path: ':id', element: <InvoiceDetailsPage /> },
            // { path: ':id/edit', element: <InvoiceEditPage /> },
            // { path: 'new', element: <InvoiceCreatePage /> },
          ],
        },
        // {
        //   path: 'blog',
        //   children: [
        //     { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
        //     { path: 'posts', element: <BlogPostsPage /> },
        //     { path: 'post/:title', element: <BlogPostPage /> },
        //     { path: 'new', element: <BlogNewPostPage /> },
        //   ],
        // },
        // { path: 'files-manager', element: <FileManagerPage /> },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            // { path: 'label/:customLabel', element: <MailPage /> },
            // { path: 'label/:customLabel/:mailId', element: <MailPage /> },
            // { path: ':systemLabel', element: <MailPage /> },
            // { path: ':systemLabel/:mailId', element: <MailPage /> },
          ],
        },
        // {
        //   path: 'chat',
        //   children: [
        //     { element: <ChatPage />, index: true },
        //     { path: 'new', element: <ChatPage /> },
        //     { path: ':conversationKey', element: <ChatPage /> },
        //   ],
        // },
        // { path: 'calendar', element: <CalendarPage /> },
        // { path: 'kanban', element: <KanbanPage /> },
        // { path: 'permission-denied', element: <PermissionDeniedPage /> },
        // { path: 'blank', element: <BlankPage /> },
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/auth/login" replace />, index: true },
        // { path: 'about-us', element: <AboutPage /> },
        // { path: 'contact-us', element: <Contact /> },
        // { path: 'faqs', element: <FaqsPage /> },
        // Demo Components
        // {
        //   path: 'components',
        //   children: [
        //     // { element: <ComponentsOverviewPage />, index: true },
        //     // {
        //     //   path: 'foundation',
        //     //   children: [
        //     //     { element: <Navigate to="/components/foundation/colors" replace />, index: true },
        //     //     { path: 'colors', element: <FoundationColorsPage /> },
        //     //     { path: 'typography', element: <FoundationTypographyPage /> },
        //     //     { path: 'shadows', element: <FoundationShadowsPage /> },
        //     //     { path: 'grid', element: <FoundationGridPage /> },
        //     //     { path: 'icons', element: <FoundationIconsPage /> },
        //     //   ],
        //     // },
        //     // {
        //     //   path: 'mui',
        //     //   children: [
        //     //     { element: <Navigate to="/components/mui/accordion" replace />, index: true },
        //     //     { path: 'accordion', element: <MUIAccordionPage /> },
        //     //     { path: 'alert', element: <MUIAlertPage /> },
        //     //     { path: 'autocomplete', element: <MUIAutocompletePage /> },
        //     //     { path: 'avatar', element: <MUIAvatarPage /> },
        //     //     { path: 'badge', element: <MUIBadgePage /> },
        //     //     { path: 'breadcrumbs', element: <MUIBreadcrumbsPage /> },
        //     //     { path: 'buttons', element: <MUIButtonsPage /> },
        //     //     { path: 'checkbox', element: <MUICheckboxPage /> },
        //     //     { path: 'chip', element: <MUIChipPage /> },
        //     //     { path: 'data-grid', element: <MUIDataGridPage /> },
        //     //     { path: 'dialog', element: <MUIDialogPage /> },
        //     //     { path: 'list', element: <MUIListPage /> },
        //     //     { path: 'menu', element: <MUIMenuPage /> },
        //     //     { path: 'pagination', element: <MUIPaginationPage /> },
        //     //     { path: 'pickers', element: <MUIPickersPage /> },
        //     //     { path: 'popover', element: <MUIPopoverPage /> },
        //     //     { path: 'progress', element: <MUIProgressPage /> },
        //     //     { path: 'radio-button', element: <MUIRadioButtonsPage /> },
        //     //     { path: 'rating', element: <MUIRatingPage /> },
        //     //     { path: 'slider', element: <MUISliderPage /> },
        //     //     { path: 'stepper', element: <MUIStepperPage /> },
        //     //     { path: 'switch', element: <MUISwitchPage /> },
        //     //     { path: 'table', element: <MUITablePage /> },
        //     //     { path: 'tabs', element: <MUITabsPage /> },
        //     //     { path: 'textfield', element: <MUITextFieldPage /> },
        //     //     { path: 'timeline', element: <MUITimelinePage /> },
        //     //     { path: 'tooltip', element: <MUITooltipPage /> },
        //     //     { path: 'transfer-list', element: <MUITransferListPage /> },
        //     //     { path: 'tree-view', element: <MUITreesViewPage /> },
        //     //   ],
        //     // },
        //     // {
        //     //   path: 'extra',
        //     //   children: [
        //     //     { element: <Navigate to="/components/extra/animate" replace />, index: true },
        //     //     { path: 'animate', element: <DemoAnimatePage /> },
        //     //     { path: 'carousel', element: <DemoCarouselsPage /> },
        //     //     { path: 'chart', element: <DemoChartsPage /> },
        //     //     { path: 'copy-to-clipboard', element: <DemoCopyToClipboardPage /> },
        //     //     { path: 'editor', element: <DemoEditorPage /> },
        //     //     { path: 'form-validation', element: <DemoFormValidationPage /> },
        //     //     { path: 'image', element: <DemoImagePage /> },
        //     //     { path: 'label', element: <DemoLabelPage /> },
        //     //     { path: 'lightbox', element: <DemoLightboxPage /> },
        //     //     { path: 'map', element: <DemoMapPage /> },
        //     //     { path: 'mega-menu', element: <DemoMegaMenuPage /> },
        //     //     { path: 'multi-language', element: <DemoMultiLanguagePage /> },
        //     //     { path: 'navigation-bar', element: <DemoNavigationBarPage /> },
        //     //     { path: 'organization-chart', element: <DemoOrganizationalChartPage /> },
        //     //     { path: 'scroll', element: <DemoScrollbarPage /> },
        //     //     { path: 'snackbar', element: <DemoSnackbarPage /> },
        //     //     { path: 'text-max-line', element: <DemoTextMaxLinePage /> },
        //     //     { path: 'upload', element: <DemoUploadPage /> },
        //     //     { path: 'markdown', element: <DemoMarkdownPage /> },
        //     //   ],
        //     // },
        //   ],
        // },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        // { path: 'pricing', element: <PricingPage /> },
        // { path: 'payment', element: <PaymentPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoonPage /> },
        // { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },

    // ***********************************   ZOHO WIDGETS ROUTES!!! **********************************************
    {
      path: 'zWidget',
      element: (
        <AuthGuard>
          <Outlet />
        </AuthGuard>
      ),
      children: [
        // ATTRIBUTES
        { path: 'attributes', element: <ZWidgetAddAttribute /> },
        { path: 'delete-attributes', element: <ZWidgetDeleteAttribute /> },
        // ATTRIBUTES SETS
        { path: 'attributes-sets', element: <ZWidgetAddAttributeSet /> },
        { path: 'delete-attributes-sets', element: <ZWidgetDeleteAttributeSet /> },
        // CATEGORIES
        { path: 'categories', element: <ZWidgetAddCategory /> },
        { path: 'delete-categories', element: <ZWidgetDeleteCategory /> },
        // VENDOR
        { path: 'approve-reject-vendor', element: <ZWidgetApproveReject /> },
        // Brand
        { path: 'approve-reject-brand', element: <ZWidgetApproveRejectBrand /> },
        // Cancel Account Request
        { path: 'approve-reject-cancel-account-request', element: <ZWidgetApproveRejectCancelAccountRequest /> },
        // Welcome
        { path: 'welcome', element: <ZWidgetWelcome /> },
      ],
    },
    {
      path: 'zAdmin',
      element: (
        <AuthGuardAdmin>
          <DisabledModulesBasedGuard>
            <DashboardLayoutAdmin />
          </DisabledModulesBasedGuard>
        </AuthGuardAdmin>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN_ADMIN} replace />, index: true },
        { path: 'app', element: <GeneralAppPage /> },
        { path: 'massive-load', element: <GeneralMassiveLoadPage /> },
        // PRODUCTS
        {
          path: 'products',
          children: [
            { path: '', element: <Navigate to="/zAdmin/products/list" /> },
            { path: 'list', element: <ProductAdminListPage /> },
            { path: ':id', element: <ProductDetailsPage /> },
            { path: 'new/:step', element: <ProductCreatePage /> },
            // For edit from zWidget
            { path: 'edit', element: <ProductCreatePage edit /> },
            { path: 'edit/:id', element: <ProductCreatePage edit /> },
            { path: 'variation', element: <VariationCreatePage /> },
            { path: 'addRelated', element: <AddRelatedProductsPage /> },
          ],
        },
        // ORDERS
        {
          path: 'orders',
          children: [
            { element: <Navigate to="/zAdmin/orders/list" replace />, index: true },
            { path: 'list', element: <InvoiceAdminListPage /> },
            { path: ':id', element: <InvoiceDetailsPage /> },
          ],
        },
        {
          path: 'product-questions',
          children: [
            { path: '', element: <Navigate to="/zAdmin/product-questions/list" /> },
            { path: 'list', element: <ProductQuestionsListPage /> },
            { path: ':id', element: <ProductQuestionsDetailPage /> },
          ],
        },
        // BRANDS
        {
          path: 'brands',
          children: [
            { element: <Navigate to="/zAdmin/brands/list" replace />, index: true },
            { path: 'list', element: <BrandsAdminListPage /> },
            { path: ':id', element: <BrandAdminPage /> },
            { path: ':id/edit', element: <BrandAdminPage edit /> },
            { path: 'new', element: <BrandAdminPage /> },
            { path: 'request', element: <BrandsAdminRequestListPage /> },
            { path: ':id/request', element: <RequestBrandsPage /> },
          ],
        },
        // MASSIVE LOAD
        {
          path: 'massive-load',
          children: [
            { path: 'import', element: <MassiveLoadImportPage /> },
            { path: 'new-template', element: <MassiveLoadCreatePage /> },
            { path: 'list', element: <MassiveLoadListPage /> },
            { path: 'load', element: <MassiveLoadDataPage /> },
            { path: 'history', element: <MassiveLoadHistoryPage /> },
            { path: 'history/:id', element: <MassiveLoadHistoryDetailsPage /> },
          ],
        },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ])
}
