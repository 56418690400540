import _mock from '../_mock';
import CoverCaballeros from '../../assets/categorias/Caballeros.png'
import CoverDamas from '../../assets/categorias/Damas.png'
import CoverNinos from '../../assets/categorias/Ninos.png'
import CoverSeguridad from '../../assets/categorias/Seguridad.png'
import CoverCaballeroDeporte from '../../assets/categorias/Zapato_caballero_deporte.jpg'
import Zapato1 from '../../assets/zapatos/zapato1.jpg'
import Zapato2 from '../../assets/zapatos/zapato2.jpg'
import Zapato3 from '../../assets/zapatos/zapato3.jpg'
import BrandRori from '../../assets/brands/rori.png'


// ----------------------------------------------------------------------

const COUNTRY = ['de', 'en', 'fr', 'kr', 'us'];

const CATEGORY = ['CAP', 'Branded Shoes', 'Headphone', 'Cell Phone', 'Earings'];

const PRODUCT_NAME = [
  'Small Granite Computer',
  'Small Rubber Mouse',
  'Awesome Rubber Hat',
  'Sleek Cotton Sausages',
  'Rustic Wooden Chicken',
];

export const  _ecommerceMostProducts = [...Array(5)].map((_, index) => ({
  label: ['Traje slim clasico', 'Camisa azul cuello redondo', 'Camisa roja slim', 'Camisa azul manga corta', 'Camisa blanca cuello redondo'][index],
  amount: _mock.number.price(index) * 100,
  value: _mock.number.percent(index),
  image: _mock.image.product(index),
  name: _mock.name.fullName(index),
}));

export const  _ecommerceSalesOverview = [...Array(5)].map((_, index) => ({
  label: ['Pantalón textura', 'Camisa manga larga slim estampada', 'Saco textura', 'Calcetines estampados negros', 'Camisa manga larga fantasía'][index],
  amount: _mock.number.price(index) * 100,
  value: _mock.number.percent(index),
}));

export const _ecommerceBestSalesman = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  email: _mock.email(index),
  avatar: _mock.image.avatar(index + 8),
  category: CATEGORY[index],
  flag: `/assets/icons/flags/ic_flag_${COUNTRY[index]}.svg`,
  total: _mock.number.price(index),
  rank: `Top ${index + 1}`,
}));

export const _ecommerceLatestProducts = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  name: PRODUCT_NAME[index],
  image: _mock.image.product(index),
  price: _mock.number.price(index),
  priceSale: index === 0 || index === 3 ? 0 : _mock.number.price(index),
  colors: (index === 0 && ['#2EC4B6', '#E71D36', '#FF9F1C', '#011627']) ||
    (index === 1 && ['#92140C', '#FFCF99']) ||
    (index === 2 && ['#0CECDD', '#FFF338', '#FF67E7', '#C400FF', '#52006A', '#046582']) ||
    (index === 3 && ['#845EC2', '#E4007C', '#2A1A5E']) || ['#090088'],
}));

export const _ecommerceNewProducts = [...Array(7)].map((_, index) => ({
  id: _mock.id(index),
  name: [
    'Nike Air Max 98',
    'Nike Zoom Gravity',
    'Nike DBreak-Type',
    'Kyrie Flytrap 3 EP Basketball Shoe',
    'Nike Air Max Fusion Men',
    'Reebok White Raptor Men',
    'Reebok Black Raptor Men'
  ][index],
  description: [
    'Nike Air Max 98',
    'Nike Zoom Gravity',
    'Nike DBreak-Type',
    'Kyrie Flytrap 3 EP Basketball Shoe',
    'Nike Air Max Fusion Men',
    'Reebok White Raptor Men',
    'Reebok Black Raptor Men'
  ][index],
  brand: [
    {name: 'Nike', cover: BrandRori },
    {name: 'Nike', cover: BrandRori },
    {name: 'Nike', cover: BrandRori },
    {name: 'Kyrie', cover: BrandRori },
    {name: 'Nike', cover: BrandRori },
    {name: 'Reebok', cover: BrandRori },
    {name: 'Reebok', cover: BrandRori },
  ][index],
  sku: [
    '456213345',
    '45641275683',
    '8989746546'
  ][ Math.floor( Math.random()*3) ],
  createdAt: _mock.time(index),
  productType: 'configurable_product',
  inventoryType: [
    'approved',
    'pending',
    'not_approved'
  ][ Math.floor( Math.random()*3) ],
  delivery: [
    'pick_up',
    'delivery',
  ],
  visibility: [
    'active',
    'inactive'
  ][_mock.boolean(index)+0],
  price: _mock.number.price(index),
  sizes: ['S', 'M', 'L'],
  colors: ['#336DFF', '#36393B', '#DAD2D5'],
  totalRating: Math.floor( Math.random()*5),
  images: [
    Zapato1,
    Zapato2,
    Zapato3,
    Zapato1,
    Zapato2,
    Zapato3,
    Zapato1
  ],
  cover: [
    Zapato1,
    Zapato2,
    Zapato3,
    Zapato1,
    Zapato2,
    Zapato3,
    Zapato1
  ][index],
}));

export const _ecommerceCategories = [...Array(4)].map((_, index) => ({
  id: _mock.id(index),
  name: [
    'Calzado Caballeros',
    'Calzado Dama',
    'Calzado Niño',
    'Calzado Seguridad',
    'Calzado Deportivo',
  ][index],
  parent:[
    'Calzado',
    'Calzado',
    'Calzado',
    'Equipamiento',
    'Calzado',
  ][index],
  route:[
    'Inicio > Moda',
    'Inicio > Moda',
    'Inicio > Moda',
    'Inicio > Seguridad',
    'Inicio > Deporte'
  ][index],
  cover: [
    CoverCaballeros,
    CoverDamas,
    CoverNinos,
    CoverSeguridad,
    CoverCaballeroDeporte
  ][index]
}));

