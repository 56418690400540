import numeral from 'numeral';

// ----------------------------------------------------------------------

numeral.register('locale', 'es', {
  delimiters: {
      thousands: '.',
      decimal: ','
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  },
  ordinal : (number) => number === 1 ? 'er' : 'ème',
  currency: {
      symbol: '$'
  }
});
numeral.locale('es');

export function fNumber(number) {
  return numeral(number).format();
}

export function fCurrency(number, currency = '$') {
  return number !== undefined ? numeral(number).format(`${currency} 0,0.00`) : '';
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

export function fPhoneNumber(number) {
  number = number.split("-").join("");
  number = number.replace(/[^0-9\\.]+/g, '');
  if (number.length > 0) {
    number = number.replace(/(\d{3})(\d{3})/, "$1-$2").slice(0,8);    
  }
  return number;
}

export function fIdDocument(number) {
  return numeral(number.slice(0,10)).format();
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}
