import PropTypes from 'prop-types'
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput, Typography } from '@mui/material'

CustomSelectNoForm.propTypes = {
  name: PropTypes.string,
  labelId: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  focused: PropTypes.bool,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  sx: PropTypes.object,
  menuItemStyles: PropTypes.object,
}

export default function CustomSelectNoForm({
  name,
  labelId,
  label,
  placeholder,
  value,
  onChange,
  options,
  size,
  fullWidth,
  required,
  disabled,
  focused,
  error,
  sx,
  menuItemStyles,
}) {
  return (
    <FormControl fullWidth focused>
      <InputLabel
        id={`input-label-${labelId}`}
        sx={{ '&.Mui-focused': { color: 'text.disabled' } }}
      >
        {label}
      </InputLabel>
      <Select
        labelId={`input-label-${labelId}`}
        id={`select-${labelId}`}
        name={name}
        size={size}
        fullWidth
        disabled={disabled}
        required={required}
        displayEmpty={!!placeholder}
        value={value}
        label={label}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{
          ...(focused &&
            !error && {
              '&.MuiOutlinedInput-root:not([aria-expanded="true"]) .MuiOutlinedInput-notchedOutline':
                {
                  borderColor: 'rgba(145, 158, 171, 0.32)',
                },
            }),
          ...(error && {
            '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              borderColor: '#FF5630',
            },
          }),
          ...sx,
        }}
        error={!!error}
      >
        {placeholder && (
          <MenuItem
            disabled
            value=""
            sx={{
              py: 1,
              px: 2,
              borderRadius: 0.75,
              typography: 'body2',
              ...menuItemStyles,
            }}
          >
            <Typography sx={{ color: 'text.primary' }}>{placeholder}</Typography>
          </MenuItem>
        )}

        {options?.map(
          (option) =>
            !option.notShow && (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            )
        )}
      </Select>
    </FormControl>
  )
}
