import React, { useContext, useState, useEffect, useCallback } from 'react'
import { Grid, CircularProgress, Typography, Button, Box, Alert, Container } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { ZOHO } from '../../lib/ZohoEmbededAppSDK'
import AttributesService from '../../services/attributesService'
import { useAuthContext } from '../../auth/useAuthContext'

const DeleteAttribute = () => {
  const { zWidgetData } = useAuthContext()

  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [attributes, setAttributes] = useState([])
  const [alertMessage, setAlertMessage] = useState({
    severity: '',
    message: '',
  })

  const getRecord = useCallback(async () => {
    const currentRecord = await ZOHO.CRM.API.getRecord({
      Entity: zWidgetData.Entity,
      RecordID: zWidgetData.EntityId[0],
    })
    setAttributes(currentRecord.data[0])
    setIsLoading(false)
  }, [zWidgetData])

  useEffect(() => {
    if (zWidgetData.EntityId.length === 1) {
      getRecord()
    } else {
      setAlertMessage({
        severity: 'error',
        message: 'No se pueden eliminar mas de un atributo a la vez',
      })
      setIsLoading(false)
    }
  }, [zWidgetData, getRecord])

  const handleSubmit = async () => {
    setIsSaving(true)
    try {
      const { status } = await AttributesService.delete(attributes.logoscorpecommerce__attribute_id)
      if (status === 'success') {
        setAlertMessage({ severity: 'success', message: 'Atributo eliminado correctamente' })
      }
    } catch (error) {
      console.log("error", error)
      const errorMessage = error.parameters && error.parameters[0] ? error?.message?.replace('%1', error?.parameters[0]) : error?.message || 'Error al Eliminar el Atributo'
      setAlertMessage({ severity: 'error', message: errorMessage  ||  'Ha ocurrido un error inesperado.'})
    } finally { 
      setIsSaving(false)
    }

  }

  if (isLoading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }
  if (alertMessage.message) {
    return (
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
        </Grid>
      </Grid>
    )
  }
  return (
    <Container
      fluid="true"
      maxWidth="100%"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '40px',
      }}
    >
      <Typography variant="h6">
        Seguro que quieres eliminar el atributo {attributes.Name}?
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', margin: '10px 0', width: '100%' }}>
        <LoadingButton
          variant="contained"
          size="small"
          sx={{ pl: 5, pr: 5 }}
          onClick={handleSubmit}
          loading={isSaving}
        >
          Aceptar
        </LoadingButton>
      </Box>
    </Container>
  )
}

export default DeleteAttribute
