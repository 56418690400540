import { createSlice } from '@reduxjs/toolkit'
import TaxService from '../../services/taxService'

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  taxes: [],
  taxesInfo: {
    countAll: 0,
  },
  tax: null,
}

const slice = createSlice({
  name: 'tax',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET INVOICES
    getTaxesSuccess(state, action) {
      state.isLoading = false
      state.taxes = action.payload.data
      state.taxesInfo = action.payload.info
    },

    // GET INVOICE
    getTaxSuccess(state, action) {
      state.isLoading = false
      state.tax = action.payload
    },
  },
})

// Reducer
export default slice.reducer

// Actions
// ----------------------------------------------------------------------

export function getTaxes() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await TaxService.fetch()
      const responseTaxes = response.data.filter(tax => tax.class_type === "PRODUCT")
      dispatch(slice.actions.getTaxesSuccess({data: responseTaxes, info: response.info}))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}
