import { createSlice } from '@reduxjs/toolkit'
import InventorySourcesService from '../../services/inventorySourcesService'
import PaymentMethodsService from '../../services/paymentMethodsService'
import DeliveryMethodsService from '../../services/deliveryMethodsService'


// ----------------------------------------------------------------------

const initialState = {
  isLoadingInventorySources: false,
  isLoadingPaymentMethods: false,
  isLoadingDeliveryMethods: false,
  error: null,
  stores: [],
  inventorySources: [],
  paymentMethods: [],
  deliveryMethods: [],
}

const slice = createSlice({
  name: 'tax',
  initialState,
  reducers: {
    // START LOADING
    startLoadingInventorySources(state) {
      state.isLoadingInventorySources = true
    },
    startLoadingPaymentMethods(state) {
      state.isLoadingPaymentMethods = true
    },
    startLoadingDeliveryMethods(state) {
      state.isLoadingDeliveryMethods = true
    },
    // GET INVENTORY SOURCES
    getInventorySourcesSuccess(state, action) {
      state.isLoadingInventorySources = false
      state.inventorySources = action.payload.items
    },
    // GET PAYMENT METHODS
    getPaymentMethodsSuccess(state, action) {
      state.isLoadingPaymentMethods = false
      state.paymentMethods = action.payload.options
    },
    // GET DELIVERY METHODS
    getDeliveryMethodsSuccess(state, action) {
      state.isLoadingDeliveryMethods = false
      state.deliveryMethods = action.payload.options
    },
  },
})

// Reducer
export default slice.reducer

// Actions
// ----------------------------------------------------------------------

export function getInventorySources() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingInventorySources())
    try {
      const response = await InventorySourcesService.fetch()
      dispatch(slice.actions.getInventorySourcesSuccess(response))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}
/* export function getDeliveryMethods() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingDeliveryMethods())
    try {
      const response = await DeliveryMethodsService.fetch()
      dispatch(slice.actions.getDeliveryMethodsSuccess(response))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
} */
/* export function getPaymentMethods() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingPaymentMethods())
    try {
      const response = await PaymentMethodsService.fetch()
      dispatch(slice.actions.getPaymentMethodsSuccess(response))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
} */
