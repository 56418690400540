import PropTypes from 'prop-types'
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react'
// utils
import axios from '../utils/axios'
import localStorageAvailable from '../utils/localStorageAvailable'
// Lib
import { ZOHO } from '../lib/ZohoEmbededAppSDK'
import { isValidToken, setSession } from './utils'
import VendorService from '../services/vendorService'
// routes
import { PATH_CATALYST } from '../routes/paths'
import { fieldsVendor, zohoAdminField, socialField, policyField } from '../redux/slices/vendor'

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  zWidgetData: null,
  adminMenuEnabled: false,
  disabledModules: '',
  welcomePage: null,
}

const fieldAllVendor = [...fieldsVendor, ...socialField, ...policyField]

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
      zWidgetData: action.payload.zWidgetData,
      adminMenuEnabled: action.payload.adminMenuEnabled,
      disabledModules: action.payload.disabledModules,
      welcomePage: action.payload.welcomePage,
    }
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    }
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    }
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    }
  }

  return state
}

// ----------------------------------------------------------------------

export const AuthContext = createContext(null)

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
}

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const storageAvailable = localStorageAvailable()

  const initialize = useCallback(async () => {
    try {
      const url = new URL(window.location.href.replace('#', ''))
      const appIsInDevEnv = JSON.parse(url.searchParams.get('dev') ?? 'false')
      axios.defaults.headers.common.dev = appIsInDevEnv
      const appIsIframe =
        !!window.location.ancestorOrigins?.length || window.location !== window.parent.location
      // console.log('appIsIframe', appIsIframe)
      if (appIsIframe) {
        // console.log("url.searchParams.get('adminMenu')", url.searchParams.get('adminMenu'));
        const adminMenuEnabled = JSON.parse(url.searchParams.get('adminMenu') ?? 'false')
        ZOHO.embeddedApp.on('PageLoad', async (zWidgetData) => {
          // console.log('zWidgetData', zWidgetData)
          const {
            users: [currentUser],
          } = await ZOHO.CRM.CONFIG.getCurrentUser()
          const {
            profiles: [profile],
          } = await ZOHO.CRM.API.getProfile({
            ID: currentUser.profile.id,
          })
          currentUser.profile = profile
          // console.log('ZOHO CRM USER ->', currentUser)

          const finalUser = {
            vendorImage: currentUser.image_link,
            zohoVendor: {
              Vendor_Name: currentUser.full_name,
            },
          }

          if (zWidgetData.Entity === 'Vendors' && zWidgetData.EntityId.length === 1) {
            // ADMIN VENDOR
           const vendorId = zWidgetData.EntityId[0]
            // const vendorId = '4934577000011012020'
            // Get vendor from crm
            const response = await VendorService.fetch(
              fieldAllVendor.concat(zohoAdminField).join(','),
              'id',
              'desc',
              undefined,
              undefined,
              [
                {
                  field: 'id',
                  operation: '=',
                  value: vendorId,
                },
              ]
            )
            const vendor = response.data[0]

            /** Get Vendor Avatar and Banner */
            let vendorImage = ''
            let vendorBanner = ''
            if (vendor.logoscorpecommerce__store_logo_pic) {
              vendorImage = await VendorService.s3ImageFromPath(
                vendor.logoscorpecommerce__store_logo_pic
              )
            }
            if (vendor.logoscorpecommerce__store_banner_pic) {
              vendorBanner = await VendorService.s3ImageFromPath(
                vendor.logoscorpecommerce__store_banner_pic
              )
            }

            finalUser.zohoVendor = vendor
            finalUser.vendorImage = vendorImage
            finalUser.vendorBanner = vendorBanner
          }
          const orgInfo = await ZOHO.CRM.CONFIG.getOrgInfo()
          dispatch({
            type: 'INITIAL',
            payload: {
              isAuthenticated: true,
              user: {
                ...currentUser,
                ...finalUser,
                appRol: 'ADMIN',
              },
              zWidgetData,
              adminMenuEnabled,
              disabledModules: (
                await ZOHO.CRM.API.getOrgVariable('logoscorpecommerce__disabled_modules_app')
              ).Success.Content,

              welcomePage: {
                CRMOrganizationID: orgInfo.org[0].zgid,
                CRMOrganizationName: orgInfo.org[0].company_name,

                adminProductsWebtabName: (
                  await ZOHO.CRM.API.getOrgVariable('logoscorpecommerce__adminProductsWebTab')
                ).Success.Content,

                adminOrdersWebtabName: (
                  await ZOHO.CRM.API.getOrgVariable('logoscorpecommerce__adminOrdersWebTab')
                ).Success.Content,
              },
            },
          })
        })
        ZOHO.embeddedApp.init()
      } else {
        // Get user from zoho
        const { content: zohoUser } = await window.catalyst.userManagement.getCurrentProjectUser()
        // Get vendor from crm
        const response = await VendorService.fetch(
          fieldAllVendor.concat(zohoAdminField).join(','),
          'id',
          'desc',
          undefined,
          undefined,
          [
            {
              field: 'Email',
              operation: '=',
              value: `'${zohoUser.email_id}'`,
            },
          ]
        )
        const vendor = response.data[0]

        /** Get Vendor Avatar and Banner */
        let vendorImage = ''
        let vendorBanner = ''
        if (vendor.logoscorpecommerce__store_logo_pic) {
          vendorImage = await VendorService.s3ImageFromPath(
            vendor.logoscorpecommerce__store_logo_pic
          )
        }
        if (vendor.logoscorpecommerce__store_banner_pic) {
          vendorBanner = await VendorService.s3ImageFromPath(
            vendor.logoscorpecommerce__store_banner_pic
          )
        }
        // let photo = {};
        // try {
        //   photo = await VendorService.getPhoto(vendor.data[0].id)
        // } catch (error) {
        //   console.log('error photo user', error);
        // }
        axios.defaults.headers.common['Vendor-Id'] = vendor.id
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user: { ...zohoUser, zohoVendor: vendor, vendorImage, vendorBanner, appRol: 'VENDOR' },
          },
        })
      }
    } catch (error) {
      console.log('GENERAL ERROR', error)
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      })
    }
    //   const accessToken = storageAvailable ? localStorage.getItem('accessToken') : ''

    //   if (accessToken && isValidToken(accessToken)) {
    //     setSession(accessToken)

    //     const response = await axios.get('/api/account/my-account')

    //     const { user } = response.data

    //     dispatch({
    //       type: 'INITIAL',
    //       payload: {
    //         isAuthenticated: true,
    //         user,
    //       },
    //     })
    //   } else {
    //     dispatch({
    //       type: 'INITIAL',
    //       payload: {
    //         isAuthenticated: false,
    //         user: null,
    //       },
    //     })
    //   }
    // } catch (error) {
    //   console.error(error)
    // dispatch({
    //   type: 'INITIAL',
    //   payload: {
    //     isAuthenticated: false,
    //     user: null,
    //   },
    // })
    // }
    // storageAvailable
  }, [])

  useEffect(() => {
    initialize()
  }, [initialize])

  // LOGIN
  const login = useCallback(async (email, password) => {
    const response = await axios.post('/api/account/login', {
      email,
      password,
    })
    const { accessToken, user } = response.data

    setSession(accessToken)

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    })
  }, [])

  // LOGIN ZOHO
  // const loginZoho = useCallback(async (user) => {
  //   const vendor = await VendorService.fetch(fieldsVendor.join(','), 'id', 'desc', undefined, undefined, [{
  //       field: 'Email',
  //       operation: '=',
  //       value: `'${user.email_id}'`,
  //   }])
  //   axios.defaults.headers.common['Vendor-Id'] = vendor.data[0].id;
  //   dispatch({
  //     type: 'LOGIN',
  //     payload: {
  //       user: {...user, zohoVendor: vendor.data[0]},
  //     },
  //   })
  // }, [])

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    })
    const { accessToken, user } = response.data

    localStorage.setItem('accessToken', accessToken)

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    })
  }, [])

  // LOGOUT
  const logout = useCallback(async () => {
    await window.catalyst.auth.signOut(PATH_CATALYST.login)
    axios.defaults.headers.common['Vendor-Id'] = undefined
    // setSession(null)
    // dispatch({
    //   type: 'LOGOUT',
    // })
  }, [])

  // UPDATEZOHOVENDOR
  const updateZohoVendor = useCallback(
    (updatedZohoVendor) => {
      dispatch({
        type: 'LOGIN',
        payload: {
          user: { ...state.user, zohoVendor: {...state.user.zohoVendor, ...updatedZohoVendor} },
        },
      })
    },
    [state.user]
  )

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      zWidgetData: state.zWidgetData,
      adminMenuEnabled: state.adminMenuEnabled,
      disabledModules: state.disabledModules,
      welcomePage: state.welcomePage,
      method: 'jwt',
      login,
      // loginZoho,
      register,
      logout,
      updateZohoVendor,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      state.zWidgetData,
      state.adminMenuEnabled,
      state.disabledModules,
      state.welcomePage,
      login,
      // loginZoho,
      logout,
      register,
      updateZohoVendor,
    ]
  )

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>
}
