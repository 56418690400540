import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'

import isEqual from 'lodash/isEqual'
import {
  Box,
  Chip,
  Grid,
  Typography,
  FormControl,
  Container,
  Button,
  Alert,
  Switch,
  TextField,
  InputLabel,
  FormControlLabel,
  CircularProgress,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import {
  ecommerceCreateAttribute,
  ecommerceEditAttributeLabel,
  ecommerceEditAttributeOptions,
} from './attribute.service'
import { getUniqueArrays } from '../recordService'
import { ZOHO } from '../../lib/ZohoEmbededAppSDK'
import { useLocales } from '../../locales'
import { CustomSelectNoForm } from '../../components/custom-select'
// import { UserContext } from '../../App'
import { useAuthContext } from '../../auth/useAuthContext'
import { getAttributeOptions } from '../../redux/slices/attributes'

const AddAttribute = () => {
  const form = {
    Name: '',
    logoscorpecommerce__attribute_code: '',
    logoscorpecommerce__attribute_id: '',
    logoscorpecommerce__backend_type: '',
    logoscorpecommerce__frontend_input: '',
    logoscorpecommerce__is_required: '',
    logoscorpecommerce__options: '',
    optionText: '',
  }

  const currentOptionsRef = useRef([])
  const isUpdate = useRef(false)

  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [alertMessage, setAlertMessage] = useState({
    severity: '',
    message: '',
  })
  const [options, setOptions] = useState([])
  const [inputs, setInputs] = useState(form)
  const [textFieldError, setTextFieldError] = useState(false)
  const [currentAttribute, setCurrentAttribute] = useState({})

  const { translate } = useLocales()
  const trans = (key) => translate(`zWidgetAttributes.${key}`)

  const { zWidgetData } = useAuthContext()

  const dispatch = useDispatch()

  useEffect(() => {
    console.log('inputs', inputs)
  }, [inputs])

  // const { zohoId, entity, isZohoLoaded, buttonPosition } = useContext(UserContext)

  useEffect(() => {
    console.log('zWidgetData', zWidgetData)
    if (zWidgetData) {
      if (
        zWidgetData.ButtonPosition !== undefined &&
        zWidgetData.ButtonPosition !== 'ListViewWithoutRecord'
      ) {
        isUpdate.current = true
        getCurrentRecord(zWidgetData.Entity, zWidgetData.EntityId[0])
      } else {
        setIsLoading(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zWidgetData])

  const ecommerceAttributeCreation = async () => {
    try {
      setIsSaving(true)
      const creationResult = await ecommerceCreateAttribute(inputs, options)
      console.log('creationResult', creationResult)
      setAlertMessage({ severity: 'success', message: creationResult.message })
      setIsSaving(false)
      window.scrollTo(0, 0)
    } catch (error) {
      if (error.message) {
        const errorMessage = error.parameters
          ? error.message.replace('%1', error.parameters[0])
          : error.message
        setAlertMessage({ severity: 'error', message: errorMessage })
      } else {
        setAlertMessage({ severity: 'error', message: 'Ha ocurrido un error inesperado.' })
      }
    }
    setIsSaving(false)
  }

  const ecommerceAttributeUpdate = async () => {
    setIsSaving(true)
    try {
      console.log('es un update')
      console.log('inputs', inputs)
      const [toDelete, toCreate] = getUniqueArrays(currentOptionsRef.current, options, 'value')
      const updateResult = await ecommerceEditAttributeLabel(inputs, toDelete, toCreate)
      setAlertMessage({ severity: 'success', message: updateResult?.message })

      if (!isEqual(currentOptionsRef.current, options)) {
        ecommerceEditAttributeOptions(inputs, currentOptionsRef.current, options)
      }
      window.scrollTo(0, 0)
    } catch (error) {
      if (error.message) {
        console.error('error', error)
        const errorMessage = error.parameters
          ? error.message.replace('%1', error.parameters[0])
          : error.message
        setAlertMessage({ severity: 'error', message: errorMessage })
      } else {
        setAlertMessage({ severity: 'error', message: 'Ha ocurrido un error inesperado.' })
      }
      // Returns options in front 
      if (currentAttribute.typeOfRecord === 'select' || currentAttribute.typeOfRecord === 'multiselect') {
        const currentOptions = await dispatch(
          getAttributeOptions(currentAttribute.logoscorpecommerce__attribute_code)
        )
        const formatedOptions = []
        currentOptions?.forEach((option) => {
          formatedOptions.push({
            label: option.Name,
            value: option.logoscorpecommerce__value,
            attributeCode: option.logoscorpecommerce__ecommerce_attribute_code,
            zohoId: option.id,
          })
        })
        currentOptionsRef.current = formatedOptions
        if (currentOptions.length) {
          setOptions(formatedOptions)
        }
      }
    } finally {
      setIsSaving(false)
    }
  }


  const getCurrentRecord = async (entity, zohoId) => {
    try {
      setIsLoading(true)
      console.log('GET CURRENT RECORD!!')
      const currentRecord = await ZOHO.CRM.API.getRecord({
        Entity: entity,
        RecordID: zohoId,
      })

      setCurrentAttribute({logoscorpecommerce__attribute_code: currentRecord.data[0].logoscorpecommerce__attribute_code, typeOfRecord: currentRecord.data[0].logoscorpecommerce__frontend_input });
      const typeOfRecord = currentRecord.data[0].logoscorpecommerce__frontend_input
      if (typeOfRecord === 'select' || typeOfRecord === 'multiselect') {
        const currentOptions = await dispatch(
          getAttributeOptions(currentRecord.data[0].logoscorpecommerce__attribute_code)
        )
        const formatedOptions = []
        currentOptions?.forEach((option) => {
          formatedOptions.push({
            label: option.Name,
            value: option.logoscorpecommerce__value,
            attributeCode: option.logoscorpecommerce__ecommerce_attribute_code,
            zohoId: option.id,
          })
        })
        currentOptionsRef.current = formatedOptions
        if (currentOptions.length) {
          setOptions(formatedOptions)
        }
      }
      setInputs({
        ...currentRecord.data[0],
        logoscorpecommerce__frontend_input:
          currentRecord.data[0].logoscorpecommerce__frontend_input,
      })
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      setAlertMessage({
        severity: 'error',
        message: 'No se pudieron obtener las opciones del atributo',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = (e) => {
    if (e.target !== undefined && e.target !== null) {
      if (e.target.name !== 'optionText' && e.target.name !== 'logoscorpecommerce__is_required')
        setOptions([])

      if (e.target.name === 'logoscorpecommerce__is_required') {
        setInputs((prevInputs) => ({
          ...prevInputs,
          [e.target.name]: Number(e.target.checked),
        }))

        return
      }

      if (e.target.name === 'Name' || e.target.name === 'optionText') {
        const value = e.target.value
        const newValue = value.replace(/[\u007C\u003D\u0023]/g, '')
        e.target.value = newValue
      }

      setInputs((prevInputs) => ({
        ...prevInputs,
        [e.target.name]: e.target.value,
      }))
    }
  }

  const handleOptionSubmit = (e) => {
    e.preventDefault()
    setTextFieldError(false)

    const isInArray = options.some((value) => value.label === inputs.optionText)
    if (!isInArray) {
      const renderOptions = [...options, { label: inputs.optionText }]
      setOptions(renderOptions)
      setInputs((prevInputs) => ({
        ...prevInputs,
        optionText: '',
      }))
      return
    }
    setTextFieldError(true)
  }

  const deleteItem = (label) => {
    setOptions((state) => state.filter((item) => item.label !== label))
  }

  const isValid = () => {
    let valid = true
    if ( inputs.Name !== '' && inputs.logoscorpecommerce__frontend_input !== '') {
      if ( inputs.logoscorpecommerce__frontend_input !== 'select' && inputs.logoscorpecommerce__frontend_input !== 'multiselect') {
        valid = false
      } else {
        // eslint-disable-next-line no-lonely-if
        if ( options.length > 0) {
          valid = false
        }
      }
    }
    return valid
  }

  if (isLoading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }
  return (
    <Container
      fluid="true"
      maxWidth="100%"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '40px',
      }}
    >
      {!!alertMessage.message && (
        <Grid container alignItems="center" sx={{ mb: 3 }}>
          <Grid item xs={12}>
            <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={4}>
        <Grid container item xs={6}>
          <FormControl fullWidth>
            <InputLabel shrink sx={{ backgroundColor: '#fff', padding: '3px 7px' }}>
              {trans('attributeNameLabel')}
              <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField name="Name" value={inputs.Name} required onChange={handleChange} disabled={isUpdate.current} helperText="Caracteres no permitidos # = |"/>            
          </FormControl>
        </Grid>

        <Grid container item xs={6}>
          <CustomSelectNoForm
            name="logoscorpecommerce__frontend_input"
            labelId="type-attr"
            label={trans('inputTypeLabel')}
            format={trans}
            required
            disabled={isUpdate.current}
            value={inputs.logoscorpecommerce__frontend_input || ''}
            defaultValue={inputs.logoscorpecommerce__frontend_input || ''}
            onChange={handleChange}
            placeholder={trans('typePlaceholder')}
            sx={{ width: '100%' }}
            options={[
              { label: trans('text'), value: 'text' },
              { label: trans('select'), value: 'select' },
              { label: trans('multiselect'), value: 'multiselect' },
              { label: trans('boolean'), value: 'boolean' },
              { label: trans('textarea'), value: 'textarea' },
            ]}
          />
        </Grid>

        {inputs.logoscorpecommerce__frontend_input === 'select' ||
        inputs.logoscorpecommerce__frontend_input === 'multiselect' ? (
          <Grid container item xs={12}>
            <Box sx={{ width: '100%' }}>
              <form onSubmit={handleOptionSubmit}>
                <FormControl fullWidth>
                  <InputLabel shrink sx={{ backgroundColor: '#fff', padding: '3px 7px' }}>
                    {trans('attributeOptionLabel')}
                  </InputLabel>
                  <TextField
                    error={textFieldError}
                    name="optionText"
                    value={inputs.optionText}
                    required
                    onChange={handleChange}
                    helperText={textFieldError && trans('duplicateAttributeError')}
                  />
                  <p style={{ color: 'gray', fontSize: 'small' }}>Para guardar necesitas agregar al menos una opcion</p>
                </FormControl>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button sx={{ mt: '1rem' }} type="submit" variant="outlined">
                    {trans('attributeOptionSubmit')}
                  </Button>
                </Box>
              </form>
            </Box>

            <Grid item container xs={12} sx={{ margin: '10px 0' }}>
              {options.map((option, index) => (
                <Grid key={index} item>
                  <Chip
                    label={option.label}
                    onDelete={() => deleteItem(option.label)}
                    sx={{ margin: '5px' }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : (
          <Grid container item xs={12} />
        )}

        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={6}>
            <FormControlLabel
              control={
                <Switch
                  onChange={handleChange}
                  checked={!!Number(inputs.logoscorpecommerce__is_required)}
                  name="logoscorpecommerce__is_required"
                />
              }
              label={
                <Typography component="p" variant="subtitle2">
                  {trans('attributeRequiredLabel')}
                </Typography>
              }
            />
          </Grid>
          <Grid container item xs={6} />
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0', width: '100%' }}>
        <LoadingButton
          variant="contained"
          size="small"
          sx={{ pl: 5, pr: 5 }}
          onClick={isUpdate.current ? ecommerceAttributeUpdate : ecommerceAttributeCreation}
          loading={isSaving}
          disabled={isValid()}
        >
          Guardar
        </LoadingButton>
      </Box>
    </Container>
  )
}

export default AddAttribute
