// routes
import { PATH_ADMIN } from '../../../routes/paths'
// components
import SvgColor from '../../../components/svg-color'

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`${process.env.PUBLIC_URL}/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
)

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  suport: icon('ic_suport'),
}

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'admin',
    items: [
      { title: 'dashboard', path: PATH_ADMIN.general.app, icon: ICONS.dashboard },
      {
        title: 'products',
        path: PATH_ADMIN.general.products,
        icon: ICONS.ecommerce,
        children: [{ title: 'products_list', path: PATH_ADMIN.products.list }],
      },
      {
        title: 'orders',
        path: PATH_ADMIN.general.orders,
        icon: ICONS.analytics,
        children: [{ title: 'history', path: PATH_ADMIN.invoice.list }],
      },
      {
        title: 'massive_load',
        path: PATH_ADMIN.general.massiveLoad,
        icon: ICONS.blank,
        children: [
          { title: 'history', path: PATH_ADMIN.massiveLoad.history },
          { title: 'import_template', path: PATH_ADMIN.massiveLoad.new },
          { title: 'import', path: PATH_ADMIN.massiveLoad.import },
        ],
      },
      {
        title: 'products_questions',
        path: PATH_ADMIN.general.productquestions,
        icon: ICONS.chat,
        children: [{ title: 'product_questions.list', path: PATH_ADMIN.productquestions.list }],
      },
      {
        title: 'brands',
        path: PATH_ADMIN.general.brands,
        icon: ICONS.label,
        children: [
          { title: 'list2', path: PATH_ADMIN.brands.list },
          { title: 'listRequest', path: PATH_ADMIN.brands.request },
        ],
      },
    ],
  },
]

export default navConfig
