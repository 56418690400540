import { createSlice } from '@reduxjs/toolkit'
import TicketsService from '../../services/ticketsService'

// ----------------------------------------------------------------------

export const fieldsTickets = [
    'ticketNumber',
    'category',
    'subCategory',    
    'subject',
    'createdTime',
    'email',
    'priority',
    'assigneeId',
    'status',
  ]

const initialState = {
    isLoading: false,
    isSaving: false,
    error: null,
    ticket: null,
    tickets: [],
}

const slice = createSlice({
    name: 'ticket',
    initialState,
    reducers:{
        // START LOADING
        startLoading(state) {
          state.isLoading = true
        },

        // HAS ERROR
        hasError(state, action) {
          state.isLoading = false
          state.error = action.payload
        },

        // GET TICKETS
        getTicketsSuccess(state, action) {            
          state.tickets = action.payload.data
          state.isLoading = false
          state.ticket = null
        },

        // GET TICKET
        getTicketSuccess(state, action) {            
          state.ticket = action.payload.data
          state.isLoading = false
        },

        // START SAVING
        startSaving(state) {
          state.isSaving = true
        },

       // ONSAVETICKET
       onSaveTicket(state, action) {
         state.isSaving = false
         state.ticket = {...state.ticket, ...action.payload} 
      },
    }
})

// Reducer
export default slice.reducer


export function getTickets(page = 1, limit = 20, sort_order = 'desc', sort_by = 'id', filters = [], headers = {}, other = {}) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await TicketsService.fetch(
                undefined,        
                sort_by,
                sort_order,
                page,
                limit,
                filters,
                headers,
                other,
              )        
            dispatch(slice.actions.getTicketsSuccess(response))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            throw new Error(error.message)
        }
    }
}

export function saveTicket(data) {
    return async (dispatch) => {
      dispatch(slice.actions.startSaving())
      try {        
        const response = await TicketsService.post(data)
        dispatch(slice.actions.onSaveTicket(response))
      } catch (error) {
        dispatch(slice.actions.hasError(error))
      }
    }
  }

  export function getTicket(id) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading())
      try {
        const response = await TicketsService.get(id)
          // , {fields: [...fieldsInvoice, 'Ordered_Items'].join(',')})
        if (response.data && response.data.length) {
          dispatch(slice.actions.getTicketSuccess(response.data[0]))
        } else {
          throw new Error('Resource not found.')
        }
      } catch (error) {
        console.error(error)
        dispatch(slice.actions.hasError(error))
      }
    }
  }