import { useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Stack, Typography, Link } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// routes
import { PATH_AUTH } from '../../routes/paths';
// layouts
import LoginLayout from '../../layouts/login';

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuthContext();

  useEffect(() => {
    window.catalyst.auth.signIn('login')
  })

  return (
    <LoginLayout>
      <Stack id="stackTest" alignItems="flex-start" justifyItems="center">
        <Stack alignItems="flex-start" sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            paddingLeft: '10px'
          },
          // mt: '200px',
          mb: '10px'
        })}>
          <Typography variant="h5"> Bienvenido al módulo de gestión </Typography>
          <Typography variant="h5"> de vendores de Sambil Online </Typography>
        </Stack>
        <div id="login" style={{ height: '200px', width: '400px' }} />
        <Stack alignItems="flex-start" sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            paddingLeft: '10px'
          },
        })}>
          <Link
            component={RouterLink}
            variant="body2"
            color="secondary"
            underline="always"
            to={PATH_AUTH.resetPassword}
          >
            ¿Olvidaste la contraseña?
          </Link>
        </Stack>
      </Stack>
    </LoginLayout>
  );
}
