import {
  startOfMonth,
  endOfMonth,
  subMonths,
  format,
  startOfWeek,
  endOfWeek,
  subWeeks,
  getDay,
  getMonth,
  addDays,
  differenceInMonths,
  isAfter,
  isBefore,
  addMonths,
} from 'date-fns'
import { es } from 'date-fns/locale'
import { fNumber } from '.'

export const AXIS_X = {
  month: Array.from({ length: 31 }, (_, index) => index + 1),
  week: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
  year: ['Ene', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dic'],
  sixMonth: ['', '1 Mes', '2 Mes', '3 Mes', '4 Mes', '5 Mes', '6 Mes', ''],
  threeMonth: ['', '1 Mes', '2 Mes', '3 Mes', ''],
}

function weekComparison(inputDate) {
  // Get the current date
  const currentDate = inputDate || new Date()

  // Calculate the start date of the current week (starting from Sunday)
  const startOfCurrentWeek = startOfWeek(currentDate, { weekStartsOn: 0 })

  // Calculate the end date of the current week (ending on Saturday)
  const endOfCurrentWeek = endOfWeek(currentDate, { weekStartsOn: 0 })

  // Calculate the start date of the previous week
  const startOfPreviousWeek = startOfWeek(subWeeks(currentDate, 1), { weekStartsOn: 0 })

  // Calculate the end date of the previous week
  const endOfPreviousWeek = endOfWeek(subWeeks(currentDate, 1), { weekStartsOn: 0 })

  // Format the dates as 'yyyy-MM-dd' strings
  const formattedStartOfCurrentWeek = format(startOfCurrentWeek, 'yyyy-MM-dd')
  const formattedEndOfCurrentWeek = format(endOfCurrentWeek, 'yyyy-MM-dd')
  const formattedStartOfPreviousWeek = format(startOfPreviousWeek, 'yyyy-MM-dd')
  const formattedEndOfPreviousWeek = format(endOfPreviousWeek, 'yyyy-MM-dd')

  return {
    actual: {
      startDate: formattedStartOfCurrentWeek,
      endDate: formattedEndOfCurrentWeek,
    },
    previous: {
      startDate: formattedStartOfPreviousWeek,
      endDate: formattedEndOfPreviousWeek,
    },
  }
}

function getMonthComparisonDates(inputDate) {
  // Get the current date
  const currentDate = inputDate || new Date()

  // Calculate the first day of the current month
  const firstDayOfMonth = startOfMonth(currentDate)

  // Calculate the last day of the current month
  const lastDayOfMonth = endOfMonth(currentDate)

  // Calculate the first day of the previous month
  const firstDayOfPreviousMonth = startOfMonth(subMonths(currentDate, 1))

  // Calculate the last day of the previous month
  const lastDayOfPreviousMonth = endOfMonth(subMonths(currentDate, 1))

  // Format the dates as 'yyyy-MM-dd' strings
  const formattedFirstDayOfMonth = format(firstDayOfMonth, 'yyyy-MM-dd')
  const formattedLastDayOfMonth = format(lastDayOfMonth, 'yyyy-MM-dd')
  const formattedFirstDayOfPreviousMonth = format(firstDayOfPreviousMonth, 'yyyy-MM-dd')
  const formattedLastDayOfPreviousMonth = format(lastDayOfPreviousMonth, 'yyyy-MM-dd')

  return {
    actual: {
      startDate: formattedFirstDayOfMonth,
      endDate: formattedLastDayOfMonth,
    },
    previous: {
      startDate: formattedFirstDayOfPreviousMonth,
      endDate: formattedLastDayOfPreviousMonth,
    },
  }
}

function getLastNMonthsComparison(inputDate, numberMonth) {
  const currentDate = inputDate || new Date()

  // Calculate the end date for the current month
  const endOfCurrentMonth = endOfMonth(currentDate)

  // Calculate the start date for 6 months ago from the current month
  const startOfLast6Months = startOfMonth(subMonths(endOfCurrentMonth, numberMonth - 1))

  // Calculate the start date for 12 months ago from the current month
  const startOfLast12Months = startOfMonth(subMonths(currentDate, numberMonth * 2 - 1))

  const endOfLast12Months = endOfMonth(subMonths(endOfCurrentMonth, numberMonth))

  // Format the dates as 'yyyy-MM-dd' strings
  const formattedStartOfLast6Months = format(startOfLast6Months, 'yyyy-MM-dd')
  const formattedEndOfCurrentMonth = format(endOfCurrentMonth, 'yyyy-MM-dd')
  const formattedStartOfLast12Months = format(startOfLast12Months, 'yyyy-MM-dd')
  const formattedEndOfLast12Months = format(endOfLast12Months, 'yyyy-MM-dd')

  return {
    actual: {
      startDate: formattedStartOfLast6Months,
      endDate: formattedEndOfCurrentMonth,
    },
    previous: {
      startDate: formattedStartOfLast12Months,
      endDate: formattedEndOfLast12Months,
    },
  }
}

const compareYear = (date) => ({
  actual: {
    startDate: `${date.getFullYear()}-01-01`,
    endDate: `${date.getFullYear()}-12-31`,
  },
  previous: {
    startDate: `${date.getFullYear() - 1}-01-01`,
    endDate: `${date.getFullYear() - 1}-12-31`,
  },
})

export const grahDate = (date) => ({
  week: weekComparison(date),
  month: getMonthComparisonDates(date),
  sixMonth: getLastNMonthsComparison(date, 6),
  threeMonth: getLastNMonthsComparison(date, 3),
  year: compareYear(date),
})

export const selectTimeValue = {
  week: 'Diario',
  month: 'Diario',
  sixMonth: 'Mensual',
  threeMonth: 'Mensual',
  year: 'Mensual',
}

export const GRAPH_AXIS_TITLE = {
  week: {
    actual: 'Semana Actual',
    previous: 'Semana Anterior',
  },
  month: {
    actual: 'Mes Actual',
    previous: 'Mes Anterior',
  },
  sixMonth: {
    actual: 'Semestre Actual',
    previous: 'Semestre Anterior',
  },
  threeMonth: {
    actual: 'Trimestre Actual',
    previous: 'Trimestre Anterior',
  },
  year: {
    actual: 'Año Actual',
    previous: 'Año Anterior',
  },
}

const parseResponse = (result, dato, index) => {
  result.monto[index] += Number(dato.monto)
  result.ticket[index] = Number(dato.ticket_promedio)
  result.items[index] = Number(dato.items)
  result.totalAmount += Number(dato.monto) || 0
  result.totalItems += Number(dato.items) || 0
  result.totalTickets += Number(dato.ticket_promedio) || 0
  result.totalOrders += Number(dato.orders) || 0
  return result
}

const baseFormat = (num) => ({
  monto: new Array(num).fill(0),
  ticket: new Array(num).fill(0),
  items: new Array(num).fill(0),
  totalAmount: 0,
  totalItems: 0,
  totalTickets: 0,
  totalOrders: 0,
})

const parseBaseResult = (result) => ({
  items: result.items.map((data) => data ?? 0),
  totalAmount: Number(result.totalAmount.toFixed(2)),
  totalItems: result.totalItems,
  totalTickets:
    result.totalItems > 0 ? Number((result.totalAmount / result.totalItems).toFixed(2)) : 0,
})
const parseResult = (result) => ({
  monto: result.monto.map((data) => data ?? 0),
  ticket: result.ticket.map((data) => data ?? 0),
  ...parseBaseResult(result),
})

const parseResultMonth = (result) => ({
  monto: ['', ...result.monto.map((data) => data ?? 0), ''],
  ticket: ['', ...result.ticket.map((data) => data ?? 0), ''],
  ...parseBaseResult(result),
})

export const parseWeekArray = (dates, data) => {
  let result = baseFormat(7)

  if (dates?.length) {
    data?.forEach((dato) => {
      const dayIndex = new Date(dato.indicator.replace(/-/g, '/')).getDay()
      result = { ...result, ...parseResponse({ ...result }, dato, dayIndex) }
    })
  }
  return parseResult(result)
}

export const parseMonthArray = (dates, data) => {
  let result = baseFormat(31)

  if (dates?.length) {
    data?.forEach((dato) => {
      const dayIndex = new Date(dato.indicator).getDate()

      result = { ...result, ...parseResponse({ ...result }, dato, dayIndex) }
    })
  }
  return parseResult(result)
}

export const parseYearArray = (dates, data) => {
  let result = baseFormat(12)

  // eslint-disable-next-line guard-for-in
  if (dates?.length) {
    data?.forEach((dato) => {
      const monthIndex = new Date(dato.indicator.split('-')[0]).getMonth()
      result = { ...result, ...parseResponse({ ...result }, dato, monthIndex) }
    })
  }

  return parseResult(result)
}

function parseDataforRangeMonth(startDate, endDate, data, num = 6) {
  // Convert startDate and endDate to Date objects
  startDate = new Date(startDate)
  endDate = new Date(endDate)

  let result = baseFormat(num)

  // Iterate over the data
  // eslint-disable-next-line guard-for-in
  for (const dato of data) {
    const year = dato.indicator.split('-')[1]
    const month = dato.indicator.split('-')[0]

    const date = new Date(`${year}-${month}-01`)
    // Check if the date is within the specified range

    if (isAfter(date, startDate) && isBefore(date, addMonths(endDate, 1))) {
      const monthDiff = differenceInMonths(date, startDate)
      result = { ...result, ...parseResponse({ ...result }, dato, monthDiff) }
    }
  }

  return parseResultMonth(result)
}

export const parseDataAmount = (resultData, actual, previous) => {
  const datesActual = resultData[0].map((res) => res.indicator.split('-')[0])
  const datesPrevious = resultData[1].map((res) => res.indicator.split('-')[0])
  return {
    week: {
      actual: parseWeekArray(datesActual, resultData[0]),
      previous: parseWeekArray(datesPrevious, resultData[1]),
    },
    month: {
      actual: parseMonthArray(datesActual, resultData[0]),
      previous: parseMonthArray(datesPrevious, resultData[1]),
    },
    threeMonth: {
      actual: parseDataforRangeMonth(actual.initial_date, actual.final_date, resultData[0], 3),
      previous: parseDataforRangeMonth(
        previous.initial_date,
        previous.final_date,
        resultData[1],
        3
      ),
    },
    sixMonth: {
      actual: parseDataforRangeMonth(actual.initial_date, actual.final_date, resultData[0]),
      previous: parseDataforRangeMonth(previous.initial_date, previous.final_date, resultData[1]),
    },
    year: {
      actual: parseYearArray(datesActual, resultData[0]),
      previous: parseYearArray(datesPrevious, resultData[1]),
    },
  }
}
