import React, { useState, useEffect, useRef, useCallback } from 'react'
import isEqual from 'lodash/isEqual'
import {
  Grid,
  FormControl,
  Button,
  InputLabel,
  TextField,
  Chip,
  Autocomplete,
  CircularProgress,
  Container,
  Box,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import debounce from "lodash/debounce";
import { ZOHO } from '../../lib/ZohoEmbededAppSDK'
import { getUniqueArrays } from '../recordService'
import { useLocales } from '../../locales'
import { useAuthContext } from '../../auth/useAuthContext'
import UserMessage from '../../components/message/UserMessage'
import AttributeSetService from '../../services/attributeSetService'

export default function AddAttributeSet() {
  const form = {
    Name: '',
    logoscorpecommerce__ecommerce_: '',
  }

  // const isUpdate = useRef(false);
  const entityRef = useRef()
  const isUpdate = useRef(false)
  const currentAttributesRef = useRef([])

  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [message, setMessage] = useState(null)
  const [inputs, setInputs] = useState(form)
  const [error, setError] = useState(null)
  const [attributes, setAttributes] = useState([])
  const [selectedAttributes, setSelectedattributes] = useState([])


  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [openSelect, setOpenSelect] = React.useState(false);
  const loadingSelect = openSelect && attributes.length === 0;


  
  const { translate } = useLocales()
  const trans = (key) => translate(`zWidgetAttributeSet.${key}`)

  // const { zohoId, entity, isZohoLoaded, buttonPosition } = useContext(UserContext)
  const { zWidgetData } = useAuthContext()


  const getAttributesWithoutValue = async () => {
    setAttributes([])
    const { data, info } = await ZOHO.CRM.API.getAllRecords({
      Entity: 'logoscorpecommerce__Attributes',
      per_page: 200,
      page:1,
      sort_by: 'Name',
      sort_order: 'asc',
      fields:
        'Name,attribute_code_unique,logoscorpecommerce__attribute_id,logoscorpecommerce__attribute_code',
    })
    setAttributes(data)
  }

  useEffect(() => {

    entityRef.current = zWidgetData.Entity
    getAttributesWithoutValue()

    if (
      zWidgetData.ButtonPosition !== undefined &&
      zWidgetData.ButtonPosition !== 'ListViewWithoutRecord'
    ) {
      isUpdate.current = true
      getCurrentRecord(zWidgetData.Entity, zWidgetData.EntityId[0])
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zWidgetData])

  const ecommerceCreate = async () => {
    setIsSaving(true)
    const attributesData = selectedAttributes.map((attribute) => ({
      attribute_name: attribute.Name,
      attribute_id: attribute.logoscorpecommerce__attribute_id,
      attribute_zoho_id: attribute.id,
      attributeCode: attribute.logoscorpecommerce__attribute_code,
    }))

    const payload = {
      attribute_set_name: inputs.Name,
      attributes: attributesData,
    }
    try {
      await AttributeSetService.post(payload)
      setMessage({ message: 'Conjunto de atributos creado exitosamente.', type: 'success' })
    } catch (error) {
      if (error.message) {
        const errorMessage = error.message.replace('%1', error.parameters[0])
        setMessage({ type: 'error', message: errorMessage })
      } else {
        setMessage({ type: 'error', message: 'Ha ocurrido un error inesperado.' })
      }
    }
    setIsSaving(false)
  }

  const ecommerceUpdate = async () => {
    console.log('inputs', inputs)
    console.log('Im in an update')
    setIsSaving(true)
    try {
      if (selectedAttributes.length === 0) {
        setMessage({
          message: 'El attribute set debe tener por lo menos un atributo asociado',
          type: 'error',
        })
        return
      }

      if (!isEqual(currentAttributesRef.current, selectedAttributes)) {
        const [toDelete, toCreate] = getUniqueArrays(
          currentAttributesRef.current,
          selectedAttributes,
          'id'
        )
        const pivotResponse = await AttributeSetService.updatePivots(inputs, toDelete, toCreate)
        setMessage({ type: 'success', message: 'Se ha actualizado el conjunto exitosamente.' })
        console.log('pivotResponse', pivotResponse)
      } else {
        const updateResponse = await AttributeSetService.update(inputs)
        setMessage({ type: 'success', message: 'Se ha actualizado el conjunto exitosamente.' })
        console.log('pivotResponse', updateResponse)
      }
    } catch (error) {
      console.log('error', error)
      setMessage({ type: 'error', message: error?.message || 'Error al actualizar los atributos' })
    }

    setIsSaving(false)
  }

  const getCurrentRecord = async (entity, zohoId) => {
    console.log('GET CURRENT RECORD!', entity, zohoId)
    const currentRecord = await ZOHO.CRM.API.getRecord({
      Entity: entity,
      RecordID: zohoId,
    })
    console.log('currentRecord', currentRecord)
    const recordData = currentRecord.data[0]
    setInputs(recordData)

    const currentAttributes = await ZOHO.CRM.API.searchRecord({
      Entity: 'logoscorpecommerce__Attributes_x_AttributeSet',
      Type: 'criteria',
      Query: `(logoscorpecommerce__attribute_set_id:equals:${recordData.logoscorpecommerce__ecommerce_attribute_set_id})`,
      delay: false,
    })
    console.log('currentRecordData', recordData)
    console.log('currentAttributes', currentAttributes)
    if (currentAttributes.status !== 204) {
      console.log('currentAttributes', currentAttributes)
      setSelectedattributes(currentAttributes.data)
      currentAttributesRef.current = currentAttributes.data
    }
    setIsLoading(false)
  }

  const onChipDelete = (Name) => () => {
    setSelectedattributes((attribute) => attribute.filter((v) => v.Name !== Name))
  }

  const handleChange = (e) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [e.target.name]: e.target.value,
    }))
  }
  
     const getAttributesWithInput = async () => {
      setAttributes([])
      const { data, info } = await ZOHO.CRM.API.searchRecord({
        Entity: 'logoscorpecommerce__Attributes',
        Type: "word",
        Query: `${inputValue}`,
        delay: true,
      })
      setAttributes(data || [])
  }  

  useEffect(() => {
    const getData = setTimeout(() => {
      setAttributes([])
      if(inputValue && inputValue.length >2 ) {
        getAttributesWithInput();
      } else {
        getAttributesWithoutValue()
      }
    }, 2000)
    return () => clearTimeout(getData)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);


  if (isLoading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <Container
      fluid="true"
      maxWidth="100%"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '40px',
      }}
    >
      <Grid container spacing={2} p={2}>
        <form style={{ width: '100%' }}>
          <Grid item container xs={12}>
            {message != null && <UserMessage type={message.type} msg={message.message} />}
          </Grid>

          <Grid item container spacing={4} xs={12}>
            <Grid item container xs={12}>
              <FormControl fullWidth>
                <InputLabel shrink sx={{ backgroundColor: '#fff', padding: '3px 7px' }}>
                  {trans('attributeSetNameLabel')}
                  <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <TextField name="Name" value={inputs.Name} required onChange={handleChange} />
              </FormControl>
            </Grid>

            <Grid item container xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  fullWidth
                  multiple
                  getOptionDisabled={(option) =>
                    selectedAttributes.some(
                      (attribute) => attribute.logoscorpecommerce__Attribute_Zoho_Id === option.id
                    )
                  }
                  options={attributes}
                  value={selectedAttributes}
                  onInputChange={(event, newInputValue) => {
                    setAttributes([])
                    setInputValue(newInputValue);
                  }}
                  open={openSelect}
                  onOpen={() => {
                    setOpenSelect(true);
                  }}
                  onClose={() => {
                    setOpenSelect(false);
                  }}
                  loading={loadingSelect}
                  // filterOptions={(options, state) =>
                  //   options.filter((attr) => {
                  //     const normalizedAttrName = attr?.Name?.toLowerCase()
                  //       .normalize('NFD')
                  //       .replace(/[\u0300-\u036f]/g, '')
                  //     const normalizedInputValue = state?.inputValue
                  //       ?.toLowerCase()
                  //       .normalize('NFD')
                  //       .replace(/[\u0300-\u036f]/g, '')
                  //     return normalizedAttrName.includes(normalizedInputValue)
                  //   })
                  // }
                  filterOptions={(x) =>x}
                  getOptionLabel={(option) => option.Name}
                  isOptionEqualToValue={(option, value) => option.Name === value.Name}
                  onChange={(e, newValue) => setSelectedattributes(newValue)}
                  renderTags={() => null}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.id}>
                      {option.Name} &nbsp;
                      <small> {option.logoscorpecommerce__attribute_code}</small>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={trans('addAttributeLabel')}
                      variant="outlined"
                      placeholder={trans('addAttributePlaceholder')}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loadingSelect ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item container xs={12} spacing={1}>
              {selectedAttributes.map((v, index) => (
                <Grid item key={index}>
                  <Chip label={v.Name} onDelete={onChipDelete(v.Name)} />
                </Grid>
              ))}
            </Grid>

            <Grid
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '20px 0',
              }}
            >
              <LoadingButton
                variant="contained"
                size="small"
                sx={{ pl: 5, pr: 5 }}
                onClick={isUpdate.current ? ecommerceUpdate : ecommerceCreate}
                loading={isSaving}
              >
                Guardar
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Container>
  )
}
