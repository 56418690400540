import { createSlice } from '@reduxjs/toolkit'
import ProductAttributesService from '../../services/productAttributesService'

// ----------------------------------------------------------------------

export const fieldsProductAttributes = [
  'logoscorpecommerce__Attribute_code',
  'logoscorpecommerce__Attribute_label',
  'logoscorpecommerce__Product_code',
  'Name',
  'logoscorpecommerce__Value',
]

const initialState = {
  isLoading: false,
  error: null,
  productAttributes: [],
}

const slice = createSlice({
  name: 'productAttributes',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET PRODUCT ATTRIBUTES
    getProductAttributesSuccess(state, action) {
      state.isLoading = false
      state.productAttributes = [...state.productAttributes, ...action.payload.data]
    },

    cleanProductAttributes(state, action) {
      state.isLoading = false
      state.productAttributes = []
    },
  },
})

// Reducer
export default slice.reducer

// Actions
export const { cleanProductAttributes } = slice.actions

// ----------------------------------------------------------------------

export function getProductAttributes(
  page = 1,
  limit = 20,
  sort_order = 'desc',
  sortBy = null,
  filters = []
) {
  // eslint-disable-next-line consistent-return
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await ProductAttributesService.fetch(
        fieldsProductAttributes.join(','),
        sortBy,
        sort_order,
        page,
        limit,
        filters
      )
      dispatch(slice.actions.getProductAttributesSuccess(response))
      return response
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}
