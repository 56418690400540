import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
  Grid,
  InputLabel,
  TextField,
  Autocomplete,
  Chip,
  CircularProgress,
  Alert,
  Container,
  Tooltip,
  IconButton,
  Box,
} from '@mui/material'

import { LoadingButton } from '@mui/lab'
// import { saveRecord, deleteRecords, getUniqueArrays } from '../recordService'
import categoryService from '../../services/categoryService'
import { ZOHO } from '../../lib/ZohoEmbededAppSDK'
import { useLocales } from '../../locales'
import { useAuthContext } from '../../auth/useAuthContext'
import Iconify from '../../components/iconify'

export default function Categories() {
  const form = {
    Name: '',
    logoscorpecommerce__ecommerce_parent_id: '',
    logoscorpecommerce__magento_id: '',
    logoscorpecommerce__ecommerce_active: true,
    logoscorpecommerce__ecommerce_category_attribute_set_id: '',
  }

  const isUpdate = useRef(false)
  const entityRef = useRef(null)
  const currentTagsRef = useRef([])

  const [inputs, setInputs] = useState(form)

  // autocomplete categories
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(form)

  // autocomplete tags
  // const [tags, setTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])

  // attributeSets
  const [attributeSets, setAttributeSets] = useState([])
  const [selectedAttributeSets, setSelectedAttributeSets] = useState({
    Name: '',
  })

  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingCategories, setIsLoadingCategories] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [alertMessage, setAlertMessage] = useState({
    severity: '',
    message: '',
  })

  const { translate } = useLocales()
  const trans = (key) => translate(`zWidgetCategories.${key}`)

  // const { zohoId, entity, isZohoLoaded, buttonPosition } = useContext(UserContext);
  const { zWidgetData } = useAuthContext()

  const getParentCategory = async (currentCategory, listOfCategories) => {
    if (!currentCategory?.logoscorpecommerce__magento_id) return null
    const parent = listOfCategories.find(
      (category) =>
        Number(category?.logoscorpecommerce__magento_id) ===
        Number(currentCategory.logoscorpecommerce__ecommerce_parent_id)
    )
    return parent
  }

  const onDelete = (Name) => () => {
    setSelectedTags((tag) => tag.filter((v) => v.Name !== Name))
  }

  // const getTags = useCallback(async () => {
  //   const { data } = await ZOHO.CRM.API.getAllRecords({
  //     Entity: 'logoscorpecommerce__Category_Tags',
  //   })

  //   if (data) {
  //     setTags(data)
  //   }
  // }, [])

  const getCurrentRecord = useCallback(async (entityId, entity) => {
    const currentRecord = await ZOHO.CRM.API.getRecord({
      Entity: entity,
      RecordID: entityId[0],
    })

    // const currentTags = await ZOHO.CRM.API.searchRecord({
    //   Entity: 'logoscorpecommerce__Category_x_Category_Tags',
    //   Type: 'criteria',
    //   Query: `(logoscorpecommerce__Category_id:equals:${currentRecord.data[0].id})`,
    //   delay: false,
    // })

    const ecommerceAttributeSetId =
      currentRecord.data[0].logoscorpecommerce__ecommerce_category_attribute_set_id
    const currentAttributeSets = await ZOHO.CRM.API.searchRecord({
      Entity: 'logoscorpecommerce__Attribute_sets',
      Type: 'criteria',
      Query: `(logoscorpecommerce__ecommerce_attribute_set_id:equals:${ecommerceAttributeSetId})`,
      delay: false,
    })
    if (currentAttributeSets?.data && currentAttributeSets?.data[0]) {
      setSelectedAttributeSets(currentAttributeSets?.data[0])
    }
    // if (currentTags?.data) {
    //   setSelectedTags(currentTags.data)
    // }
    return [currentRecord.data[0]]
  }, [])

  const handleChange = (e) => {
    if (e.target.name === 'logoscorpecommerce__ecommerce_active') {
      setInputs((prevInputs) => ({
        ...prevInputs,
        [e.target.name]: e.target.checked,
      }))
      return
    }

    setInputs((prevInputs) => ({
      ...prevInputs,
      [e.target.name]: e.target.value,
    }))
  }

  const handleUpdate = async (e) => {
    setIsSaving(true)
    const payload = {
      name: inputs.Name,
      parent_id: selectedCategory?.logoscorpecommerce__magento_id.toString() || '2',
      is_active: inputs.logoscorpecommerce__ecommerce_active,
      attribute_set_id: inputs.logoscorpecommerce__ecommerce_category_attribute_set_id || '4',
    }
    try {
      const zohoResponse = await categoryService.update(
        inputs.logoscorpecommerce__magento_id,
        payload
      )
      console.log('zohoEditResponse', zohoResponse)
      console.log('status', zohoResponse[0].status)
      if (zohoResponse[0].status === 'success') {
        setAlertMessage({
          severity: 'success',
          message: trans('categoryUpdateSuccess'),
        })
      }
    } catch (error) {
      console.log(error)
      // const errorMessage = error.message.replace("%1", error.parameters[0])
      setAlertMessage({
        severity: 'error',
        message: trans('categoryCreationSuccess'),
      })
    }
    setIsSaving(false)
  }

  const handleSubmit = async () => {
    // the root category id in magento (default category) is 2
    // the default attribute set id in a magento install is 4
    setIsSaving(true)
    console.log('create')
    const payload = {
      name: inputs.Name,
      parent_id: selectedCategory?.logoscorpecommerce__magento_id.toString() || '2',
      is_active: inputs.logoscorpecommerce__ecommerce_active,
      attribute_set_id:
        inputs.logoscorpecommerce__ecommerce_category_attribute_set_id.toString() || '4',
    }
    try {
      const zohoResponse = await categoryService.post(payload)
      console.log('zohoResponse', zohoResponse)
      console.log('status', zohoResponse[0].status)
      if (zohoResponse[0].status === 'success') {
        setAlertMessage({
          severity: 'success',
          message: 'Categoría creada con éxito',
        })
      }
    } catch (error) {
      console.log(error)
      const errorMessage =
        error?.error?.message?.replace('%1', error?.error?.parameters[0]) ||
        'Error al crear la categoría'
      setAlertMessage({
        severity: 'error',
        message: errorMessage,
      })
    } finally {
      setIsSaving(false)
    }
    setIsSaving(false)
  }

  // const submitTag = async (data, categoryId) => {
  //   let apiData = []
  //   data.forEach((tag) => {
  //     apiData = [
  //       ...apiData,
  //       {
  //         Name: tag.Name,
  //         logoscorpecommerce__Category_id: categoryId,
  //         logoscorpecommerce__Tag_Id: tag.id,
  //       },
  //     ]
  //   })
  //   try {
  //     await saveRecord(apiData, 'logoscorpecommerce__Category_x_Category_Tags', false)
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  // const handleTagSubmit = async (categoryId) => {
  //   if (!isUpdate.current) {
  //     submitTag(selectedTags, categoryId)
  //     return
  //   }

  //   const [deleteRecord, createRecord] = getUniqueArrays(currentTagsRef.current, selectedTags)
  //   submitTag(createRecord, categoryId)
  //   deleteRecord.map(async (tag) => {
  //     try {
  //       await deleteRecords('logoscorpecommerce__Category_x_Category_Tags', tag.id)
  //     } catch (err) {
  //       setAlertMessage({
  //         severity: 'error',
  //         message: err.message,
  //       })
  //     }
  //   })
  // }

  const getCategories = useCallback(async () => {
    setIsLoadingCategories(true)
    let hasMore = true
    let page = 1
    const categoriesArray = []

    while (hasMore) {
      const { data, info } = await ZOHO.CRM.API.getAllRecords({
        Entity: 'logoscorpecommerce__Categories',
        per_page: 200,
        page,
        sort_by: 'Name',
        sort_order: 'asc',
      })
      page += 1

      if (data) {
        categoriesArray.push(...data)
      }
      hasMore = info?.more_records
    }

    setCategories(categoriesArray)
    setIsLoadingCategories(false)
  }, [])

  const getAttributeSet = useCallback(async () => {
    const { data } = await ZOHO.CRM.API.getAllRecords({
      Entity: 'logoscorpecommerce__Attribute_sets',
    })
    if (data) {
      setAttributeSets(data)
    }
  }, [])

  useEffect(() => {
    if (categories.length > 0) {
      getAttributeSet()
    }
  }, [categories, getAttributeSet])

  useEffect(() => {
    if (
      zWidgetData.ButtonPosition !== undefined &&
      zWidgetData.ButtonPosition !== 'ListViewWithoutRecord' &&
      categories.length > 0
    ) {
      isUpdate.current = true
      getCurrentRecord(zWidgetData.EntityId, zWidgetData.Entity).then((data) => {
        const currentRecord = data[0]
        const tags = data[1]
        currentTagsRef.current = tags
        setInputs(currentRecord)
        getParentCategory(currentRecord, categories).then((res) => {
          if (res) {
            setSelectedCategory(res)
          }
        })
        setIsLoading(false)
      })
    } else {
      setIsLoading(false)
    }
  }, [zWidgetData, getCurrentRecord, categories])

  useEffect(() => {
    if (zWidgetData.Entity !== undefined) {
      entityRef.current = zWidgetData.Entity
      // getTags().catch((err) => console.error('AddCategoriesTags', err.message))
      getCategories().catch((err) => console.error('AddCategoriesCats', err.message))
    }
  }, [zWidgetData, getCategories])

  if (isLoading || isLoadingCategories) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <Container
      fluid="true"
      maxWidth="100%"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '40px',
      }}
    >
      {alertMessage.message && (
        <Alert sx={{ marginBottom: '40px' }} severity={alertMessage.severity}>
          {alertMessage.message}
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid xs={12} container item sx={{ width: '100%' }}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel shrink sx={{ backgroundColor: '#fff', padding: '3px 7px' }}>
              {trans('categoryNameLabel')}
            </InputLabel>
            <TextField name="Name" value={inputs.Name} required onChange={handleChange} fullWidth />
          </FormControl>
        </Grid>

        <Grid container item xs={12} wrap="nowrap">
          <Autocomplete
            fullWidth
            options={categories}
            value={selectedCategory}
            filterOptions={(options, state) =>
              options.filter((attr) => {
                const normalizedAttrName = attr?.Name?.toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                const normalizedInputValue = state?.inputValue
                  ?.toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                return normalizedAttrName.includes(normalizedInputValue)
              })
            }
            getOptionLabel={(option) => option.Name}
            onChange={(e, newValue) => {
              setSelectedCategory(newValue)
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.id}>
                {option.Name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={trans('parentCategoryNameLabel')}
                variant="outlined"
                placeholder={trans('parentCategoryPlaceholder')}
              />
            )}
          />
          <Tooltip title="Refresh">
            <IconButton color="primary" onClick={getCategories}>
              <Iconify icon="eva:refresh-outline" />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item container xs={12} fullWidth>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              options={attributeSets}
              value={selectedAttributeSets}
              filterOptions={(options, state) =>
                options.filter((attr) => {
                  const normalizedAttrName = attr?.Name?.toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                  const normalizedInputValue = state?.inputValue
                    ?.toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                  return normalizedAttrName.includes(normalizedInputValue)
                })
              }
              getOptionLabel={(option) => option.Name}
              onChange={(e, newValue) => {
                setSelectedAttributeSets(newValue || { Name: '' })
                setInputs((prevInputs) => ({
                  ...prevInputs,
                  logoscorpecommerce__ecommerce_category_attribute_set_id: newValue
                    ? newValue.logoscorpecommerce__ecommerce_attribute_set_id
                    : null,
                  logoscorpecommerce__Attribute_Set_Zoho_Id: newValue ? newValue.id : null,
                }))
              }}
              renderTags={() => null}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.id}>
                  {option.Name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={trans('attributeSetLabel')}
                  testing="test"
                  variant="outlined"
                  name="logoscorpecommerce__ecommerce_category_attribute_set_id"
                  placeholder={trans('attributeSetPlaceholder')}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/*   <Grid item container xs={12} fullWidth>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              multiple
              options={tags}
              value={selectedTags}
              getOptionDisabled={(option) => selectedTags.some((tag) => tag.id === option.id)}
              getOptionLabel={(option) => option.Name}
              isOptionEqualToValue={(option, value) => option.Name === value.Name}
              onChange={(e, newValue) => setSelectedTags(newValue)}
              renderTags={() => null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={trans('categoryTagLabel')}
                  variant="outlined"
                  placeholder={trans('categoryTagPlaceholder')}
                />
              )}
            />
          </FormControl>
        </Grid> */}

        <Grid item container xs={12} spacing={1} fullWidth>
          {selectedTags?.map((v, index) => (
            <Grid key={index} item>
              <Chip key={v.Name} label={v.Name} onDelete={onDelete(v.Name)} />
            </Grid>
          ))}
        </Grid>

        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={6}>
            <FormControlLabel
              control={
                <Switch
                  onChange={handleChange}
                  value={inputs.logoscorpecommerce__ecommerce_active}
                  checked={!!inputs.logoscorpecommerce__ecommerce_active}
                  name="logoscorpecommerce__ecommerce_active"
                />
              }
              label={
                <Typography component="p" variant="subtitle2">
                  {trans('categoryIsActive')}
                </Typography>
              }
            />
          </Grid>
        </Grid>

        <Grid container item justifyContent="flex-end">
          <LoadingButton
            variant="contained"
            size="small"
            sx={{ pl: 5, pr: 5 }}
            onClick={isUpdate.current ? handleUpdate : handleSubmit}
            loading={isSaving}
          >
            {trans('categorySaveBtn')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Container>
  )
}
