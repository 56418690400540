import { useSelector } from 'react-redux'
import { createSlice } from '@reduxjs/toolkit'
import CancelAccountRequestsService from '../../services/cancelAccountRequestService'

// ----------------------------------------------------------------------

export const fieldsVendor = [
  'Name',
  'logoscorpecommerce__vendor_name',
  'logoscorpecommerce__vendor_ecommerce_id',
  'logoscorpecommerce__request_status',
]

const initialState = {
  cancelAccountRequest: null,
  cancelAccountRequests: [],
  isSaving: false,
  isLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    // START SAVING
    startSaving(state) {
      state.isSaving = true
    },

    // ENDSAVING SAVING
    endSaving(state) {
      state.isSaving = false
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.isSaving = false
      state.error = action.payload
    },
  },
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export function ApproveRejectCancelAccountResources(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startSaving())
    try {
      await CancelAccountRequestsService.updateOtherResource(data.id, data, data.otherResource)
      dispatch(slice.actions.endSaving())
    } catch (error) {
      console.log(error)
      dispatch(slice.actions.hasError(error))
      throw new Error(error.error?.message || 'Ha ocurrido un error, por favor intenta mas tarde.')
    }
    //  finally {
    //   dispatch(slice.actions.endSaving())
    // }
  }
}
