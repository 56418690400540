import { Suspense, lazy } from 'react'
// components
import LoadingScreen from '../components/loading-screen'

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')))
export const RegisterPage = Loadable(
  lazy(() => import(/* webpackChunkName: "register" */ '../pages/auth/RegisterPage'))
)
export const VerifyCodePage = Loadable(
  lazy(() => import(/* webpackChunkName: "register" */ '../pages/auth/VerifyCodePage'))
)
export const NewPasswordPage = Loadable(
  lazy(() => import(/* webpackChunkName: "register" */ '../pages/auth/NewPasswordPage'))
)
export const ResetPasswordPage = Loadable(
  lazy(() => import(/* webpackChunkName: "register" */ '../pages/auth/ResetPasswordPage'))
)

// DASHBOARD: GENERAL
export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAppPage')))
export const GeneralMassiveLoadPage = Loadable(
  lazy(() => import(/* webpackChunkName: "products" */ '../pages/dashboard/GeneralMassiveLoadPage'))
)

export const AddRelatedProductsPage = Loadable(
  lazy(() =>
    import(/* webpackChunkName: "products" */ '../pages/dashboard/products/AddRelatedProductsPage')
  )
)
export const ProductDetailsPage = Loadable(
  lazy(() =>
    import(/* webpackChunkName: "products" */ '../pages/dashboard/products/ProductDetailsPage')
  )
)
export const ProductListPage = Loadable(
  lazy(() =>
    import(/* webpackChunkName: "products" */ '../pages/dashboard/products/ProductListPage')
  )
)
export const ProductCreatePage = Loadable(
  lazy(() =>
    import(/* webpackChunkName: "products" */ '../pages/dashboard/products/ProductCreatePage')
  )
)
export const VariationCreatePage = Loadable(
  lazy(() =>
    import(
      /* webpackChunkName: "products" */ '../pages/dashboard/products/variations/VariationCreatePage'
    )
  )
)

// // DASHBOARD: MASSIVELOAD
export const MassiveLoadImportPage = Loadable(
  lazy(() =>
    import(
      /* webpackChunkName: "products" */ '../pages/dashboard/massiveLoad/MassiveLoadImportPage'
    )
  )
)
export const MassiveLoadCreatePage = Loadable(
  lazy(() =>
    import(
      /* webpackChunkName: "products" */ '../pages/dashboard/massiveLoad/MassiveLoadCreatePage'
    )
  )
)
export const MassiveLoadListPage = Loadable(
  lazy(() =>
    import(/* webpackChunkName: "products" */ '../pages/dashboard/massiveLoad/MassiveLoadListPage')
  )
)
export const MassiveLoadDataPage = Loadable(
  lazy(() =>
    import(/* webpackChunkName: "products" */ '../pages/dashboard/massiveLoad/MassiveLoadDataPage')
  )
)
export const MassiveLoadHistoryPage = Loadable(
  lazy(() =>
    import(
      /* webpackChunkName: "products" */ '../pages/dashboard/massiveLoad/MassiveLoadHistoryPage'
    )
  )
)
export const MassiveLoadHistoryDetailsPage = Loadable(
  lazy(() =>
    import(
      /* webpackChunkName: "products" */ '../pages/dashboard/massiveLoad/MassiveLoadHistoryDetailsPage'
    )
  )
)

// DASHBOARD: INVOICE
export const InvoiceListPage = Loadable(
  lazy(() =>
    import(/* webpackChunkName: "invoices" */ '../pages/dashboard/invoice/InvoiceListPage')
  )
)

export const InvoiceDetailsPage = Loadable(
  lazy(() =>
    import(/* webpackChunkName: "invoices" */ '../pages/dashboard/invoice/InvoiceDetailsPage')
  )
)

// DASHBOARD: PRODUCTQUESTIONS
export const ProductQuestionsListPage = Loadable(
  lazy(() =>
    import(
      /* webpackChunkName: "question-products" */ '../pages/dashboard/productQuestions/ProductQuestionsListPage'
    )
  )
)
export const ProductQuestionsDetailPage = Loadable(
  lazy(() =>
    import(
      /* webpackChunkName: "question-products" */ '../pages/dashboard/productQuestions/ProductQuestionsDetailPage'
    )
  )
)

// DASHBOARD: BRANDS
export const BrandsListPage = Loadable(
  lazy(() => import(/* webpackChunkName: "brands" */ '../pages/dashboard/brands/BrandsListPage'))
)
export const BrandsDetailPage = Loadable(
  lazy(() => import(/* webpackChunkName: "brands" */ '../pages/dashboard/brands/BrandsDetailPage'))
)
export const BrandsRequestPage = Loadable(
  lazy(() => import(/* webpackChunkName: "brands" */ '../pages/dashboard/brands/BrandsRequestPage'))
)

// DASHBOARD: SUPPORT
export const SupportListPage = Loadable(
  lazy(() => import(/* webpackChunkName: "brands" */ '../pages/dashboard/support/SupportListPage'))
)
export const SupportDetailPage = Loadable(
  lazy(() =>
    import(/* webpackChunkName: "brands" */ '../pages/dashboard/support/SupportDetailPage')
  )
)
export const SupportCreatePage = Loadable(
  lazy(() =>
    import(/* webpackChunkName: "brands" */ '../pages/dashboard/support/SupportCreatePage')
  )
)

// DASHBOARD: USER
export const UserProfilePage = Loadable(
  lazy(() => import(/* webpackChunkName: "profile" */ '../pages/dashboard/UserProfilePage'))
)
export const UserListPage = Loadable(
  lazy(() => import(/* webpackChunkName: "profile" */ '../pages/dashboard/UserListPage'))
)
export const UserAccountPage = Loadable(
  lazy(() => import(/* webpackChunkName: "profile" */ '../pages/dashboard/UserAccountPage'))
)
export const UserCreatePage = Loadable(
  lazy(() => import(/* webpackChunkName: "profile" */ '../pages/dashboard/UserCreatePage'))
)
export const UserEditPage = Loadable(
  lazy(() => import(/* webpackChunkName: "profile" */ '../pages/dashboard/UserEditPage'))
)

// BLANK PAGE
export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')))

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')))
export const Page403 = Loadable(lazy(() => import('../pages/Page403')))
export const Page404 = Loadable(lazy(() => import('../pages/Page404')))

// ADMIN

export const ProductAdminListPage = Loadable(
  lazy(() =>
    import(/* webpackChunkName: "productsAdmin" */ '../pages/admin/products/ProductAdminListPage')
  )
)

export const InvoiceAdminListPage = Loadable(
  lazy(() =>
    import(/* webpackChunkName: "ordersAdmin" */ '../pages/admin/orders/InvoiceAdminListPage')
  )
)

export const BrandsAdminListPage = Loadable(
  lazy(() =>
    import(/* webpackChunkName: "brandsAdmin" */ '../pages/admin/brands/BrandsAdminListPage')
  )
)
export const BrandAdminPage = Loadable(
  lazy(() => import(/* webpackChunkName: "brandsAdmin" */ '../pages/admin/brands/BrandAdminPage'))
)

export const BrandsAdminRequestListPage = Loadable(
  lazy(() =>
    import(/* webpackChunkName: "brandsAdmin" */ '../pages/admin/brands/BrandAdminRequestListPage')
  )
)

export const RequestBrandsPage = Loadable(
  lazy(() =>
    import(
      /* webpackChunkName: "brandsRequestPage" */ '../pages/dashboard/brands/RequestBrandsPage'
    )
  )
)

export const RequestBrandListPage = Loadable(
  lazy(() =>
    import(
      /* webpackChunkName: "brandsRequestVendor" */ '../pages/dashboard/brands/RequestBrandListPage'
    )
  )
)
