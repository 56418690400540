import axios from '../utils/axios'
import BaseService from './baseService'

export default class ProductService extends BaseService {
  static path = ''

  static resource() {
    return `Products${this.path}`
  }

  /**
   * Query for delete products
   * @param {String[]} productsIds - productsIds
   * @returns {Promise<any>}
   */
  static async delete(productsIds) {
    const response = await axios.delete(`${this.baseURL()}/${this.resource()}`, {
      data: { productsIds },
    })
    return response
  }

  static async s3ImageFromPath(path) {
    try {
      const response = await this.get(`s3ImageFromPath?path=${encodeURIComponent(path)}`)
      return response.data
    } catch (error) {
      return {}
    }
  }

  static async getImages(id) {
    try {
      const response = await this.get(`${id}/images`)
      return response.data
    } catch (error) {
      return {}
    }
  }

  // static async getPhoto( id ) {
  //   try{
  //     const response = await this.get(`${id}/photo`)
  //     return response.data;
  //   }catch(error){
  //     return {}
  //   }
  // }

  // static async getAttachments( id ) {
  //   try{
  //     const response = await this.get(`${id}/Attachments`)
  //     return response
  //   }
  //   catch(error){
  //     return []
  //   }
  // }

  static async updateStock(formData) {
    try {
      this.path = '/mass-stock-update'
      const response = await this.post(formData)
      this.path = ''
      return response
    } catch (error) {
      console.log('updateStock Error')
      console.log(error)
      throw error
    }
  }

  static async updatePrice(formData) {
    try {
      this.path = '/mass-price-update'
      const response = await this.post(formData)
      this.path = ''
      return response
    } catch (error) {
      console.log('updatePrice Error')
      console.log(error)
      throw error
    }
  }

  static async getProductsStock(vendorId, page, pageSize) {
    try {
      this.path = '/get-products-stock-and-price'
      const response = await this.get('', { vendorId, page, pageSize })
      this.path = ''
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  /**
   * Modify a existent resource
   * @param {Number} id - resource Id
   * @param {object} payload  - Data to update
   * @param {string} otherResouce param  - String
   * @param {boolean} isFormData  - Data is send should be converted in form-data
   * @returns {object} resource
   */
  static async massiveUpdate(payload = {}, path) {
    try {
      const { data } = await this.updateMassive(path, payload)
      return data
    } catch (error) {
      console.log(error)
      const message =
        error.errors && Array.isArray(error.errors)
          ? error.errors[0].msg
          : error?.msg || error?.error || 'Error al actualizar los productos'
      throw Error(message)
    }
  }

  /**
   * Create a Product
   * @param {Number} id - product Id
   * @param {object} payload  - Data to create
   * @param {boolean} isFormData  - Data is send should be converted in form-data
   * @returns {object} resource
   */
  static async post(payload = {}, isFormData = false) {
    const { data } = await axios.post(`${this.baseURL()}/${this.resource()}`, payload, {
      headers: isFormData && { 'Content-Type': 'multipart/form-data' },
    })
    return data
  }

  /**
   * Modify a existent Product
   * @param {Number} id - product Id
   * @param {object} payload  - Data to update
   * @param {boolean} isFormData  - Data is send should be converted in form-data
   * @returns {object} resource
   */
  static async update(id, payload = {}, isFormData = false) {
    const { data } = await axios.put(`${this.baseURL()}/${this.resource()}/${id}`, payload, {
      headers: isFormData && { 'Content-Type': 'multipart/form-data' },
    })
    return data
  }

  /**
   * Update price and stock Product
   * @param {Number} id - product Id
   * @param {object} payload  - Data to update
   * @returns {object} resource
   */

  static async updateIndividualStockPrice(dataToUpload) {
    try {
      const { id, ...payload } = dataToUpload
      this.path = '/update-stock-price'
      const { data } = await axios.put(`${this.baseURL()}/${this.resource()}/${id}`, payload)
      this.path = ''
      return data
    } catch (error) {
      console.log('updateStock Error')
      console.log(error)
      throw error
    } finally {
      this.path = ''
    }
  }

  static async deleteMagentoProducts(vendorEcommerceId, quantity) {
    try {
      const payload = {
        vendor_ecommerce_id: vendorEcommerceId,
        qty_deletes: quantity,
      }
      this.path = '/mass-delete-magento-delete-account'
      const { data } = await axios.put(`${this.baseURL()}/${this.resource()}`, payload)
      this.path = ''
      return data
    } catch (error) {
      console.log('Delete Products Error')
      console.log(error)
      throw error
    } finally {
      this.path = ''
    }
  }
}
