import _mock from '../_mock';
import { randomNumberRange, randomInArray } from '../utils';

// ----------------------------------------------------------------------

export const _appRelated = ['Chrome', 'Drive', 'Dropbox', 'Evernote', 'Github'].map(
  (name, index) => ({
    id: _mock.id(index),
    name,
    system: (index === 2 && 'Windows') || (index === 4 && 'Windows') || 'Mac',
    price: index === 0 || index === 2 || index === 4 ? 0 : _mock.number.price(index),
    rating: _mock.number.rating(index),
    review: randomNumberRange(999, 99999),
    shortcut:
      (name === 'Chrome' && '/assets/icons/apps/ic_chrome.svg') ||
      (name === 'Drive' && '/assets/icons/apps/ic_drive.svg') ||
      (name === 'Dropbox' && '/assets/icons/apps/ic_dropbox.svg') ||
      (name === 'Evernote' && '/assets/icons/apps/ic_evernote.svg') ||
      '/assets/icons/apps/ic_github.svg',
  })
);

// ----------------------------------------------------------------------

export const _appInstalled = ['de', 'en', 'fr', 'kr', 'us'].map((country, index) => ({
  id: _mock.id(index),
  name: ['Germany', 'England', 'France', 'Korean', 'USA'][index],
  android: randomNumberRange(999, 99999),
  windows: randomNumberRange(999, 99999),
  apple: randomNumberRange(999, 99999),
  flag: `/assets/icons/flags/ic_flag_${country}.svg`,
}));

// ----------------------------------------------------------------------

export const _appAuthors = [...Array(3)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  avatar: _mock.image.avatar(index),
  favourite: randomNumberRange(9999, 19999),
}));

// ----------------------------------------------------------------------

export const _appInvoices = [...Array(5)].map((_, index) => ({
  id: `${Date.now() + index}`,
  price: _mock.number.price(index),
  category: [
    'Calzado casual',
    'Traje caballero slim fantasía',
    'Camisa negra',
    'Short azul',
    'Pantalon jean'
  ][index],
  status: randomInArray(['Completado', 'Cancelada', 'En progreso']),
}));

export const _appInvoices2 = [...Array(5)].map((_, index) => ({
  id: [
    '4225-776-3234',
    '6541-455-9812',
    '7856-776-3456',
    '1245-346-8765',
    '3455-776-3345'
  ][index],
  price: _mock.number.price(index),
  category: [
    'Pantalón Textura',
    'Camisa Manga larga Slim estampada',
    'Saco textura',
    'Calcetines estampados',
    'Camisa Manga Larga Fantasía '
  ][index],
  status: [
    35,
    24,
    18,
    17,
    14
  ][index],
}));

// ----------------------------------------------------------------------

export const _appFeatured = [...Array(2)].map((_, index) => ({
  id: _mock.id(index),
  title: [
    'Compra un espacio publicitario en Sambil Online',
    'Crea un programa de lealtad  y aumenta tus ventas',
  ][index],
  category: [
    'Campañas',
    'Estrategias'
  ][index],
  image: [
    `${process.env.PUBLIC_URL}/assets/banners/banner-publicidad2.jpg`,
    `${process.env.PUBLIC_URL}/assets/banners/banner-lealtad.jpg`
  ][index],
}));
