import axios from '../utils/axios'
import BaseService from './baseService'

export default class AttributeSetService extends BaseService {
  static resource() {
    return 'Attributes_by_sets'
  }

  static catalystResource() {
    return 'AttributeSets'
  }

  static async post(payload = {}, isFormData = false) {
    const baseUrl = await this.baseURL()
    const { data } = await axios.post(`${baseUrl}/${this.catalystResource()}`, payload, {
      headers: isFormData && { 'Content-Type': 'multipart/form-data' },
    })
    return data.data
  }

  static async update({ logoscorpecommerce__ecommerce_attribute_set_id: magentoId, id, Name }) {
    console.log('update set')
    const baseUrl = await this.baseURL()

    const payload = {
      attribute_set_zoho_id: id,
      attribute_set_name: Name,
    }
    const { data } = await axios.put(`${baseUrl}/${this.catalystResource()}/${magentoId}`, payload)
    return data
  }

  static async updatePivots(inputs, toDelete, toCreate) {
    try {
      const baseUrl = await this.baseURL()
      const attributeId = inputs.logoscorpecommerce__ecommerce_attribute_set_id
      const createAttribute = toCreate.map((item) => ({
        attribute_name: item.Name,
        attributeCode: item.logoscorpecommerce__attribute_code,
        attribute_zoho_id: item.id,
        attribute_id: item.logoscorpecommerce__attribute_id,
      }))
      const deleteAtributes = toDelete.map((item) => ({
        attributeCode: item.logoscorpecommerce__attribute_code,
        attribute_id: item.logoscorpecommerce__attribute_id,
      }))

      const payload = {
        group_id: Number(inputs.logoscorpecommerce__ecommerce_Attribute_Group_Id),
        records_to_delete: deleteAtributes,
        records_to_create: createAttribute,
        name: inputs.Name,
        zoho_id: inputs.id,
      }
      const { data } = await axios.put(
        `${baseUrl}/${this.catalystResource()}/${attributeId}/attributes`,
        payload
      )
      return data
    } catch (error) {
      console.log('error', error.response.data.error)
      throw Error(error?.response?.data?.error || 'Error al actualizar los atributos')
    }
  }

  static async delete(id) {
    const baseUrl = await this.baseURL()
    const { data } = await axios.delete(`${baseUrl}/${this.catalystResource()}/${id}`)
    return data.data[0]
  }
}
