export * from './formatNumber'
export * from './formatString'
export * from './sliceData'

export const generateRandomIntFromInterval = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min)

export const extractVariationsSkusFromConfigurations = (configurations) => {
  const firstArr = configurations.split('|')
  const values = []
  for (const item of firstArr) {
    if (item.startsWith('sku=')) {
      values.push(item.split('#sambil#')[0].substring(4))
    }
  }
  return values
}

// todo
/**
 * Get An Array with Ids for Attributes in configuration field
 * @returns ids Array
 */
export const extractIdsVariationsfromConfigurations = (configurations) => {
  const configs = configurations.split('|')
  const uniqueIds = new Set()

  for (const config of configs) {
    const configItems = config.split('#sambil#')
    const sku = configItems[0]

    // Remove SKU
    configItems.shift()

    const attrValues = configItems.map((item) => {
      const [attrKey, attrValue] = item.split('=')
      const [attrId] = attrKey.split('-')
      return Number(attrId)
    })

    if (sku.startsWith('sku=')) {
      // Corrected condition
      for (const value of attrValues) {
        uniqueIds.add(value)
      }
    }
  }

  return Array.from(uniqueIds)
}

export const extractProductsConfigurations = (configurations) => {
  const configs = configurations.split('|')
  const hashmap = {}
  for (const config of configs) {
    const configItems = config.split('#sambil#')
    const sku = configItems[0]
    // Remove SKU
    configItems.shift()
    const attrValues = configItems.map((item) => {
      const [attrKey, attrValue] = item.split('=')
      const [attrId, attrLabel] = attrKey.split('-')
      return { id: attrId, label: attrLabel, value: attrValue }
    })
    if (sku.startsWith('sku=')) {
      const realSku = sku.substring(4)
      hashmap[realSku] = attrValues
    }
  }
  return hashmap
}
