import axios from '../utils/axios'

import BaseService from "./baseService";

export default class Configurations extends BaseService {
  static path = ''

    static resource() {
      return `Configurations${this.path}`
    }

  static async getTermAndConditions() {
    try {
      this.path = `/terms_and_conditions`
      const { data } = await axios.get(`${this.baseURL()}/${this.resource()}`, {})
      console.log('data', data)
      this.path = ''
      return data
    } catch (error) {
      console.log(error)
      throw new Error(error.error?.message || 'Ha ocurrido un error, por favor intenta mas tarde.')
    } finally {
      this.path = ''
    }
  }

  
}