// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`
}

const ROOTS_AUTH = '/auth'
const ROOTS_DASHBOARD = '/dashboard'
const ROOTS_ADMIN = '/zAdmin'
const ROOTS_CATALYST = '/app/index.html#'

// ----------------------------------------------------------------------

const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
}

const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
}

const PATH_CATALYST = {
  login: path(ROOTS_CATALYST, PATH_AUTH.login),
}

const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    massiveload: path(ROOTS_DASHBOARD, '/massive-load'),
    products: path(ROOTS_DASHBOARD, '/products'),
    orders: path(ROOTS_DASHBOARD, '/orders'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    file: path(ROOTS_DASHBOARD, '/file'),
    productquestions: path(ROOTS_DASHBOARD, '/product-questions'),
    brands: path(ROOTS_DASHBOARD, '/brands'),
    support: path(ROOTS_DASHBOARD, '/support'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  products: {
    root: path(ROOTS_DASHBOARD, '/products'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/products/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    addRelated: path(ROOTS_DASHBOARD, '/products/addRelated'),
    new: (step) => path(ROOTS_DASHBOARD, `/products/new/${step}`),
    variation: path(ROOTS_DASHBOARD, '/products/variation'),
    // view: (id) => path(ROOTS_DASHBOARD, `/products/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/products/${id}/edit`),
    // demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    // demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  orders: {
    root: path(ROOTS_DASHBOARD, '/orders'),
    list: path(ROOTS_DASHBOARD, '/orders/list'),
    checkout: path(ROOTS_DASHBOARD, '/orders/checkout'),
    view: (id) => path(ROOTS_DASHBOARD, `/orders/orders/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/orders/orders/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/orders/orders/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/orders/orders/nike-air-force-1-ndestrukt'),
  },
  massiveLoad: {
    root: path(ROOTS_DASHBOARD, '/massive-load'),
    new: path(ROOTS_DASHBOARD, '/massive-load/new-template'),
    list: path(ROOTS_DASHBOARD, '/massive-load/list'),
    load: path(ROOTS_DASHBOARD, '/massive-load/load'),
    import: path(ROOTS_DASHBOARD, '/massive-load/import'),
    history: path(ROOTS_DASHBOARD, '/massive-load/history'),
    historyDetails: (id) => path(ROOTS_DASHBOARD, `/massive-load/history/${id}`),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
  productquestions: {
    root: path(ROOTS_DASHBOARD, '/product-questions'),
    list: path(ROOTS_DASHBOARD, '/product-questions/list'),
    view: (id) => path(ROOTS_DASHBOARD, `/product-questions/${id}`),
    // edit: (id) => path(ROOTS_DASHBOARD, `/product-questions/${id}/edit`),
  },
  brands: {
    root: path(ROOTS_DASHBOARD, '/brands'),
    list: path(ROOTS_DASHBOARD, '/brands/request'),
    request: path(ROOTS_DASHBOARD, '/brands/request'),
    new: path(ROOTS_DASHBOARD, `/brands/new`),
    view: (id) => path(ROOTS_DASHBOARD, `/brands/${id}`),
    viewRequest: (id) => path(ROOTS_DASHBOARD, `/brands/${id}/request`),
    newRequest: path(ROOTS_DASHBOARD, `/brands/resquest/new`),
  },
  support: {
    root: path(ROOTS_DASHBOARD, '/brands'),
    list: path(ROOTS_DASHBOARD, '/support/list'),
    view: (id) => path(ROOTS_DASHBOARD, `/support/${id}`),
    new: path(ROOTS_DASHBOARD, `/support/new`),
  },
}

const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  general: {
    app: path(ROOTS_ADMIN, '/app'),
    products: path(ROOTS_ADMIN, '/products'),
    orders: path(ROOTS_ADMIN, '/orders'),
    massiveLoad: path(ROOTS_ADMIN, '/massive-load'),
    productquestions: path(ROOTS_ADMIN, '/product-questions'),
    brands: path(ROOTS_ADMIN, '/brands'),
  },
  products: {
    root: path(ROOTS_ADMIN, '/products'),
    list: path(ROOTS_ADMIN, '/products/list'),
    new: (step) => path(ROOTS_ADMIN, `/products/new/${step}`),
    edit: (id) => path(ROOTS_ADMIN, `/products/edit/${id}`),
    addRelated: path(ROOTS_ADMIN, '/products/addRelated'),
    variation: path(ROOTS_ADMIN, '/products/variation'),
   // view: (id) => path(ROOTS_ADMIN, `/products/${id}`),
  },
  invoice: {
    root: path(ROOTS_ADMIN, '/orders'),
    list: path(ROOTS_ADMIN, '/orders/list'),
    view: (id) => path(ROOTS_ADMIN, `/orders/${id}`),
    edit: (id) => path(ROOTS_ADMIN, `/orders/${id}/edit`),
  },
  massiveLoad: {
    root: path(ROOTS_ADMIN, '/massive-load'),
    new: path(ROOTS_ADMIN, '/massive-load/new-template'),
    list: path(ROOTS_ADMIN, '/massive-load/list'),
    load: path(ROOTS_ADMIN, '/massive-load/load'),
    import: path(ROOTS_ADMIN, '/massive-load/import'),
    history: path(ROOTS_ADMIN, '/massive-load/history'),
    historyDetails: (id) => path(ROOTS_ADMIN, `/massive-load/history/${id}`),
  },
  productquestions: {
    root: path(ROOTS_ADMIN, '/product-questions'),
    list: path(ROOTS_ADMIN, '/product-questions/list'),
    view: (id) => path(ROOTS_ADMIN, `/product-questions/${id}`),
  },
  brands: {
    root: path(ROOTS_ADMIN, '/brands'),
    new: path(ROOTS_ADMIN, `/brands/new`),
    list: path(ROOTS_ADMIN, '/brands/list'),
    request: path(ROOTS_ADMIN, '/brands/request'),
    view: (id) => path(ROOTS_ADMIN, `/brands/${id}`),
    viewEdit: (id) => path(ROOTS_ADMIN, `/brands/${id}/edit`),
    viewRequest: (id) => path(ROOTS_ADMIN, `/brands/${id}/request`),
  },
}

const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
}

const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/'

const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/'

const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/'

const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1'

export {
  PATH_AUTH,
  PATH_PAGE,
  PATH_DASHBOARD,
  PATH_ADMIN,
  PATH_DOCS,
  PATH_ZONE_ON_STORE,
  PATH_MINIMAL_ON_STORE,
  PATH_FREE_VERSION,
  PATH_FIGMA_PREVIEW,
  PATH_CATALYST,
}
