import AttributesService from '../../services/attributesService'
import { ZOHO } from '../../lib/ZohoEmbededAppSDK'
import { saveRecord, getUniqueArrays } from '../recordService'

export async function ecommerceCreateAttribute(inputs, options) {
  const normalizedString = inputs.Name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  const attributeCode = normalizedString.split(' ').join('_').toLowerCase()

  const magentoPayload = {
    name: inputs.Name,
    attributeCode,
    frontend_input: inputs.logoscorpecommerce__frontend_input,
    is_required: !!inputs.logoscorpecommerce__is_required,
    is_user_defined: true,
    is_visible: true,
    options,
  }

  try {
    const catalystResponse = await AttributesService.post(magentoPayload)
    console.log('catalystResponse', catalystResponse)
    return { message: 'Record creado satisfactoriamente', type: 'success' }
  } catch (error) {
    const errorResponse = error.error
    if (errorResponse.message) {
      const errorMessage = errorResponse.parameters ? errorResponse.message.replace('%1', errorResponse.parameters[0]) : errorResponse.message
      throw new Error(errorMessage)
    } else {
      throw new Error('Ha ocurrido un error inesperado.')
    }
  }
}

export async function ecommerceEditAttributeLabel(inputs, toDelete, toCreate) {
  const magentoAttributePayload = {
    attribute: {
      attribute_id: inputs.logoscorpecommerce__attribute_id,
      default_frontend_label: inputs.Name,
      is_required: false,
    },
  }

  const magentoPayload2 = {
    zoho_id: inputs.id,
    attribute_id: inputs.logoscorpecommerce__attribute_id,
    default_frontend_label: inputs.Name,
    frontend_input: inputs.logoscorpecommerce__frontend_input,
    is_required: !!inputs.logoscorpecommerce__is_required,
    create_options: toCreate,
    delete_options: toDelete,
  }
  console.log('payloadToCatalyst', magentoPayload2)

  try {
    const catalystResponse = await AttributesService.update(
      inputs.logoscorpecommerce__attribute_code,
      magentoPayload2
    )
    console.log('catalystResponse', catalystResponse)
    return { message: 'Record actualizado satisfactoriamente', type: 'success' }
  } catch (error) {
    const errorResponse = error.error
    if (errorResponse?.message) {
      const errorMessage = errorResponse.parameters ? errorResponse.message.replace('%1', errorResponse.parameters[0]) : errorResponse.message
      throw new Error(errorMessage)
    } else {
      throw new Error('Ha ocurrido un error inesperado.')
    }
  }

  // const zohoResponse = await ZOHO.CRM.FUNCTIONS.execute("logoscorpecommerce__magentoeditattribute", {
  //   attributeCode: inputs.logoscorpecommerce__attribute_code,
  //   magentoAttributePayload: JSON.stringify(magentoAttributePayload)
  // })
  // const response = JSON.parse(zohoResponse.details.output)

  // if (response.responseCode !== 200){
  //   console.log("error", response.resonseText)
  //   return { message: `Error en sincronizacion: ${response.responseText.parameters} record no creado`, type: "error" }
  // }

  // const insertRecords = await  ZOHO.CRM.API.updateRecord({ Entity: "logoscorpecommerce__Attributes", APIData: inputs })
  // return { message: "Record editado satisfactoriamente", type: "success" }
}

export async function ecommerceEditAttributeOptions(inputs, prevOptions, currentOptions) {
  const [recordsToDelete, recordsToCreate] = getUniqueArrays(prevOptions, currentOptions, 'label')
  const attributeCode = inputs.logoscorpecommerce__attribute_code
  const payloadToCreate = recordsToCreate.length && { options: recordsToCreate }
  const payloadToDelete = recordsToDelete.length && { options: recordsToDelete }
  const zohoId = inputs.id

  const zohoResponse = await ZOHO.CRM.FUNCTIONS.execute(
    'logoscorpecommerce__magentoeditattributeoptions',
    {
      createOptions: JSON.stringify(payloadToCreate),
      deleteOptions: JSON.stringify(payloadToDelete),
      attributeCode,
    }
  )

  try {
    const response = JSON.parse(zohoResponse.details.output)
    console.log('fullResponsefromZoho', response)
    // Crear las opciones que deben ser creadas
    if (response.createOptionsResponse) {
      if (response.createOptionsResponse.responseCode === 200) {
        const newOptions = response.createOptionsResponse.responseText[0].options
        createAttributeOption(newOptions, zohoId, attributeCode)
      } else {
        return {
          message: `Error en sincronizacion: ${response.responseText.parameters} record no creado`,
          type: 'error',
        }
      }
    }

    // Eliminar las opciones que deben ser eliminadas
    // Se elimina uno por uno debido a que no hay un metodo en el sdk que borre multiples records
    if (response.deleteOptionsResponse) {
      const succesfullyDeleted = response.deleteOptionsResponse.filter(
        (option) => option.success === 'true'
      )
      succesfullyDeleted.forEach((option) => {
        ZOHO.CRM.API.deleteRecord({
          Entity: 'logoscorpecommerce__Attributes_x_Options',
          RecordID: option.zohoId,
        }).then((data) => {
          console.log('deleteResponse', data)
        })
      })
      return { message: 'Se actualizo correctamente', type: 'success' }
    } 
      return { message: 'No se pudo actualizar', type: 'error' }
    
  } catch (e) {
    console.log('catched error', e)
    return e
  }
}

async function createAttributeOption(data, attributeId, attributeCode) {
  let apiData = []
  data.forEach((singleOption) => {
    if (singleOption.value === '') {
      return false
    }
    apiData = [
      ...apiData,
      {
        Name: singleOption.label,
        logoscorpecommerce__Zoho_Attribute_Id: attributeId,
        logoscorpecommerce__value: singleOption.value,
        logoscorpecommerce__ecommerce_attribute_code: attributeCode,
      },
    ]
    return singleOption
  })
  try {
    await saveRecord(apiData, 'logoscorpecommerce__Attributes_x_Options', false)
  } catch (err) {
    console.log(err)
  }
}
