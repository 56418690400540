// @mui
import { styled } from '@mui/material/styles'

// ----------------------------------------------------------------------

export const StyledRoot = styled('main')(() => ({
  height: '100%',
  display: 'flex',
  position: 'relative',
}))

export const StyledSection = styled('div')(({ theme }) => ({
  display: 'none',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}))

export const StyledSectionBg = styled('div')(({ theme }) => ({
  background: `url('${process.env.PUBLIC_URL}/assets/background/sambil_background_3.jpg')`,
  backgroundSize: '100% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  width: '100%',
  height: '100%',
  position: 'absolute',
}))

export const StyledContent = styled('div')(({ theme }) => ({
  width: 550,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(0, 4),
  [theme.breakpoints.up('md')]: {
    flexShrink: 0,
    padding: theme.spacing(0, 4, 20, 4),
  },
}))
export const StyledContentWelcome = styled('div')(({ theme }) => ({
  width: 550,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(0, 4),
  [theme.breakpoints.up('md')]: {
    flexShrink: 0,
    padding: theme.spacing(0, 4, 20, 4),
  },
}))
