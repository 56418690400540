import axios from '../utils/axios'
import { HOST_API_KEY } from '../config-global'

export default class BaseService {
  static resource() {
    return ''
  }

  static baseURL() {
    const isDevCatalyst = /.development./.test(window.location)
    if (!isDevCatalyst && process.env.NODE_ENV === 'production') {
      return 'https://sambil-ecommerce-797517798.catalystserverless.com/server/remoteAPI'
    }
    return 'https://sambil-ecommerce-797517798.development.catalystserverless.com/server/remoteAPI'
    // return 'http://localhost:3001/server/remoteAPI'
  }

  /**
   * Fetch all records from resources
   * @options {object} params - Query filters params
   * @returns {object}
   */
  static async fetch(
    fields = '',
    sort_by = 'id',
    sort_order = 'desc',
    page = 1,
    limit = 999,
    filters = [],
    headers = {},
    others = {},
    updateOtherResource = ''
  ) {
    // ["A", "B", "C", "D"] => (((("A") AND "B") AND "C") AND "D")
    const finalFilters = filters.filter((filter) => {
      if (filter.value !== undefined) {
        return true
      }
      if (filter.extraFilter) {
        filter.extraFilter = filter.extraFilter.filter((extra) => extra.value !== undefined)
        return filter.extraFilter.length > 0
      }
      return false
    })

    const filtersString = finalFilters.reduce((acc, curr, index) => {
      const getFilter = (curr) => `${curr.field} ${curr.operation || '='} ${curr.value}`
      let result = acc
      const { extraFilter } = curr
      if (Array.isArray(extraFilter)) {
        const filter = extraFilter.map(getFilter).join(' OR ')
        result = `(${result}`
        result += `${index > 0 ? 'AND ' : ''}(${filter})) `
        return result
      }

      const filter = getFilter(curr)
      result = `(${result}`
      result += `${index > 0 ? 'AND ' : ''}${filter}) `
      return result
    }, '')

    const params = {
      fields,
      sort_by,
      sort_order,
      page: page >= 1 ? page : 1,
      per_page: limit < 0 ? 0 : limit,
      filters: finalFilters.length ? filtersString : undefined,
      ...others,
    }
    // Eliminamos los parametros nullos o en blanco. Ejm: `filter[query]=`
    Object.keys(params).forEach((key) => {
      if (!params[key]) delete params[key]
    })

    const { data } = await axios.get(
      `${this.baseURL()}/${this.resource()}${updateOtherResource ? `/${updateOtherResource}` : ''}${
        finalFilters.length ? '/search' : ''
      }`,
      {
        params,
        headers,
      }
    )
    if (!data) {
      return { data: [] }
      // throw new Error('Not found')
    }
    return data
  }

  /**
   * Fetch all records from resources
   * @param {string} fields - fields
   * @param {Object[]} filters - filters
   * @param {string} filters[].field - field of filter.
   * @param {string} filters[].operation - operation of filter (in, like, =, between).
   * @param {string} filters[].value - value of filter.
   * @returns {object}
   */
  static async countByFields(fields = '', filters = []) {
    const finalFilters = filters?.filter((e) => e.value !== undefined && !!e?.value?.length)
    const params = {
      fields,
      filters: finalFilters?.length
        ? finalFilters.reduce((accumulator, currentValue, currentIndex, array) => {
            let newString = accumulator
            newString += `(${currentValue.field} ${currentValue.operation || '='} ${
              currentValue.value
            })`
            if (currentIndex === array.length - 1) {
              newString += ')'
            } else {
              newString += 'and'
            }
            return newString
          }, '(')
        : undefined,
    }
    // Eliminamos los parametros nullos o en blanco. Ejm: `filter[query]=`
    Object.keys(params).forEach((key) => {
      if (!params[key]) delete params[key]
    })

    const { data } = await axios.get(`${this.baseURL()}/${this.resource()}/count`, {
      params,
    })
    if (!data) {
      return { data: [] }
      // throw new Error('Not found')
    }
    return data
  }

  /**
   * Get a resource by id
   * @param {Number} id - resource Id
   * @param {Array} params - query params
   * @returns {object} resource
   */
  static async get(id, params = {}) {
    const { data } = await axios.get(`${this.baseURL()}/${this.resource()}/${id}`, {
      params,
    })
    return data
  }

  /**
   * Create a new resource
   * @param {object} payload  - Data to save
   * @param {boolean} isFormData  - Data is send should be converted in form-data
   * @returns {object} resource
   */
  static async post(payload = {}, isFormData = false) {
    const { data } = await axios.post(`${this.baseURL()}/${this.resource()}`, payload, {
      headers: isFormData && { 'Content-Type': 'multipart/form-data' },
    })
    return data?.data ? data?.data : data
  }

  /**
   * Modify a existent resource
   * @param {Number} id - resource Id
   * @param {object} payload  - Data to update
   * @param {boolean} isFormData  - Data is send should be converted in form-data
   * @returns {object} resource
   */
  static async update(id, payload = {}, isFormData = false) {
    const { data } = await axios.put(`${this.baseURL()}/${this.resource()}/${id}`, payload, {
      headers: isFormData && { 'Content-Type': 'multipart/form-data' },
    })
    return data.data
  }

  /**
   * Modify a existent resource
   * @param {Number} id - resource Id
   * @param {object} payload  - Data to update
   * @param {boolean} isFormData  - Data is send should be converted in form-data
   * @returns {object} resource
   */
  static async updateMany(payload = {}, isFormData = false) {
    const { data } = await axios.put(`${this.baseURL()}/${this.resource()}`, payload, {
      headers: isFormData && { 'Content-Type': 'multipart/form-data' },
    })
    return data.data
  }

  /**
   * Delete a existent resource
   * @param {Number} id - resource Id
   * @returns {object} resource
   */
  static async delete(id) {
    const { data } = await axios.delete(`${this.baseURL()}/${this.resource()}/${id}`)
    return data
  }

  /**
   * Create or Modify a existent resource
   * @param {object} payload  - Data to create or update
   * @param {boolean} isFormData  - Data is send should be converted in form-data
   * @returns {object} resource
   */
  static async save(payload, isFormData = false) {
    if (payload.id) return this.update(payload.id, payload, isFormData)
    return this.post(payload, isFormData)
  }

  /**
   * Modify a existent resource
   * @param {object} payload  - Data to update
   * @param {string} otherResource param  - String
   * @returns {object} resource
   */
  static async updateMassive(otherResource, payload = {}) {
    const response = await axios.put(
      `${this.baseURL()}/${this.resource()}/${otherResource}/`,
      payload
    )
    return response
  }

  /**
   * Modify a existent resource
   * @param {Number} id - resource Id
   * @param {object} payload  - Data to update
   * @param {string} otherResouce param  - String
   * @param {boolean} isFormData  - Data is send should be converted in form-data
   * @returns {object} resource
   */
  static async updateOtherResource(id, payload = {}, otherResouce, isFormData = false) {
    const { data } = await axios.put(
      `${this.baseURL()}/${this.resource()}/${otherResouce}/${id}`,
      payload,
      {
        headers: isFormData && { 'Content-Type': 'multipart/form-data' },
      }
    )
    return data
  }
}
