import { createSlice } from '@reduxjs/toolkit'
import productQuestionsService from '../../services/productQuestionsService'
import ProductService from '../../services/productService'
import { fToTimezone } from '../../utils/formatTime'

// ----------------------------------------------------------------------

export const fieldsProductQuestions = [
  'id',
  // Producto
  'logoscorpecommerce__Product',
  'logoscorpecommerce__Product.logoscorpecommerce__Name1',
  'logoscorpecommerce__Product.Product_Name',
  'logoscorpecommerce__Product.Record_Image',
  'logoscorpecommerce__Product.logoscorpecommerce__SKU',
  'logoscorpecommerce__Product.logoscorpecommerce__Principal_Image_Path',
  'logoscorpecommerce__Product.Product_Code',
  'logoscorpecommerce__Product.Unit_Price',
  'Name',
  // Cliente
  'Owner',
  'logoscorpecommerce__Customer',
  'logoscorpecommerce__Customer.First_Name',
  'logoscorpecommerce__Question',
  'logoscorpecommerce__Subject',
  'logoscorpecommerce__Answer',
  'logoscorpecommerce__Answered',
  // datos de complemente
  'logoscorpecommerce__Vendor',
  'Created_Time',
  'logoscorpecommerce__Public_Date',
  'logoscorpecommerce__Visibility',
  'logoscorpecommerce__Status',
]

const initialState = {
  isLoading: false,
  isSaving: false,
  error: null,
  questionsCountByAnswer: 0,
  question: null,
  questions: [],
  questionsInfo: {
    countAll: 0,
  },
  questionsError: null,
  isQuestionsLoading: false,
}

const slice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // START SAVING
    startSaving(state) {
      state.isSaving = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET QUESTIONS
    getQuestionsSuccess(state, action) {
      state.questions = action.payload.data.map((question) => {
        const newQuestion = question
        if(newQuestion.principalImage){
          newQuestion.product.principalImage =
            newQuestion.principalImage
          delete newQuestion.principalImage
        }
        return newQuestion
      })
      state.questionsInfo = action.payload.info
      state.isLoading = false
      state.question = null
    },

    // GET QUESTIONS
    getQuestionSuccess(state, action) {      
      if(action.payload.principalImage){
        action.payload.product.principalImage = action.payload.principalImage
        delete action.payload.principalImage
      }

      state.question = action.payload
      state.isLoading = false
    },

    // ONSAVEQUESTION
    onSaveQuestion(state, action) {
      const index = state.questions.findIndex((q) => q.id === action.payload.id)
      if (index !== -1) {
        state.questions[index].logoscorpecommerce__Visibility =
          action.payload.logoscorpecommerce__Visibility
      }
      state.isSaving = false
      state.question = { ...state.question, ...action.payload }
    },

    // GET QUESTIONS COUNT BY ANSWER
    getQuestionsCountByAnswerSuccess(state, action) {
      let answered = 0
      let pending = 0
      let total = 0
      action.payload.data?.forEach((item) => {
        if (item.logoscorpecommerce__Answered === true) {
          answered = item.count
          total += item.count
        } else {
          pending = item.count
          total += item.count
        }
      })
      if (total !== 0) {
        answered = (answered / total) * 100
        pending = (pending / total) * 100
      }
      state.questionsCountByAnswer = { answered, pending, total }
      state.isLoading = false
    },
  },
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------
export function saveQuestion(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startSaving())
    try {
      const result = await productQuestionsService.postAnswerToQuestionById(data.id,data)
      dispatch(slice.actions.onSaveQuestion(data))
      return result
    } catch (error) {
      dispatch(slice.actions.hasError(error))
      throw new Error(error.message)
    }
  }
}

export function getQuestionsCountByAnswer(vendorId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await productQuestionsService.countByFields('logoscorpecommerce__Answered', [
        {
          field: 'logoscorpecommerce__Vendor',
          operation: '=',
          value: vendorId,
        },
      ])
      dispatch(slice.actions.getQuestionsCountByAnswerSuccess(response))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function updateManyQuestions(selects, visibility, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    // dispatch(slice.actions.startSaving())
    try {
      for (const item of selects) {
        await productQuestionsService.update(item, {
          id: item,
          logoscorpecommerce__Visibility: visibility,
        })
        // console.log({id:item},'item')
        // dispatch(slice.actions.onSaveQuestion({id:item}))
      }
      // await productQuestionsService.updateMany( {data:selects.map((id) => ({id, logoscorpecommerce__Visibility:visibility}))} )
      dispatch(
        getQuestions(undefined, undefined, undefined, undefined, [
          {
            field: 'logoscorpecommerce__Vendor',
            operation: '=',
            value: id,
          },
        ])
      )
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function getQuestions(
  id,
  admin = false,
  page = 1,
  limit = 20,
  sort_order = 'desc',
  sort_by = 'id',
  filters = [],
  headers = {}
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      let response = []
      if(admin){
        response = await productQuestionsService.fetchQuestions(
          [],
          sort_by,
          sort_order,
          page,
          limit,
          filters,
          headers
        )
      }else{
        response = await productQuestionsService.fetchQuestionsByVendor(
          id,
          [],
          sort_by,
          sort_order,
          page,
          limit,
          filters,
          headers
        )
      }
      const { items, total_count, search_criteria } = response
      const QuestionsWithImages = await Promise.all(
        items.map(async (question) => {
          let principalImage = ''
          const date = fToTimezone(question.created_at)        
          const answers = await productQuestionsService.getAnswerByQuestionId(question.question_id,[])
          if(answers){
            const filteredAr = answers?.filter(item => item.content !== '' && item.respond_type === 'Vendor')
            const sortedAr = filteredAr.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            if(sortedAr && sortedAr.length > 0){              
              question.answer = sortedAr[0]
            }          
          }          
          if(question?.product){
            if (question.product.logoscorpecommerce__Principal_Image_Path) {
              principalImage = await ProductService.s3ImageFromPath(
                question.product.logoscorpecommerce__Principal_Image_Path
              )
            }
          }
          return { ...question, principalImage, created_at: date }
        })
      )      
      dispatch(slice.actions.getQuestionsSuccess({ data: QuestionsWithImages, info: {total_count, search_criteria} }))
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function getQuestion(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await productQuestionsService.getQuestionByQuestionId(
        id,
        [],
      )
      if (response && response.items) {
        const questionData = response.items
        const QuestionsWithImages = await Promise.all(
          questionData.map(async (question) => {
            let principalImage = ''
            const date = fToTimezone(question.created_at)            
            if (question.product && question.product.logoscorpecommerce__Principal_Image_Path) {
              principalImage = await ProductService.s3ImageFromPath(
                question.product.logoscorpecommerce__Principal_Image_Path
              )
            }
            return { ...question, principalImage, created_at: date }
          })        
        )
        const answers = await productQuestionsService.getAnswerByQuestionId(id,[])
        if(answers){
          const filteredAr = answers?.filter(item => item.content !== '' && item.respond_type === 'Vendor')
          const sortedAr = filteredAr.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          if(sortedAr && sortedAr.length > 0){              
            QuestionsWithImages[0].answer = sortedAr[0]
            QuestionsWithImages[0].answerContent = sortedAr[0].content
          }          
        }                
        dispatch(slice.actions.getQuestionSuccess(QuestionsWithImages[0]))
      } else {
        throw new Error('Resource not found.')
      }
    } catch (error) {
      console.error("entre en error")

      console.error(error)
      dispatch(slice.actions.hasError(error))
      throw error
    }
  }
}
