import axios from '../utils/axios'
import BaseService from './baseService'

export default class MagentoProductService extends BaseService {
  static path = 'magento'

  static resource() {
    return `magento/${this.path}`
  }

  static async getProductbySkuMagento(id, sourceCode = 'sambilchacao') {
    try {
      this.path = '/get-stock-magento'
      const params = { sourceCode }
      const response = await this.get(`${id}`, params)
      this.path = ''
      return response
    } catch (error) {
      return {}
    }
  }

  static async getMagentoProductStockPrice(sku, sourceCode = 'sambilchacao') {
    try {
      this.path = '/get-stock-price/'
      const params = { sourceCode }
      const response = await this.get(`${sku}`, params)
      this.path = ''
      return response
    } catch (error) {
      console.log('error in request', error)
      throw Error(error?.error || 'Error al obtener el Producto de Magento')
    }
  }

  static async getStores() {
    try {
      this.path = '/stores/'
      const { data } = await axios.get(`${this.baseURL()}/${this.resource()}`)
      this.path = ''
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  static async disableProductsBySku(skuRegex) {
    try {
      this.path = 'disable-products-by-sku'
      const { data } = await axios.get(`${this.baseURL()}/${this.resource()}/${skuRegex}`)
      this.path = ''
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  static async getSalesData(payload, id) {
    try {
      this.path = '/get-sales-info/'
      const { data } = await axios.post(`${this.baseURL()}/${this.resource()}${id || ''}`, payload)
      this.path = ''
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  static async disableProductsByCategory(id) {
    try {
      this.path = 'disable-products-by-category'
      const { data } = await axios.get(`${this.baseURL()}/${this.resource()}/${id}`)
      this.path = ''
      return data
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}
