// @mui
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

export const StyledRoot = styled('main')(() => ({
  background: `url('${process.env.PUBLIC_URL}/assets/background/sambil_background_2.jpg')`,
  backgroundSize: '100% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  width: '100%',
  height: '100%',
  position: 'absolute'
}));
