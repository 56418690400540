import { createSlice } from '@reduxjs/toolkit'
import MassiveUploadService from '../../services/massiveUploadService'
import { fDate } from '../../utils/formatTime'

// ----------------------------------------------------------------------

export const fieldsMassiveUpload = [
  'logoscorpecommerce__Vendor',
  'logoscorpecommerce__Type',
  'logoscorpecommerce__Status',
  'logoscorpecommerce__Description',
  'Created_Time',
]

const initialState = {
  isLoading: false,
  isLoadingCount: {
    Completada: false,
    'Por Completar': false,
    Cancelada: false,
  },
  error: null,
  massiveUploads: [],
  UploadLogsCountBy: {
    Completada: '',
    'Por Completar': '',
    Cancelada: '',
  },
  massiveUploadsInfo: {
    countAll: 0,
  },
  massiveUploadDetail: null,
}

const slice = createSlice({
  name: 'massive',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // START LOADING
    startLoadingCountBy(state, action) {
      state.isLoadingCount[action.payload.type] = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET MASSIVE UPLOADS
    getUploadLogsSuccess(state, action) {
      state.isLoading = false
      state.massiveUploads = action.payload.data
      state.massiveUploadsInfo = action.payload.info
    },

    // GET MASSIVE UPLOADS Count
    getUploadLogsCountBySuccess(state, action) {
      state.isLoadingCount[action.payload.type] = false
      state.UploadLogsCountBy[action.payload.type] =
        action.payload.data.length > 0 ? action.payload.data[0].count : ''
    },

    // GET LOG DETAIL
    getUploadLogDetailSuccess(state, action) {
      state.isLoading = false
      state.massive = action.payload.data.map((detail) => {
        const newDetail = detail
        newDetail.Created_Time = fDate(newDetail.Created_Time, 'dd-MM-yyyy HH:mm:ss')
        return newDetail
      })
      state.massiveUploadDetail = action.payload.data[0]
    },
  },
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------
export function getUploadsLogsCountByStatus(vendorId, statusFilter) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingCountBy({ type: statusFilter }))
    try {
      const response = await MassiveUploadService.countByFieldsMassive(
        'logoscorpecommerce__Status',
        [
          {
            field: 'logoscorpecommerce__Vendor_Ecommerce_Id',
            operation: '=',
            value: vendorId,
          },
          {
            field: 'logoscorpecommerce__Status',
            operation: '=',
            value: statusFilter ? `'${statusFilter}'` : undefined,
          },
        ]
      )
      dispatch(slice.actions.getUploadLogsCountBySuccess({ ...response, type: statusFilter }))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function getUploadsLogs(
  page = 1,
  limit = 20,
  sort_order = 'desc',
  sort_by = 'id',
  filters = []
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await MassiveUploadService.fetch(
        [...fieldsMassiveUpload].join(','),
        sort_by,
        sort_order,
        page,
        limit,
        filters
      )
      dispatch(slice.actions.getUploadLogsSuccess(response))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function getUploadLogDetail(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await MassiveUploadService.get(id)
      dispatch(slice.actions.getUploadLogDetailSuccess(response))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}
