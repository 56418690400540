import axios from '../utils/axios'

import BaseService from './baseService'

export default class VendorService extends BaseService {
  static path = ''

  static resource() {
    return `Vendors${this.path}`
  }

  static async s3ImageFromPath(path) {
    try {
      const response = await this.get(`s3ImageFromPath?path=${encodeURIComponent(path)}`)
      return response.data
    } catch (error) {
      return {}
    }
  }

  // static async getPhoto( id ) {
  //   try{
  //     const response = await this.get(`${id}/photo`)
  //     return response.data;
  //   }catch(error){
  //     return {}
  //   }
  // }

  static async getContacts(id) {
    const { data } = await this.get(
      `${id}/Contacts?fields=First_Name,Last_Name,Email,Phone,Mobile,sambil__Tipo_de_Documento,logoscorpecommerce__Document_Type,logoscorpecommerce__Document_Number,Vendor_Name,logoscorpecommerce__Company_position,Mailing_Country,Mailing_State,Mailing_City,Mailing_Street,logoscorpecommerce__Residencia_o_Edificio,logoscorpecommerce__Floor_or_House_Number,Mailing_Zip`
    )
    return data
  }

  static async visibleStoreToggleMagento(id, payload) {
    try {
      this.path = `/visibleStoreMagento`
      const { data } = await axios.put(`${this.baseURL()}/${this.resource()}/${id}`, payload, {})
      this.path = ''
      return data
    } catch (error) {
      console.log(error)
      throw new Error(error.error?.message || 'Ha ocurrido un error, por favor intenta mas tarde.')
    } finally {
      this.path = ''
    }
  }

  static async visibleStoreToggle(id, payload) {
    try {
      this.path = `/visibleStore`
      const { data } = await axios.put(`${this.baseURL()}/${this.resource()}/${id}`, payload, {})
      this.path = ''
      return data
    } catch (error) {
      console.log(error)
      throw new Error(error.error?.message || 'Ha ocurrido un error, por favor intenta mas tarde.')
    } finally {
      this.path = ''
    }
  }

  static async cancelAccountRequest(payload) {
    try {
      const path = 'CancelAccountRequests'
      console.log('payload',payload)
      const { data } = await axios.post(`${this.baseURL()}/${path}`, payload, {})      
      return data
    } catch (error) {
      console.log(error)
      throw new Error(error.error?.message || 'Ha ocurrido un error, por favor intenta mas tarde.')
    } 
  }
}
