import PropTypes from 'prop-types'
import { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthContext } from './useAuthContext'

// ----------------------------------------------------------------------

DisabledModulesAprovedVendorGuard.propTypes = {
  children: PropTypes.node,
}

export default function DisabledModulesAprovedVendorGuard({ children }) {
  const { user, isAuthenticated } = useAuthContext()
  const { pathname } = useLocation()

  const protected_routes = [
    '/dashboard/products',
    '/dashboard/products/list',
    '/dashboard/products/new/0',
    '/dashboard/product-questions/list',
    '/dashboard/brands/list',
    '/dashboard/brands/request',
    '/dashboard/massive-load',
    '/dashboard/massive-load/history',
    '/dashboard/massive-load/new-template',
    '/dashboard/massive-load/import',
    '/dashboard/invoice',
    '/dashboard/invoice/list',
  ]

  if (isAuthenticated && user?.appRol === 'ADMIN' && user.zohoVendor.logoscorpecommerce__Status !== 'Activo') {    
    if (protected_routes.some((route) => route === pathname)) {
        alert('No puedes ver este modulo si el vendor no esta aprobado.')
        return <Navigate to="/dashboard/app" />
    }
  }

  return <> {children} </>
}
