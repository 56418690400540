import PropTypes from 'prop-types'
import { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
// components
import LoadingScreen from '../components/loading-screen'
//
import Login from '../pages/auth/LoginPage'
import { useAuthContext } from './useAuthContext'

// ----------------------------------------------------------------------

DisabledModulesBasedGuard.propTypes = {
  children: PropTypes.node,
}

export default function DisabledModulesBasedGuard({ children }) {
  const { adminMenuEnabled, user, disabledModules } = useAuthContext()
  const { pathname } = useLocation()
  const location = useLocation()

  const [requestedLocation, setRequestedLocation] = useState(null)

  if (disabledModules) {
    const disabledModulesArr = disabledModules.replace(/\s+/g, '').split(',')
    if (disabledModulesArr.some((module) => module === pathname)) {
      alert('Este modulo se encuentra en desarrollo.')
      return <Navigate to="/zAdmin/app" />
    }

    if (requestedLocation && pathname !== requestedLocation) {
      setRequestedLocation(null)
      return <Navigate to={requestedLocation} />
    }
  }

  return <> {children} </>
}
