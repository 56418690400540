import React from 'react'
import { Box, Typography, LinearProgress, linearProgressClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

// eslint-disable-next-line react/prop-types
const LinearProgressWithLabel = ({ value, ...props }) => {
  // ----------------------- Component for Linear Progress Bar ----------------------------------
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }))

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <BorderLinearProgress variant="determinate" {...props} value={value} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(value)}%`}</Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
        <Box>
          <Typography style={{ fontWeight: 'bold' }} sx={{ styleDesc2 }}>
            ¡Atención!
          </Typography>

          <Typography sx={{ styleDesc2 }}>
            No refresque, ni cierre la ventana hasta que la barra se haya completado.
          </Typography>
        </Box>
      </Box>
    </>
  )
}

const styleDesc2 = {
  color: 'text.secondary',
  fontFamily: 'Public Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '22px',
}

export default LinearProgressWithLabel
