// ----------------------------------------------------------------------

export default function ListItemText(theme) {
  return {
    MuiListItemText: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        },
      },
    },
  };
}
