import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from '../components/loading-screen';
//
import Login from '../pages/auth/LoginPage';
import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------

AuthGuardAdmin.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuardAdmin({ children }) {
  const { isAuthenticated, isInitialized, adminMenuEnabled, user} = useAuthContext();
  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);


  console.log('admin');
  console.log('requestedLocation', requestedLocation);
  console.log('pathname', pathname);
  console.log('adminMenuEnabled', adminMenuEnabled);


  if (!isInitialized) {
    return <LoadingScreen />;
  }

 if (!isAuthenticated || !adminMenuEnabled || user?.appRol !== 'ADMIN' ) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    
    return <Navigate to="/dashboard/app" />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  /* if (!isAuthenticated || !adminMenuEnabled || appRol != 'ADMIN' ) {
    return <Navigate to="dashboard" />;;
  } */

  return <> {children} </>;
}
